import Close from "../../assets/images/close.svg";
import styles from "./CloseButton.module.sass";
import cn from "classnames";

interface IProps {
  closeToast: any;
  onClick?: () => void;
  className?: string;
}

const CloseButton = ({ closeToast, onClick, className }: Partial<IProps>) => {
  return (
    <button
      className={cn(styles.close, className)}
      onClick={onClick !== undefined ? onClick : closeToast}
    >
      <img
        className={styles.iconClose}
        src={Close}
        width={12}
        height={12}
        alt=""
      />
    </button>
  );
};

export default CloseButton;
