import Button from "../Button/Button";
import CloseButton from "../CloseButton/CloseButton";
import cn from "classnames";
import styles from "./DownloadAdminAppBanner.module.sass";
import { testflightLink } from "../../utils/constants";
import { useState } from "react";
import { useWindowWidth } from "../../hooks/useWindowWidth";

import GooglePlay from "../../assets/images/google-play.svg";
import AppStore from "../../assets/images/app-store.svg";
import PersonLogo from "../../assets/images/mobile-application-logo.png";
import QRAppStore from "../../assets/images/appstore-qr-code.svg";
import QRGooglePlay from "../../assets/images/googleplay-qr-code.svg";

const DownloadAdminAppBanner = () => {
  const width = useWindowWidth();
  const [isBannerVisible, setIsBannerVisible] = useState(true);
  const isMobileVersion = width < 640;

  const closeButtonHandler = () => {
    setIsBannerVisible(false);
    localStorage.setItem("hideDowloadAppBanner", "true");
  };

  const navigateToTestflight = () => {
    window.open(testflightLink);
  };

  return isBannerVisible ? (
    <div className={cn(styles.card)}>
      {/* <div className={cn(styles.head)}>         */}
        {/* <div className={cn(styles.mobileVersionLayoutControler)}> */}
          {/* <div className={cn(styles.section)}> */}
            
        <h5 className={styles.title}>{"👉 Step 2: Download the Carl Family mobile app"}</h5>   
        <div className={styles.containerHorizontal}>                    
        <img  
          width={102}
          height={102}        
          onClick={() => {}}
          alt="Person Logo"
          className={styles.downloadFromStore}
          src={PersonLogo}
        />          
          <div className={styles.gridContainer}>
          <div className={styles.gridItem}>           
            <div className={styles.downloadIconsContainer}>
              <a href="https://apps.apple.com/ro/app/carl-friends-family/id6473246850" target="_blank" rel="noreferrer" > 
              <img
                width={140}
                height={40}
                onClick={() => {}}
                alt="Download app from app store"
                className={styles.downloadFromStore}
                src={AppStore}
              />
              </a>
              <img
                  width={100}
                  height={100}
                  onClick={() => {}}
                  alt="QR Appstore code app store"
                  className={styles.downloadFromStore}
                  src={QRAppStore}
                />
            </div>
            <div className={styles.downloadIconsContainer}>
              <a href="https://play.google.com/store/apps/details?id=com.hellocarl.carladmin" target="_blank" rel="noreferrer" >             
              <img
                width={143}
                height={41}
                onClick={() => {}}
                alt="Download app from google play"
                className={styles.downloadFromStore}
                src={GooglePlay}
              />
              </a>
              <img
                  width={100}
                  height={100}
                  onClick={() => {}}
                  alt="QR Googleplay code app store"
                  className={styles.downloadFromStore}
                  src={QRGooglePlay}
                />  
            </div>   
            </div>                                                             
        </div>
      </div> 
    </div>
      
  ) : (
    <div />
  );
};

export default DownloadAdminAppBanner;
