import { Dispatch, createSlice, PayloadAction } from "@reduxjs/toolkit";
import axios from "axios";
import { Admin, IPhoneNumber } from "../../interfaces/app.interface";
import { auth } from "../../services/firebase";
import { RootState } from "../index.store";
import { adminSlice } from "../slices/admin.slice";
import { authenticationSlice } from "./auth.slice";

const endpoint = process.env.REACT_APP_CARL_ENDPOINT;

interface ICreateAccount {
  name: string | null;
  email: string | null;
  phoneNumber: IPhoneNumber | null;
  verifiedPhoneNumber: string | null;
  securityCode: string | null;
  isSecurityCodeVerified: boolean;
  securityCodePending: boolean;
  section: CreateAccountSections;
  imagePath: string | null;
  error?: string | null;
  actionCompleteNotification?: string | null;
}

interface ISetValue {
  value: string | null | IPhoneNumber | File;
  field: string;
}

export enum CreateAccountSections {
  name = "name",
  email = "email",
  phoneNumber = "phoneNumber",
  securityCode = "securityCode",
  picture = "picture",
}

const initialState: ICreateAccount = {
  name: null,
  email: null,
  phoneNumber: null,
  verifiedPhoneNumber: null,
  isSecurityCodeVerified: false,
  securityCodePending: false,
  securityCode: null,
  imagePath: null,
  section: CreateAccountSections.name,
};

export const CreateAccountSlice = createSlice({
  name: "createAccount",
  initialState,
  extraReducers: (builder) => {
    builder.addCase(
      authenticationSlice.actions.onAddCaregiverResponse,
      (_, action) => {
        return {
          ...initialState,
          name: !!action.payload.data?.name
            ? action.payload.data.name.includes(" ")
              ? action.payload.data.name.split(" ")[0]
              : action.payload.data.name
            : null,
          email: action.payload.data?.email ?? null,
        };
      }
    );
    builder.addCase(
      adminSlice.actions.onSuccessfulGetAdmin,
      (state, action: PayloadAction<Admin>) => {
        return {
          ...state,
          name:
            state.name ?? !!action.payload?.name
              ? action.payload?.name?.includes(" ")
                ? action.payload?.name?.split(" ")[0]
                : (action.payload.name as string)
              : null,
          email: state.email ?? (action.payload.email as string),
        };
      }
    );
  },
  reducers: {
    setValue: (state, action: PayloadAction<ISetValue>) => {
      return {
        ...state,
        [action.payload.field]: action.payload.value,
      };
    },
    onSuccessfulSendCode: (state, action: PayloadAction<boolean>) => {
      return {
        ...state,
        error: null,
        securityCodePending: true,
        ...(action.payload && {
          actionCompleteNotification: "Security code was successfully resent",
        }),
      };
    },
    onSuccessfulVerifyCode: (state) => {
      return {
        ...state,
        isSecurityCodeVerified: true,
        verifiedPhoneNumber: state.phoneNumber?.international!,
      };
    },
    resetActionCompleteNotification: (state) => ({
      ...state,
      actionCompleteNotification: null,
    }),
    setCreateAccountToInial: (state) => {
      return {
        ...initialState,
      };
    },
    setVerifyPhoneNumberToInitial: (state) => {
      return {
        ...state,
        securityCode: null,
        isSecurityCodeVerified: false,
        verifiedPhoneNumber: null,
        securityCodePending: false,
      };
    },
    onError: (state, action: PayloadAction<string>) => {
      return {
        ...state,
        error: action.payload,
        loading: false,
      };
    },
    resetError: (state) => ({
      ...state,
      error: null,
    }),
  },
});

export const setCreateAccountValues =
  ({ value, field }: ISetValue) =>
  (dispatch: Dispatch) => {
    dispatch(setValue({ value, field }));
  };

export const resetCreateAccount = () => (dispatch: Dispatch) => {
  dispatch(setCreateAccountToInial());
};

export const resetVerifyPhoneNumber = () => (dispatch: Dispatch) => {
  dispatch(setVerifyPhoneNumberToInitial());
};

export const sendCode =
  (completePhoneNumber: string, resend: boolean) =>
  async (dispatch: Dispatch) => {
    try {
      const idToken = await auth.currentUser?.getIdToken(true);
      await axios.post(
        `${endpoint}/phone-verification/send-code`,
        {
          phone: completePhoneNumber,
        },
        {
          headers: {
            Authorization: `Bearer ${idToken}`,
          },
        }
      );
      dispatch(onSuccessfulSendCode(resend));
    } catch (error: any) {
      console.log(error.message);
      dispatch(onError(error?.response?.data?.error || error?.message));
    }
  };

export const verifyCode =
  (completePhoneNumber: string, code: string) => async (dispatch: Dispatch) => {
    try {
      const idToken = await auth.currentUser?.getIdToken(true);
      await axios.post(
        `${endpoint}/phone-verification/verify-code`,
        {
          phone: completePhoneNumber,
          code: code,
        },
        {
          headers: {
            Authorization: `Bearer ${idToken}`,
          },
        }
      );
      dispatch(onSuccessfulVerifyCode());
      return true;
    } catch (error: any) {
      console.log(error.message);
      error?.response?.status === 403
        ? dispatch(
            onError("That code didn't work. Check the code and try again.")
          )
        : dispatch(onError(error?.response?.data?.error || error?.message));
      return false;
    }
  };

export const {
  setValue,
  setCreateAccountToInial,
  onError,
  resetError,
  onSuccessfulSendCode,
  onSuccessfulVerifyCode,
  resetActionCompleteNotification,
  setVerifyPhoneNumberToInitial,
} = CreateAccountSlice.actions;
export const selectCreateAccount = (state: RootState) => state.createAccount;
export const createAccountReducer = CreateAccountSlice.reducer;
