import { useState, useEffect } from "react";
import PhoneInput, { CountryData } from "react-phone-input-2";
import cn from "classnames";
import "react-phone-input-2/lib/style.css";
import "./phoneInput.css";

import styles from "./PhoneNumberInput.module.sass";
import axios from "axios";

type IProps = {
  value: string | null | undefined;
  countryCode?: string | number;
  isInvalidInput: boolean;
  onChange: (phone: string, country: {} | CountryData) => void;
  autoFocus?: boolean;
  extendDropDown?: boolean;
};

const PhoneNumberInput = ({
  value,
  countryCode,
  isInvalidInput,
  onChange,
  autoFocus,
  extendDropDown,
}: IProps) => {
  const [defaultCountryCode, setDefaultCountryCode] = useState<string>("");

  const getGeoInforomation = async () => {
    try {
      const response = await axios.get("https://ipapi.co/json/");
      response.data &&
        setDefaultCountryCode(response.data.country.toLowerCase());
    } catch (err) {
      console.log(`Get geo info error: ${err}`);
    }
  };

  useEffect(() => {
    if (!!countryCode === false && !value) getGeoInforomation();
  }, [getGeoInforomation]);

  return (
    <PhoneInput
      inputProps={{
        autoFocus: autoFocus ?? false,
      }}
      masks={{
        ar: '. .. .... ....',
      }}
      countryCodeEditable={false}
      country={countryCode ?? defaultCountryCode}
      onChange={(phone, country) => onChange(phone, country)}
      containerClass={cn(styles.phoneInputContainer, {
        [styles.invalidInput]: isInvalidInput,
      })}
      value={value}
      inputClass={cn(styles.phoneInput)}
      buttonClass={styles.phoneInputButton}
      dropdownClass={cn(styles.phoneInputDropdown, {
        [styles.extendDropDown]: extendDropDown
      })}
      searchClass={styles.phoneInputSearch}
    />
  );
};

export default PhoneNumberInput;
