import React, { useEffect, useState } from "react";
import cn from "classnames";

import Icon from "../Icon";
import Button from "../Button/Button";

import styles from "./FormCard.module.sass";
import { uuidv4 } from "@firebase/util";

interface IProps {
  title?: string;
  children?: React.ReactNode;
  backButtonVisible?: boolean;
  closeButtonVisible?: boolean;
  skipButton?: boolean;
  onClickSkipButton?: () => void;
  primaryButtonLabel?: string;
  onClickPrimaryButton?: () => void;
  disablePrimaryButton?: boolean;
  onClickBackButton?: () => void;
  skipButtonLabel?: string;
  onClickCloseButton?: () => void;
  primaryButtonClass?: string;
  section?: string;
}

const FormCard = ({
  children,
  title,
  backButtonVisible,
  closeButtonVisible,
  skipButton,
  onClickSkipButton,
  primaryButtonLabel,
  onClickPrimaryButton,
  disablePrimaryButton,
  onClickBackButton,
  skipButtonLabel,
  onClickCloseButton,
  primaryButtonClass,
  section,
}: IProps) => {
  const [resetAnimationKey, setResetAnimationKey] = useState<string>();

  useEffect(() => {
    setResetAnimationKey(uuidv4());
  }, [section]);

  return (
    <div className={styles.container}>
      {(!!onClickBackButton || !!onClickCloseButton || !!title) && (
        <div className={styles.navigation}>
          <div
            className={cn(styles.iconContainer, {
              [styles.invisible]: !backButtonVisible,
            })}
          >
            <Icon
              onClick={onClickBackButton}
              name="arrow-left"
              viewbox="-1 -1 18 18"
              size={24}
            />
          </div>
          {title && <p className={styles.title}>{title}</p>}
          <div
            className={cn(styles.iconContainer, {
              [styles.invisible]: !closeButtonVisible,
            })}
          >
            <Icon
              onClick={closeButtonVisible ? onClickCloseButton : () => {}}
              name="close"
              viewbox="-1 -1 18 18"
              size={24}
            />
          </div>
        </div>
      )}

      <div key={resetAnimationKey} className={styles.body}>
        {children}
      </div>

      <div className={styles.buttonsContainer}>
        {skipButton && (
          <div onClick={onClickSkipButton} className={cn(styles.skipButton)}>
            {skipButtonLabel ?? "Skip"}
          </div>
        )}
        {onClickPrimaryButton && (
          <Button
            btnClass={cn(styles.nextButton, primaryButtonClass, {
              [styles.disable]: disablePrimaryButton,
            })}
            onClick={disablePrimaryButton ? () => {} : onClickPrimaryButton}
          >
            {primaryButtonLabel}
          </Button>
        )}
      </div>
    </div>
  );
};

export default FormCard;
