import { useEffect, useRef, useState } from "react";
import styles from "./Terms.module.sass";

export default function Terms(termly) {  
  useEffect(() => {    
    const script = document.createElement("script");
    script.id = "termly-jssdk";
    script.src = "https://app.termly.io/embed-policy.min.js";
    script.async = true;      
    document.body.appendChild(script);
  }, []);
  
  return (
    <div 
      name="termly-embed"
      data-id={termly.termlyId}
      data-type="iframe"
    ></div>
  );
}
