import { useNavigate } from "react-router-dom";
import FormCard from "../../components/FormCard/FormCard";
import { Widget } from '@typeform/embed-react'
import { useAppSelector } from "../../store/redux.hooks";

const TypeformSurvey = () => {
  const admin = useAppSelector((state) => state.admin);  
  return (
    <>
    <div style={{height:"100vh"}} >
      <Widget style={{height:"100%"}} id={String(process.env.REACT_APP_TYPEFORM_ID)} className="my-form"  hidden={{email: String(admin.email), coupon_code: "ONEMONTHFREE", client_reference_id: String(admin.profile_id)}}/>
      </div></>
  );
};

export default TypeformSurvey;
