import { useEffect } from "react";
import cn from "classnames";
import packageJson from "../../../package.json";
import Icon from "../Icon";

import styles from "./FormPage.module.sass";
import { useWindowWidth } from "../../hooks/useWindowWidth";
import { ToastContainer } from "react-toastify";
import CloseButton from "../CloseButton/CloseButton";

import { useAppDispatch, useAppSelector } from "../../store/redux.hooks";
import { resetActionCompleteNotification } from "../../store/slices/createAccount.slice";
import { toast } from "react-toastify";

interface IProps {
  children: React.ReactNode;
  isSignInPage?: any;
}
const FormPage = ({ children, isSignInPage }: IProps) => {
  const width = useWindowWidth();
  const isMobileVersion = width < 639;

  const dispatch = useAppDispatch();

  const createAccountError: string | null | undefined = useAppSelector(
    (state) => state.createAccount.error
  );
  const createAccountNotification: string | null | undefined = useAppSelector(
    (state) => state.createAccount.actionCompleteNotification
  );

  useEffect(() => {
    toast.error(createAccountError, {
      toastId: createAccountError as string,
      autoClose: 50000,
    });

    toast.success(createAccountNotification, {
      toastId: createAccountNotification as string,
      autoClose: 50000,
    });
    !!createAccountNotification && dispatch(resetActionCompleteNotification());
  }, [createAccountError, dispatch, createAccountNotification]);

  return (
    <div
      className={cn(styles.background, {
        [styles.signInBackgroud]: !!isSignInPage,
      })}
    >
      <ToastContainer
        hideProgressBar={true}
        pauseOnFocusLoss={true}
        newestOnTop={true}
        className={styles.toastBody}
        toastClassName={styles.toast}
        closeOnClick={false}
        closeButton={<CloseButton closeToast />}
      />
      <div
        className={cn(styles.wrapper, {
          [styles.signInBackgroud]: !!isSignInPage,
        })}
      >
        {((!isSignInPage && !isMobileVersion) || !!isSignInPage) && (
          <div
            className={cn(styles.logoContainer, {
              [styles.absolutePosition]: !isSignInPage && !isMobileVersion,
              [styles.signInPageLogo]: isSignInPage && width > 474,
            })}
          >
            <Icon
              className={cn(styles.carlLogo, {
                [styles.absolutePosition]: !isSignInPage && !isMobileVersion,
              })}
              name="carlLogo"
              viewbox={"0 0 123 47"}
              fill="#002E1C"
              size={100}
            />
          </div>
        )}
        {children}
      </div>
      <p className={cn(styles.appVersion)}>{`v${packageJson.version}`}</p>
    </div>
  );
};

export default FormPage;
