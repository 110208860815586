import { combineReducers, configureStore } from "@reduxjs/toolkit";
import { authenticationReducer } from "./slices/auth.slice";
import { imageCachingReducer } from "./slices/imageCaching";
import { adminReducer } from "./slices/admin.slice";
import { createAccountReducer } from "./slices/createAccount.slice";
import persistReducer from "redux-persist/es/persistReducer";
import storage from "redux-persist/lib/storage";
import {
  FLUSH,
  PAUSE,
  PERSIST,
  PURGE,
  REGISTER,
  REHYDRATE,
  persistStore,
} from "redux-persist";
import { addLovedOneReducer } from "./slices/addLovedOne.slice";
import { BillingReducer } from "./slices/billing.slice";

const persistConfig = {
  key: "root",
  storage,
  blacklist: [],
  whitelist: ["authentication", "createAccount", 'addLovedOne', 'billing', 'partner'],
};

const reducers = combineReducers({
  authentication: authenticationReducer,
  admin: adminReducer,
  imageCaching: imageCachingReducer,
  createAccount: createAccountReducer,
  addLovedOne: addLovedOneReducer,
  billing: BillingReducer,
});

const persistedReducer = persistReducer(persistConfig, reducers);

export const store = configureStore({
  reducer: persistedReducer,
  devTools: { trace: true, traceLimit: 25 },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: {
        ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
      },
    }).concat(),
});

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;
export const persistor = persistStore(store);
