import { useEffect, useState } from "react";
import { useAppDispatch, useAppSelector } from "../../store/redux.hooks";
import cn from "classnames";

import {
  CareGroup,
  CareReceiver,
  Member,
} from "../../interfaces/app.interface";
import { inviteCaregiver, refreshAdminList } from "../../store/slices/admin.slice";
import { getAdmin } from "../../store/slices/admin.slice";

import Table from "../../components/Table/Table";
import Card from "../../components/Card";
import Button from "../../components/Button/Button";
import TextInput from "../../components/TextInput";
import Modal from "../../components/Modal";
import { ImageKit } from "../../components/Image";

import styles from "./CareTeam.module.sass";
import { StripeStatus } from "../../interfaces/app.enums";
import { isEmailValid, nameToPossessive } from "../../utils/helper.function";
import DownloadAdminAppBanner from "../../components/DownloadAdminAppBanner/DownloadAdminAppBanner";
import { useWindowWidth } from "../../hooks/useWindowWidth";

const columns = ["Name", "Status", "Email", "Actions"];

const CareTeam = () => {
  const dispatch = useAppDispatch();
  const width = useWindowWidth();

  const [dataSource, setDataSource] = useState<Member[]>([]);
  const [modalActive, setModalActive] = useState<boolean>(false);
  const [section, setSection] = useState<string>();
  const [caregiverEmail, setCaregiverEmail] = useState<string>("");
  const [caregiverFullName, setCaregiverFullName] = useState<string>("");
  const [adminsIds, setAdmindsIds] = useState<string[]>([]);
  const [ownersIds, setOwnersIds] = useState<string[]>([]);

  const careGroup: CareGroup | undefined | null = useAppSelector(
    (state) => state.admin.selected_care_group
  );
  const [selectedCareReceiver, setSelectedCareReceiver] =
    useState<CareReceiver>();
  const currentUser = useAppSelector((state) => state.admin);
  const userIsInactive = useAppSelector(
    (state) =>
      state.admin.selected_care_group?.care_receivers[0].status ===
      StripeStatus.inactive
  );

  const isMobileVersion = width < 640;

  useEffect(() => {
    if (careGroup) {
      const sortedMembers = [...careGroup.members]?.sort((a, b) =>
        (a.name || "").localeCompare(b.name || "")
      );

      setDataSource(sortedMembers);
      const receiver = careGroup?.care_receivers?.[0];
      if (receiver !== null) {
        setSelectedCareReceiver(receiver!);
      }
    }
  }, [careGroup, careGroup?.members]);

  useEffect(() => {
    careGroup &&
      setAdmindsIds(careGroup?.admins.map((owner) => owner.profile_id));
    careGroup &&
      setOwnersIds(careGroup?.owners.map((owner) => owner.profile_id));
  }, [careGroup]);

  const handleInviteCaregiver = () => {
    if (
      caregiverEmail.trim() !== "" &&
      isEmailValid(caregiverEmail.trim()) &&
      careGroup?._id != null &&
      caregiverFullName.trim() !== ""
    ) {
      setModalActive(false);
      dispatch(
        inviteCaregiver(
          careGroup?._id,
          caregiverEmail?.trim()!,
          caregiverFullName
        )
      );
    }
  };

  const inviteCareGiverHandler = () => {
    if (userIsInactive) return;
    setSection(() => "careGiver");
    setModalActive(() => true);
  };

  return (
    <div className={cn(styles.container)}>
      <div className={styles.row}>
        <div className={styles.col}>
          <div className={styles.header}>
            <div className={styles.titleContainer}>
              {selectedCareReceiver?.image_uri ? (
                <div className={styles.avatar}>
                  <ImageKit
                    color
                    path={selectedCareReceiver?.image_uri}
                    width={48}
                    height={48}
                  />
                </div>
              ) : (
                <div className={styles.initialsContainer}>
                  <p className={styles.initials}>
                    {selectedCareReceiver?.name
                      ?.split(" ")
                      ?.map((a) => a[0]?.toUpperCase())
                      ?.join("")}
                  </p>
                </div>
              )}
              <p className={cn(styles.title)}>
                {`${nameToPossessive(selectedCareReceiver?.name)} care team`}
              </p>
            </div>
            <p className={cn(styles.subtitle)}>
              Care giving alone could be very difficult. Invite other family
              members and/or caregivers to join your team.
            </p>
          </div>
          {!localStorage.getItem("hideDowloadAppBanner") && (
            <DownloadAdminAppBanner />
          )}
          <Card
            className={cn(styles.card, {
              [styles.cardMarginBottom]: dataSource.length > 0
            })}
            title={"Caregivers"}
            // subtitle={"Has the same permissions as yourself."}
            classTitle="title-blue"
            head={
              <div
                className={cn(styles.cardHead, {
                  [styles.cardHeadMobileVersion]: isMobileVersion,
                })}
              >
                <Button
                  onClick={() => dispatch(refreshAdminList(careGroup?._id as string))}
                  disabled={undefined}
                  btnClass={cn(
                    isMobileVersion
                      ? styles.buttonRefreshMobile
                      : styles.refreshButton
                  )}
                  icon="refresh"
                  iconColor={isMobileVersion ? "#1A1D1F" : "#0096B7"}
                  iconViewBox={"-0.5 -1 20 18"}
                >
                  {isMobileVersion ? "Refresh" : null}
                </Button>

                {adminsIds.includes(currentUser.profile_id as string) && (
                  <Button
                    onClick={inviteCareGiverHandler}
                    icon={"plus"}
                    disabled={undefined}
                    btnClass={cn(styles.button)}
                  >
                    {"Invite a caregiver"}
                  </Button>
                )}
              </div>
            }
          >
            <div className={styles.overview}>
              {dataSource.length !== 0 && (
                <Table
                  userTable={"member"}
                  columns={columns}
                  data={dataSource}
                  adminsIds={adminsIds}
                  ownersIds={ownersIds}
                ></Table>
              )}
            </div>
          </Card>
          <Modal
            title={
              section === "careGiver"
                ? "Invite a caregiver"
                : "Invite a family or friend"
            }
            primaryDescription={
              section === "careGiver"
                ? "A caregiver role will have access to all features and functionalities."
                : "A family or friend role will only have access to the chat, learn, and settings features."
            }
            primaryBtnStyle={cn({
              [styles.dissable]:
                caregiverEmail.trim() === "" ||
                !isEmailValid(caregiverEmail.trim()) ||
                caregiverFullName.trim() === "",
            })}
            onClose={() => setModalActive(() => false)}
            onFinish={handleInviteCaregiver}
            visible={modalActive}
            primaryBtnLabel={"Invite caregiver"}
            hasSeparator={true}
          >
            <TextInput
              onChange={(e: { target: { value: string } }) =>
                setCaregiverFullName(() => e.target.value)
              }
              label="Name"
              placeHolder="First and last"
            />
            <TextInput
              onChange={(e: { target: { value: string } }) =>
                setCaregiverEmail(() => e.target.value)
              }
              label="Email"
              placeHolder="name@gmail.com"
            />
          </Modal>
        </div>
      </div>
    </div>
  );
};

export default CareTeam;
