import { useEffect, useState } from "react";
import { useAppDispatch, useAppSelector } from "../../store/redux.hooks";
import { isValidPhoneNumber } from "react-phone-number-input";
import cn from "classnames";

import {
  updateProfileReceiver,
  deleteCareReceiver,
} from "../../store/slices/admin.slice";

import { CareReceiver, IPhoneNumber } from "../../interfaces/app.interface";
import { CountryData } from "react-phone-input-2";
import { CountryCode } from "libphonenumber-js";
import { pronounOptions } from "../../utils/constants";

import Button from "../../components/Button/Button";
import TextInput from "../../components/TextInput";
import MenuDropDown from "../../components/MenuDropDown/MenuDropDown";
import { ImageKit } from "../../components/Image";
import Modal from "../../components/Modal/index";
import ImagePicker from "../../components/ImagePicker/ImagePicker";
import PhoneNumberInput from "../../components/PhoneNumberInput/PhoneNumberInput";

import styles from "./Profile.module.sass";
import { StripeStatus } from "../../interfaces/app.enums";
import { nameToPossessive } from "../../utils/helper.function";

interface IFiledsVladition {
  completePhoneNumber: boolean;
  careReceiverName: boolean;
  pronoun: boolean;
}

interface ProfileInterface {}

const Profile = ({}: ProfileInterface) => {
  const selectedCareReceiver: CareReceiver | null = useAppSelector(
    (state) => state.admin.selected_care_group?.care_receivers[0] ?? null
  );
  const [completePhoneNumber, setCompletePhoneNumber] =
    useState<IPhoneNumber | null>(null);
  const [careReceiverName, setCareReceiverName] = useState<string | undefined>();
  const [pronoun, setPronoun] = useState<string | null>(null);
  const [imgPath, setImgPath] = useState<File | null>();
  const [imageIsUploading, setImageIsUploading] = useState(false);
  const [filedsValidation, setFiledsValidation] = useState<IFiledsVladition>({
    completePhoneNumber: true,
    careReceiverName: true,
    pronoun: true,
  });
  const [removeModalActive, setRemoveModalActive] = useState(false);
  const [removeCareReceiverName, setRemoveCareReceiverName] = useState<
    string | null
  >(null);
  const [imageModalActive, setImageModalActive] = useState(false);
  const [adminsIds, setAdminsIds] = useState<string[]>([]);
  const selectedCareGroup = useAppSelector(
    (state) => state.admin.selected_care_group
  );
  const userIsInactive = useAppSelector(
    (state) =>
      state.admin.selected_care_group?.care_receivers[0].status ===
      StripeStatus.inactive
  );
  const currentUser = useAppSelector((state) => state.admin);
  const dispatch = useAppDispatch();

  useEffect(() => {
    selectedCareGroup &&
      setAdminsIds(selectedCareGroup?.admins.map((owner) => owner.profile_id));
  }, [selectedCareGroup]);

  useEffect(() => {
    setImgPath(null);
    setFiledsValidation({
      completePhoneNumber: true,
      careReceiverName: true,
      pronoun: true,
    });
    if (selectedCareReceiver) {
      setCareReceiverName(selectedCareReceiver?.name);
      setPronoun(selectedCareReceiver?.pronoun ?? null);
      setCompletePhoneNumber(selectedCareReceiver?.phone_number ?? null);
    }
  }, [selectedCareReceiver]);

  const openFilePicker = (ref?: React.RefObject<HTMLInputElement>) => {
    if (!!ref && ref.current?.click) {
      ref.current?.click();
    } else {
      document.getElementById("imagePickerRef")?.click();
    }
  };

  const removeCareReceiver = () => {
    dispatch(deleteCareReceiver(selectedCareReceiver?.id as string));
    setRemoveModalActive(false);
  };

  const onPhoneNumberInputChange = (
    phone: string,
    country: {} | CountryData
  ) => {
    if (userIsInactive) return;
    setFiledsValidation((state) => ({
      ...state,
      completePhoneNumber: isValidPhoneNumber(
        phone,
        country["countryCode"]?.toUpperCase()
      ),
    }));

    setCompletePhoneNumber({
      isoCode: country["countryCode"].toUpperCase(),
      countryCode: country["dialCode"],
      nsn: phone.substring(phone.indexOf(country["dialCode"]) + country["dialCode"].length),
      international: `+${phone}`,
    });
  };

  const uploadPhoto = () => {
    setImageModalActive(false);
    imgPath &&
      dispatch(
        updateProfileReceiver({
          isUpdateProfileScreen: true,
          id: selectedCareReceiver!.id,
          imageFile: imgPath as unknown as string,
        })
      );
  };

  const removePhoto = () => {
    setImgPath(null);
    dispatch(
      updateProfileReceiver({
        isUpdateProfileScreen: true,
        id: selectedCareReceiver!.id,
        imageFile: null,
      })
    );
  };

  const imageModalOnClose = () => {
    setImageModalActive(false);
    setImgPath(null);
  };

  const displayAvatar = () => {
    if (!!selectedCareReceiver?.image_uri) {
      return (
        <div className={styles.avatar}>
          <ImageKit
            color
            path={selectedCareReceiver.image_uri}
            width={96}
            height={96}
          />
        </div>
      );
    }
    return (
      <div className={styles.initialsContainer}>
        <p className={styles.initials}>
          {selectedCareReceiver?.name
            ?.split(" ")
            ?.map((a) => a[0]?.toUpperCase())
            ?.join("")}
        </p>
      </div>
    );
  };
  

  const submitFrom = () => {
    if (userIsInactive) return;
    const isNameValid = careReceiverName?.trim() !== "" && careReceiverName?.trim() !== null;
    const isPhoneNumberValid =
      !!completePhoneNumber?.international &&
      !!completePhoneNumber?.countryCode &&
      isValidPhoneNumber(
        completePhoneNumber?.international as string,
        completePhoneNumber?.countryCode as CountryCode
      );
    const isPronounValid = pronoun !== null && pronoun !== undefined;

    if (isNameValid && isPhoneNumberValid && isPronounValid) {
      if (
        selectedCareReceiver?.name !== careReceiverName ||
        selectedCareReceiver?.pronoun !== pronoun ||
        selectedCareReceiver?.phone_number?.international !==
          completePhoneNumber?.international
      ) {
        dispatch(
          updateProfileReceiver({
            isUpdateProfileScreen: true,
            id: selectedCareReceiver!.id,
            name: careReceiverName,
            pronoun: pronoun,
            phoneNumber: completePhoneNumber,
            imageFile: selectedCareReceiver?.image_uri,
          })
        );
      }
    } else {
      setFiledsValidation({
        completePhoneNumber: isPhoneNumberValid,
        careReceiverName: isNameValid,
        pronoun: isPronounValid,
      });
    }
  };

  const openImageModal = () => {
    if (userIsInactive) return;
    setImageModalActive(true);
  };

  const openRemoveModal = () => {
    setRemoveModalActive(true);
  };

  return (
    <>
      {/* <p className={styles.title}>
        {`${nameToPossessive(selectedCareReceiver?.name)} profile`}
      </p> */}
      <div className={styles.container}>
        {/* <div className={styles.subtitleContainer}> */}
          {/* <p className={styles.subtitle}>{"Edit profile"}</p> */}
          {/* <div className={styles.separator} />  */}
        {/* </div> */}
        <div className={cn(styles.formContainer)}>
          <div className={styles.profileImgContainer}>
            <div className={styles.avatar}>{displayAvatar()}</div>
            <Button btnClass={styles.uploadPhotoBtn} onClick={openImageModal}>
              {"Change profile photo"}
            </Button>
          </div>
          <TextInput
            onChange={(e) => {
              if (userIsInactive) return;
              const value = e.target.value;
              setFiledsValidation((state) => ({
                ...state,
                careReceiverName: value !== "",
              }));
              setCareReceiverName(() => value);
            }}
            label="Name or nickname*"
            value={careReceiverName ?? ""}
            placeHolder="First and last"
            className={cn(styles.textInput, {
              [styles.invalidInput]:
                filedsValidation.careReceiverName === false,
            })}
          />
          <MenuDropDown
            classDropdownHead={cn({
              [styles.invalidDropDown]: filedsValidation.pronoun === false,
            })}
            setValue={(value) => {
              if (userIsInactive) return;
              setFiledsValidation((state) => ({ ...state, pronoun: true }));
              setPronoun(value);
            }}
            label="Pronoun"
            value={pronoun ?? undefined}
            options={pronounOptions}
          />
          <div className={styles.numberContainer}>
            <div className={styles.label}>{"Phone"}</div>
            <div className={styles.phoneContainer}>
              <PhoneNumberInput
                value={selectedCareReceiver?.phone_number?.international}
                isInvalidInput={filedsValidation.completePhoneNumber === false}
                countryCode={selectedCareReceiver?.phone_number?.countryCode}
                onChange={onPhoneNumberInputChange}
              />
            </div>
          </div>
          <p className={styles.requriedFileds}>Required field*</p>
          <div className={styles.buttonsContainer}>
            {adminsIds.includes(currentUser.profile_id as string) && (
              <Button
                btnClass={cn(styles.removeBtn, {
                  [styles.subsriptionInactiveDeleteBtn]: userIsInactive,
                })}
                onClick={openRemoveModal}
              >
                {"Delete"}
              </Button>
            )}
            <Button
              btnClass={cn(styles.saveButton, {
                [styles.saveBtnDissabled]:
                  (selectedCareReceiver?.name === careReceiverName ||
                    filedsValidation.careReceiverName === false) &&
                  (selectedCareReceiver?.pronoun === pronoun ||
                    filedsValidation.pronoun === false) &&
                  (selectedCareReceiver?.phone_number?.countryCode ===
                    completePhoneNumber?.countryCode ||
                    filedsValidation.completePhoneNumber === false) &&
                  (selectedCareReceiver?.phone_number?.nsn ===
                    completePhoneNumber?.nsn ||
                    filedsValidation.completePhoneNumber === false),
              })}
              onClick={submitFrom}
            >
              {"Save changes"}
            </Button>
          </div>
          <Modal
            title={`Delete ${nameToPossessive(
              selectedCareReceiver?.name
            )} account`}
            primaryDescription={
              "Deleting this care receiver from Carl will end your subscription with this loved one and you’ll lose access to remote care features for them."
            }
            secundaryDescription={
              "Are you sure you would like to delete your care receiver from Carl?"
            }
            descriptionStyle={styles.modalDescription}
            onFinish={
              careReceiverName?.trim() === removeCareReceiverName?.trim()
                ? (removeCareReceiver as () => void)
                : null
            }
            onClose={() => setRemoveModalActive(false)}
            primaryBtnLabel={"Delete account"}
            primaryBtnStyle={styles.modalPrimaryButton}
            secundaryBtnLabel={"Keep account"}
            secundaryBtnStyle={styles.modalSecundaryButton}
            onSecundaryBtnClick={() => setRemoveModalActive(false)}
            visible={removeModalActive}
          >
            <TextInput
              placeHolder="Name"
              label="Enter care receiver’s name"
              inputStyle={styles.modalInputStyle}
              labelStyle={styles.modalInputLabel}
              onChange={(e: { target: { value: string } }) =>
                setRemoveCareReceiverName(e.target.value)
              }
            />
          </Modal>
          <Modal
            title={
              selectedCareReceiver?.image_uri
                ? "Profile photo"
                : "Add profile photo"
            }
            primaryBtnLabel={
              !imageIsUploading
                ? imgPath
                  ? "Confirm"
                  : selectedCareReceiver?.image_uri
                  ? "Upload photo"
                  : undefined
                : undefined
            }
            hasSeparator={false}
            onClose={imageModalOnClose}
            onFinish={imgPath ? uploadPhoto : openFilePicker}
            visible={imageModalActive}
            secundaryBtnStyle={styles.removeButton}
            secundaryBtnLabel={
              selectedCareReceiver?.image_uri && !imageIsUploading
                ? "Remove"
                : undefined
            }
            onSecundaryBtnClick={removePhoto}
          >
            <ImagePicker
              id={selectedCareReceiver?.id}
              setIsUploading={setImageIsUploading}
              img={imgPath ?? selectedCareReceiver?.image_uri}
              setImage={(img) => setImgPath(img)}
            />
          </Modal>
        </div>
      </div>
    </>
  );
};

export default Profile;
