import { useContext, useEffect, useState } from "react";
import { useAppDispatch, useAppSelector } from "../../store/redux.hooks";
import OutsideClickHandler from "react-outside-click-handler";
import packageJson from "../../../package.json";
import cn from "classnames";

import {
  signInUserWithGoogle,
  signInUserWithApple,
  resetError,
} from "../../store/slices/auth.slice";

import { useIntercom } from "react-use-intercom";

import { Image } from "../../components/Image";
import Modal from "../../components/Modal";

import styles from "./SignIn.module.sass";
import { useWindowWidth } from "../../hooks/useWindowWidth";
import Icon from "../../components/Icon";
import PeopleImage from "../../assets/images/people.png"
import carlLogo from "../../assets/images/Logo.svg"
import { useSearchParams } from "react-router-dom";
import { SegmentContext } from "../../contexts/segment";

const SignIn = ({isSignup}) => {
  const dispatch = useAppDispatch();
  const [isSignUp, setIsSignUp] = useState(isSignup);
  const [modalIsVisible, setModalIsVisible] = useState(false);
  const { boot, shutdown } = useIntercom();
  const [searchParams] = useSearchParams();  
  const partnerId = searchParams.get('partnerId');
  const context = useContext(SegmentContext);
  const {segment} = context;

  const authErrorCode = useAppSelector(
    (state) => state.authentication.errorCode
  );
  const width = useWindowWidth();
  const isMobileVersion = width < 639;

  useEffect(() => {
    segment.track('Dashboard Admin', partnerId)
    authErrorCode === 403 && setModalIsVisible(true);
    !!authErrorCode && dispatch(resetError());
  }, [authErrorCode, dispatch]);

  useEffect(() => {       
    boot()
    return () => shutdown();
  }, []);

  return (
    <>
       <div className={styles.carlLogoFrame}>
        <Image
            className={styles.carlLogo}
            src={carlLogo}
            srcDark={carlLogo}
            alt="Google"            
            srcSet={undefined}
            srcSetDark={undefined}
          />
        </div>
     {/* <Icon
        className={cn(styles.carlLogo, {
          [styles.absolutePosition]: !isMobileVersion,
        })}
        name="carlLogo"
        viewbox={"0 0 123 47"}
        fill="#002E1C"
        size={100}
      /> */}

    <div className={styles.frame}>

      {!isMobileVersion && <Image
            className={styles.imagepage}
            src={PeopleImage}
            srcDark={PeopleImage}
            alt="Google"            
            srcSet={undefined}
            srcSetDark={undefined}
          />}
    <div className={styles.signpage}>
    <div className={styles.signcard}>    
        
      <div className={cn("h3", styles.signuptitle)}>
        {isSignUp ? <p style={{color: "#FFD3D2"}}>Step 1</p>: null}     
        {isSignUp ? <p style={{width: "80%",textAlign: "center"}}>Create your account to checkout</p> : <p style={{width: "80%",textAlign: "center"}}>Log in to your account</p>} 
      </div>
      <div className={styles.btns}>
        <button
          onClick={() => {
            dispatch(signInUserWithGoogle(partnerId))
            segment.track('SignIn With Google ', partnerId)
          }}
          className={cn("button-stroke", styles.button)}
        >
          <Image
            className={styles.pic}
            src="/images/content/google.svg"
            srcDark="/images/content/google.svg"
            alt="Google"
            srcSet={undefined}
            srcSetDark={undefined}
          />
          Continue with Google
        </button>
        <button
          onClick={() => {
            dispatch(signInUserWithApple(partnerId))
            segment.track('Continue with Apple ', partnerId)
          }}
          className={cn("button-stroke", styles.button)}
        >
          <Image
            className={styles.pic}
            src="/images/content/apple-dark.svg"
            srcDark="/images/content/apple-dark.svg"
            alt="Apple"
            srcSet={undefined}
            srcSetDark={undefined}
          />
          Continue with Apple
        </button>
      </div>
      {isSignUp ? (
        <p className={styles.linkInfo}>
          Already have an account?{" "}
          <a onClick={() => setIsSignUp(false)} className={styles.link}>
            Log in
          </a>
        </p>
      ) : (
        <p className={styles.linkInfo}>
          Don’t have an account?{" "}
          <a onClick={() => setIsSignUp(true)} className={styles.link}>
            Sign up
          </a>
        </p>
      )}
    
      {/* <OutsideClickHandler onOutsideClick={() => {}}>
        <Modal
          title={"Email not recognized"}
          primaryDescription={
            "Looks like you're new here! Register for our waitlist to get early access."
          }
          onFinish={() =>
            (window.location.href = "https://www.hellocarl.com/waitlist")
          }
          onClose={() => setModalIsVisible(false)}
          primaryBtnLabel={"Join the waitlist"}
          visible={modalIsVisible}
        ></Modal>
      </OutsideClickHandler> */}
      </div>
      
      </div>
      <p className={cn(styles.appVersion)}>{`v${packageJson.version}`}</p>
      </div>      
    </>
  );
};

export default SignIn;
