import Button from "../Button/Button";
import cn from "classnames";
import styles from "./ChooseProduct.module.sass";
import { useState } from "react";
import { useWindowWidth } from "../../hooks/useWindowWidth";
import ConnectionImage from "../../assets/images/mobile-application-pairing.png";
import { useAppSelector } from "../../store/redux.hooks";

const ChooseProduct = () => {
  const width = useWindowWidth();
  const [isBannerVisible, setIsBannerVisible] = useState(true);
  const admin = useAppSelector(
    (state) => state.admin
  );
  const selectedCareReceiverId = useAppSelector(
    (state) => state.admin.selected_care_group?.care_receivers[0].id
  );

  let link = ""
  switch (admin.partnerId) {
    case "website":
      link = String(process.env.REACT_APP_PRICING_LINK);
    break;
    case "JCC01":
      link = String(process.env.REACT_APP_PARTNERSHIP_LINK_JCC1);
    break;   
    case "HLTH-EU2024":
      link = String(process.env.REACT_APP_PARTNERSHIP_LINK_HLTH);
    break;
    case "pilot": {
      const pilot_link = String(process.env.REACT_APP_PAYMENT_PILOT_LINK_PHONE);
      let billingUrl = `${pilot_link}?prefilled_email=${admin.email}&client_reference_id=${admin.profile_id}`;
      link = selectedCareReceiverId ? billingUrl+"_"+selectedCareReceiverId : billingUrl    
      break;
    }
    case "lp01": {
      const pilot_link = String(process.env.REACT_APP_PAYMENT_LP01_LINK_PHONE);
      let billingUrl = `${pilot_link}?prefilled_email=${admin.email}&client_reference_id=${admin.profile_id}`;
      link = selectedCareReceiverId ? billingUrl+"_"+selectedCareReceiverId : billingUrl
      break;
    }
    default:
      link = String(process.env.REACT_APP_PRICING_LINK);
      break;
  }

  const isMobileVersion = width < 350;

  return isBannerVisible ? (
    <div className={cn(styles.card)}>      
    <h5 className={cn(styles.title)}>{"👉 Step 1: Choose a product" }</h5>
      <div className={!isMobileVersion ? styles.containerHorizontal: styles.containerVertical}>
          <img          
            width={119}
            height={100}
            onClick={() => {}}
            alt="Person Logo"
            className={styles.downloadFromStore}
            src={ConnectionImage}
          />
          <div className={styles.manageProductContainer}>            
            <Button onClick={() => {
              (admin.partnerId === "website") ? window.location.replace(String(process.env.REACT_APP_PRICING_LINK)) : window.location.replace(link) ;
            }} btnClass={cn(styles.buttonPrimary)}>
              {"Choose product"}
            </Button>
        </div>        
    </div>
    </div>
  ) : (
    <div />
  );
};

export default ChooseProduct;
