import { useContext, useEffect, useState } from "react";
import { useAppDispatch, useAppSelector } from "./store/redux.hooks";
import { IntercomProvider } from "react-use-intercom";
import { onAuthStateChanged, signOut } from "firebase/auth";
import { auth } from "./services/firebase";
import { toast } from "react-toastify";
import { IKContext } from "imagekitio-react";
import {
  Route,
  Routes,
  useLocation,
  useNavigate,
  useSearchParams,
} from "react-router-dom";

import "react-toastify/dist/ReactToastify.css";
import "./styles/app.sass";

import ProtectedRoute, {
  ProtectedRouteProps,
} from "./routes/ProtectedRoute.guard";

import { Environment, StripeStatus } from "./interfaces/app.enums";
import { logOutUser, verifyAuth } from "./store/slices/auth.slice";
import { createPortal } from "./store/slices/admin.slice";
import { authenticator } from "./utils/helper.function";
import { getAdmin } from "./store/slices/admin.slice";
import { acceptInviteCaregiver } from "./store/slices/admin.slice";

import CareTeam from "./pages/CareTeam/CareTeam";
import PageWithSidebar from "./components/PageWithSidebar/PageWithSidebar";
import FormPage from "./components/FormPage/FormPage";
import SignIn from "./pages/SignIn/SignIn";
import Profile from "./pages/Profile/Profile";
import StripePricingTable from "./components/StripePricingTable/StripePricingTable";
import Modal from "./components/Modal";
import TypeformSurvey from "./pages/TypeformSurvey/TypeformSurvey";
import LightMenu from "./pages/LightMenu/LightMenu";
import LoadingAccountSetup from "./pages/LoadingAccountSetup/LoadingAccountSetup";
import { AnalyticsBrowser } from "@segment/analytics-next";
import { SegmentContext } from "./contexts/segment";

const segment = AnalyticsBrowser.load({ writeKey: String(process.env.REACT_APP_SEGMENT_KEY) })

const App = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const location = useLocation();
  const [searchParams, _] = useSearchParams();

  const [inviteWarningModal, setInviteWarningModal] = useState(false);

  const addCareReceiverResponse = useAppSelector(
    (state) => state.authentication.addCaregiverResponse
  );
  const defaultProtectedRouteProps: Omit<ProtectedRouteProps, "outlet"> = {
    redirectPath: "/sign-in",
  };
  const isAuthenticated = useAppSelector(
    (state) => state.authentication.authenticated
  );
  const admin = useAppSelector((state) => state.admin);
  const authError = useAppSelector((state) => state.authentication.error);
  const authErrorCode = useAppSelector(
    (state) => state.authentication.errorCode
  );
  const adminIsLoading = useAppSelector((state) => state.admin.loading);
  const authIsLoading = useAppSelector((state) => state.authentication.loading);

  useEffect(() => {
    if (addCareReceiverResponse === 201)
      dispatch(verifyAuth(addCareReceiverResponse));
  }, [addCareReceiverResponse]);

  useEffect(() => {
    authError &&
      authErrorCode !== 403 &&
      toast.error(authError, {
        toastId: authError as string,
        autoClose: 50000,
      });
  }, [authError]);

  useEffect(() => {
    onAuthStateChanged(auth, async (user) => {
      if (!admin.profile_id && user && isAuthenticated) {
        await dispatch(getAdmin());
      } else {
        if (location.pathname === "/sign-up") {             
          navigate(`/sign-up?${searchParams.toString()}`, { replace: true })
        } else {
          navigate(`/sign-in?${searchParams.toString()}`, { replace: true })
        }
      }
    });    
  }, []);

  useEffect(() => {
    if (auth.currentUser && admin.profile_id) {    
      if (location.pathname === "/sign-in" || location.pathname === "/sign-up") {          
          navigate(`/?${searchParams.toString()}`, { replace: true })    
        } else {
          navigate(location.pathname, { replace: true })    
        }
    } 
  },[admin.profile_id, auth.currentUser]);

  const returnSection = () => {
    if (!adminIsLoading && isAuthenticated && admin) {
      if (location.pathname.includes("care-team")) return <CareTeam />;
      if (location.pathname.includes("profile")) return <Profile />;
      if (location.pathname.includes("billing")) {
        if (
          admin.selected_care_group?.care_receivers[0].status ===
          StripeStatus.active
        ) {
          navigate(`care-team/${localStorage.getItem("selectedUser")}`);
          localStorage.setItem("section", "care-team");
          dispatch(createPortal(admin.selected_care_group?._id as string));
        } else {
          return (
            <StripePricingTable
              email={admin.email}
              ids={admin.profile_id + "_" + admin.selected_care_group?.care_receivers[0].id}
            />
          );
        }
      }
    }
  };

  return (
    <>
    <SegmentContext.Provider value={{segment}}>
      <Modal
        primaryBtnLabel={"Ok"}
        title={"Oops"}
        primaryDescription={`${"It seems that you already have a care receiver associated with this account. Currently, we don't support multiple care receivers. However, we're actively working on this feature and will update you soon!"}`}
        onFinish={() => setInviteWarningModal(false)}
        visible={inviteWarningModal}
      />
      <IKContext
        urlEndpoint={process.env.REACT_APP_IMAGEKIT_URL_ENDPOINT}
        publicKey={process.env.REACT_APP_IMAGEKIT_PUBLIC_KEY}
        authenticator={authenticator}
      >
        <IntercomProvider
          appId={process.env.REACT_APP_INTERCOM_APP_ID as string}
        >
          <Routes>
            <Route path={"/:section/:id"}>
              <Route
                index
                element={
                  <ProtectedRoute
                    {...defaultProtectedRouteProps}
                    outlet={
                      <PageWithSidebar>{returnSection()}</PageWithSidebar>
                    }
                  />
                }
              />
            </Route>
            <Route path={"/"}>
              <Route
                index
                element={
                  <ProtectedRoute
                    {...defaultProtectedRouteProps}
                    outlet={
                      <PageWithSidebar>
                        <LightMenu/>
                      </PageWithSidebar>
                    }
                  />
                }
              />
            </Route>
            
            <Route
              path="/sign-in"
              element={
                <SignIn isSignup={false} />
              }
            />

            <Route
              path="/sign-up"
              element={
                <SignIn isSignup={true} />
              }
            />

            {/* <Route
              path="/create-account"
              element={
                <ProtectedRoute
                  {...defaultProtectedRouteProps}
                  redirectPath="/"
                  extraCondition={admin.first_login}
                  outlet={
                    <FormPage>
                      <CreateAccount />
                    </FormPage>
                  }
                />
              }
            /> */}
            
            <Route
              path="/purchase-survey"
              element={
                <ProtectedRoute
                  {...defaultProtectedRouteProps}
                  redirectPath="/"
                  outlet={                    
                    <TypeformSurvey />                    
                  }
                />
              }
            />

            {/* <Route
              path="/add-loved-one"
              element={
                <ProtectedRoute
                  {...defaultProtectedRouteProps}
                  outlet={
                    <FormPage>
                      <CreateReceiver />
                    </FormPage>
                  }
                />
              }
            /> */}

            {/* <Route
              path="/billing"
              element={
                <ProtectedRoute
                  {...defaultProtectedRouteProps}
                  outlet={
                    <FormPage>
                      <Billing />
                    </FormPage>
                  }
                />
              }
            /> */}

            <Route
              path="/loading-account"
              element={
                <ProtectedRoute
                  {...defaultProtectedRouteProps}
                  outlet={
                    <FormPage>
                      <LoadingAccountSetup />
                    </FormPage>
                  }
                />
              }
            />
          </Routes>
        </IntercomProvider>
      </IKContext>
      </SegmentContext.Provider>
    </>
  );
};

export default App;
