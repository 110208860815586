import { useNavigate, useSearchParams } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "../../store/redux.hooks";
import { resetAddLovedOne } from "../../store/slices/addLovedOne.slice";

import Button from "../../components/Button/Button";

import styles from "./LightMenu.module.sass";
import DownloadAdminAppBanner from "../../components/DownloadAdminAppBanner/DownloadAdminAppBanner";
import ManageSubscription from "../../components/ManageSubscription/ManageSubscription";
import Profile from "../Profile/Profile";
import CareReceiverSection from "../CareReceiverSection/CareReceiverSection";
import { ImageKit } from "../../components/Image";
import ChooseProduct from "../../components/ChooseProduct/ChooseProduct";
import { useWindowWidth } from "../../hooks/useWindowWidth";
import { logOutUser } from "../../store/slices/auth.slice";
import Modal from "../../components/Modal";
import Terms from "../Terms/Terms";
import { useContext, useState } from "react";
import Icon from "../../components/Icon";
import { SegmentContext } from "../../contexts/segment";

const FooterSection = () => {
  const dispatch = useAppDispatch();
  const [showPrivacy, setShowPrivacy] = useState(false);
  const [showTerms, setShowTerms] = useState(false);

  return (
  <div className={styles.logout}>
      <Modal
          outerClassName={styles.modalOuter}
          title={"Privacy Policy"}       
          visible={showPrivacy}
          hasSeparator={false}
          onClose={() => setShowPrivacy(false)}
          onFinish={()=>{}}        
          secundaryBtnStyle={styles.removeButton}        
          onSecundaryBtnClick={()=>{}}>            
          <Terms termlyId="9ea49ad0-cc46-435f-9025-3829ab013778" />          
        </Modal> 

        <Modal
          outerClassName={styles.modalOuter}
          title={"Terms and Conditions"}       
          visible={showTerms}
          hasSeparator={false}
          onClose={() => setShowTerms(false)}
          onFinish={()=>{}}        
          secundaryBtnStyle={styles.removeButton}        
          onSecundaryBtnClick={()=>{}}>                        
          <Terms termlyId="bc7505f5-5d99-4bf7-aa93-95e263f79488"/>          
        </Modal> 
    <div
        onClick={() => dispatch(logOutUser())}>
      <p>Log out</p>
    </div>
    <div className={styles.termsandprivacy}>
      <div
        onClick={() => { setShowTerms(true)}}>
        <p>Terms & conditions</p>
      </div>
      |
      <div
        onClick={() => { setShowPrivacy(true)}}>
        <p>Privacy policy</p>
      </div>
    </div>
  </div>
  );
}

const LightMenu = () => {
  const width = useWindowWidth();
  const isMobileVersion = width < 767;
  
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  
  const context = useContext(SegmentContext);
  const {segment} = context;

  const careGroups = useAppSelector((state) => state.admin.caregroups);  
  const selectedCareReceiverId = useAppSelector(
    (state) => state.admin.selected_care_group?.care_receivers[0].id
  );
  const admin = useAppSelector((state) => state.admin);
  
  const [searchParams] = useSearchParams();  
  const productId = searchParams.get('productId');
  const partnerId = searchParams.get('partnerId');

  if (admin.subscriptions === undefined || admin.subscriptions?.length === 0) {

    if (productId) {
      let link = ""
      switch (productId) {
        case "tablet":
          link = (partnerId === "website") ? String(process.env.REACT_APP_PAYMENT_LINK_TABLET) : String(process.env.REACT_APP_PAYMENT_PARTNER_LINK_TABLET)
        break;
        case "phone":
          if (partnerId === "website" || partnerId === undefined) 
            link = String(process.env.REACT_APP_PAYMENT_LINK_PHONE)
          else if (partnerId === "pilot")
            link = String(process.env.REACT_APP_PAYMENT_PILOT_LINK_PHONE)
          else if (partnerId === "lp01")
            link = String(process.env.REACT_APP_PAYMENT_LP01_LINK_PHONE)
          else 
            link =  String(process.env.REACT_APP_PAYMENT_PARTNER_LINK_PHONE)                
        break;
        case "byod":
          link = (partnerId === "website") ? String(process.env.REACT_APP_PAYMENT_LINK_BYOD) : String(process.env.REACT_APP_PAYMENT_PARTNER_LINK_BYOD)
        break;
      
        default:
          link = String(process.env.REACT_APP_PAYMENT_PARTNER_LINK_BYOD)
          break;
      }
      segment.track('Redirect to Stripe ', partnerId)
      let billingUrl = `${link}?prefilled_email=${admin.email}&client_reference_id=${admin.profile_id}`;
      billingUrl = selectedCareReceiverId ? billingUrl+"_"+selectedCareReceiverId : billingUrl      
      window.location.replace(billingUrl)          
    } else {
      return (    
        <div className={styles.container}>     
          {isMobileVersion ? <Icon
            className={styles.logo}
            name="carlLogo"
            viewbox={"0 0 123 47"}
            fill="#002E1C"
            size={100}
          /> : null }
          {(careGroups?.length > 0) && (
            <CareReceiverSection />    
          )}               
          <ChooseProduct />                             
          <DownloadAdminAppBanner />    
          {isMobileVersion ? <FooterSection /> : null}
        </div>
      );
    }       
  } else {

  return (    
    <div className={styles.container}>     
      {isMobileVersion ? <Icon
        className={styles.logo}
        name="carlLogo"
        viewbox={"0 0 123 47"}
        fill="#002E1C"
        size={100}
      /> : null}
      {(careGroups?.length > 0) && (
        <CareReceiverSection />    
      )}             
        <ManageSubscription />                
      <DownloadAdminAppBanner />      
      {isMobileVersion ? <FooterSection /> : null}
    </div>
  );
};
}

export default LightMenu;
