import React, { useEffect, useState } from "react";
import { useIntercom } from "react-use-intercom";
import cn from "classnames";

import { useAppDispatch, useAppSelector } from "../../store/redux.hooks";
import {
  changeSection,
  resetActionCompleteNotification,
  resetError,
} from "../../store/slices/admin.slice";

import CloseButton from "../CloseButton/CloseButton";
import Header from "../Header/Header";
import Sidebar from "../Sidebar";
import { ToastContainer } from "react-toastify";
import { resetError as authResetError } from "../../store/slices/auth.slice";
import styles from "./PageWithSidebar.module.sass";
import { toast } from "react-toastify";
import { useLocation, useNavigate } from "react-router-dom";
import Button from "../Button/Button";
import { StripeStatus } from "../../interfaces/app.enums";

interface IProps {
  children: React.ReactNode;
}

const PageWithSidebar = ({ children }: IProps) => {
  const dispatch = useAppDispatch();
  const location = useLocation();
  const navigate = useNavigate();
  const { boot, shutdown, update } = useIntercom();

  const caregiverError = useAppSelector((state) => state.admin.error);
  const careGiver = useAppSelector((state) => state.admin);
  const careGiverName = useAppSelector((state) => state.admin.name);
  const careGiverEmail = useAppSelector((state) => state.admin.email);
  const careGiverPartner = useAppSelector((state) => state.admin.partnerId);

  const userIsInactive = useAppSelector(
    (state) =>
      (state.admin.subscriptions != null && state.admin.subscriptions?.length > 0) &&
      state.admin.selected_care_group?.care_receivers[0].status ===
      StripeStatus.inactive
  );
  const caregiverActionCompleteNotification = useAppSelector(
    (state) => state.admin.actionCompleteNotification
  );


  const selectedCareReceiverId = useAppSelector(
    (state) => state.admin.selected_care_group?.care_receivers[0].id
  );
  const caregiverId = useAppSelector((state) => state.admin.profile_id);
  const intercomUserHash = useAppSelector((state) => state.admin.intercom_user_hash);

  const [visible, setVisible] = useState(false);

  useEffect(() => {
    if (intercomUserHash && caregiverId) {
      boot({
        email: String(careGiverEmail),
        name: String(careGiverName) ,
        userHash: intercomUserHash,
        userId: caregiverId,
      });
    }
    return () => shutdown();
  }, [intercomUserHash, caregiverId, boot]);

  useEffect(() => {
    toast.success(caregiverActionCompleteNotification, {
      toastId: caregiverActionCompleteNotification as string,
      autoClose: 50000,
    });
    toast.error(caregiverError, {
      toastId: caregiverError as string,
      autoClose: 50000,
    });
    dispatch(resetActionCompleteNotification());
    dispatch(authResetError());
    dispatch(resetError());
  }, [caregiverError, caregiverActionCompleteNotification, dispatch]);

  // useEffect(() => {
  //   const section = localStorage.getItem("section") ?? "care-team";
  //   navigate(
  //     careGroups?.length > 0
  //       ? !!localStorage.getItem("selectedUser")
  //         ? `/${section}/${localStorage.getItem("selectedUser")}`
  //         : `/care-team/${careGroups[0]?._id}`
  //       : "/"
  //   );
  // }, [careGroups, careGroups?.length]);

  return (
    <>
      <div className={styles.page}>
        <Sidebar
          className={cn(styles.sidebar, {
            [styles.visible]: visible,
          })}
          onClose={() => {
           update({hideDefaultLauncher: false})
            setVisible(false);
          }}
        />
        <div className={cn(styles.inner)}>
          <ToastContainer
            hideProgressBar={true}
            pauseOnFocusLoss={true}
            newestOnTop={true}
            className={styles.toastBody}
            toastClassName={styles.toast}
            closeOnClick={false}
            closeButton={<CloseButton closeToast />}
          />
          {userIsInactive && !location.pathname.includes("billing") && (
            <>
              <div className={styles.overlay} />
              <div className={styles.reactivateSubscriptionContainer}>
                <div className={styles.reactivateTextContainer}>
                  <p className={cn("h5", styles.reactivateSubscriptionTitle)}>
                    Your subscription is inactive!
                  </p>
                  <p className={styles.reactivateSubscriptionSubTitle}>
                    Full access to all features requires an active subscription.
                    Activate now to enjoy the complete Carl experience.
                  </p>
                </div>
                <Button
                  btnClass={styles.reactivateSubscriptionBtn}
                  onClick={() => {
                    const link = (careGiverPartner === "website") ? String(process.env.REACT_APP_PAYMENT_LINK_BYOD) : String(process.env.REACT_APP_PAYMENT_PARTNER_LINK_BYOD)                    
                    let billingUrl = `${link}?prefilled_email=${careGiverEmail}&client_reference_id=${caregiverId}`;
                    billingUrl = selectedCareReceiverId ? billingUrl+"_"+selectedCareReceiverId : billingUrl
                    window.location.replace(billingUrl)
                  }}
                >
                  Reactivate subscription
                </Button>
              </div>
            </>
          )}
          {children}
        </div>
      </div>
    </>
  );
};

export default PageWithSidebar;
