import React, { useState } from "react";
import cn from "classnames";
import OutsideClickHandler from "react-outside-click-handler";
import styles from "./MenuDropDown.module.sass";

interface IProps {
  className?: string;
  classDropdownHead?: string;
  classDropdownLabel?: string;
  value?: string;
  setValue: (value: string) => void;
  options?: string[];
  label?: string;
}

const MenuDropDown = ({
  className,
  classDropdownHead,
  classDropdownLabel,
  value,
  setValue,
  options,
  label,
}: IProps) => {
  const [visible, setVisible] = useState<boolean>(false);

  const handleClick = (value: string) => {
    setValue(value);
    setVisible(false);
  };

  return (
    <OutsideClickHandler onOutsideClick={() => setVisible(false)}>
      {label && (
        <div className={cn(styles.label, classDropdownLabel)}>{label}</div>
      )}
      <div
        className={cn(styles.dropdown, className, { [styles.active]: visible })}
      >
        <div
          className={cn(styles.head, classDropdownHead)}
          onClick={() => setVisible(!visible)}
        >
          <div className={cn(styles.selection, { [styles.placeHolder]: !value } )}>{value ?? "Select"}</div>
        </div>
        <div className={cn(styles.body, { [styles.bodyUp]: true })}>
          {options?.map((x, index) => (
            <div
              className={cn(styles.option)}
              onClick={() => handleClick(x)}
              key={index}
            >
              {x}
            </div>
          ))}
        </div>
      </div>
    </OutsideClickHandler>
  );
};

export default MenuDropDown;
