import cn from "classnames";
import styles from "./TextInput.module.sass";
import Icon from "../Icon";

interface IProps {
  className?: string;
  labelStyle?: string;
  inputStyle?: string;
  label?: string;
  icon?: string;
  copy?: string;
  currency?: string;
  placeHolder?: string;
  value?: string;
  autoFocus?: boolean;
  onChange: (e: { target: { value: string } }) => void;
}
const TextInput = ({
  className,
  labelStyle,
  inputStyle,
  label,
  icon,
  copy,
  currency,
  placeHolder,
  value,
  autoFocus,
  onChange,
  ...props
}: IProps) => {

  return (
    <div
      className={cn(
        styles.field,
        { [styles.fieldIcon]: icon },
        { [styles.fieldCopy]: copy },
        { [styles.fieldCurrency]: currency },
        className
      )}
    >
      {label && <div className={cn(labelStyle, styles.label)}>{label} </div>}
      <div className={styles.wrap}>
        <input
          value={value}
          autoFocus={autoFocus ?? false}
          placeholder={placeHolder}
          className={cn(styles.input, inputStyle)}
          onChange={onChange}
          {...props}
        />
        {icon && (
          <div className={styles.icon}>
            <Icon name={icon} size={24} />{" "}
          </div>
        )}
        {copy && (
          <button className={styles.copy}>
            <Icon name="copy" size={24} />{" "}
          </button>
        )}
        {currency && <div className={styles.currency}>{currency}</div>}
      </div>
    </div>
  );
};

export default TextInput;
