import Button from "../Button/Button";
import CloseButton from "../CloseButton/CloseButton";
import cn from "classnames";
import styles from "./ManageSubscription.module.sass";
import { testflightLink } from "../../utils/constants";
import { useState } from "react";
import { useWindowWidth } from "../../hooks/useWindowWidth";
import ConnectionImage from "../../assets/images/mobile-application-pairing.png";
import { useAppDispatch, useAppSelector } from "../../store/redux.hooks";
import { useNavigate } from "react-router-dom";
import { createPortal, getStripePortal } from "../../store/slices/admin.slice";

const ManageSubscription = () => {
  const width = useWindowWidth();
  const [isBannerVisible, setIsBannerVisible] = useState(true);
  const admin = useAppSelector(
    (state) => state.admin
  );
  const dispatch = useAppDispatch();
  const isMobileVersion = width < 640;

  return isBannerVisible ? (
    <div className={cn(styles.card)}>    
      <h5 className={cn(styles.title)}>{"✅ Step 1: Carl Connect plan"}</h5>      
      <div className={!isMobileVersion ? styles.containerHorizontal: styles.containerVertical}>
          <img          
            width={119}
            height={100}
            onClick={() => {}}
            alt="Person Logo"
            className={styles.downloadFromStore}
            src={ConnectionImage}
          />
          <div className={styles.manageSubscriptionContainer}>                
            <Button onClick={() => {
              (admin.subscriptions !== undefined && admin.subscriptions?.length>0) ? dispatch(getStripePortal(String(admin.subscriptions![0].customer_id))) : <></> ;
            }} btnClass={cn(styles.button)}>
              {"Manage Subscription"}
            </Button> 
        </div>        
    </div>
    </div>
  ) : (
    <div />
  );
};

export default ManageSubscription;
