import cn from "classnames";
import styles from "./CareReceiverSection.module.sass";
import { CareReceiver } from "../../interfaces/app.interface";
import { useState } from "react";
import { useWindowWidth } from "../../hooks/useWindowWidth";
import { useAppDispatch, useAppSelector } from "../../store/redux.hooks";
import { useNavigate } from "react-router-dom";
import Button from "../../components/Button/Button";
import { ImageKit } from "../../components/Image";
import { nameToPossessive } from "../../utils/helper.function";
import Modal from "../../components/Modal";
import Profile from "../Profile/Profile";


const CareReceiverSection = () => {
  const width = useWindowWidth();
  const [isBannerVisible, setIsBannerVisible] = useState(true);
  const [showEditProfile, setShowEditProfile] = useState(false);

  const selectedCareReceiver: CareReceiver | null = useAppSelector(
    (state) => state.admin.caregroups[0]?.care_receivers[0] ?? null
  );

  return isBannerVisible ? (    
    <div className={cn(styles.card)}>
      <div className={styles.containerVertical}>
        <div className={styles.containerHorizontal}>
        <Modal
            title={"Edit Profile"}       
            visible={showEditProfile}
            hasSeparator={false}
            onClose={() => setShowEditProfile(false)}
            onFinish={()=>{}}        
            secundaryBtnStyle={styles.removeButton}        
            onSecundaryBtnClick={()=>{}}>            
          <Profile />
        </Modal> 

        {selectedCareReceiver?.image_uri ? (
          <div className={styles.avatar}>
              <ImageKit
                color
                path={selectedCareReceiver?.image_uri}
                width={48}
                height={48}
              />
          </div>
          ) : (
            <div className={styles.initialsContainer}>
              <p className={styles.initials}>
                {selectedCareReceiver?.name
                  ?.split(" ")
                  ?.map((a) => a[0]?.toUpperCase())
                  ?.join("")}
              </p>
            </div>
          )}
          <div className={styles.containerVertical}>
            <h1 className={styles.title}>{`${selectedCareReceiver?.name}`}</h1>
            {selectedCareReceiver?.phone_number ? <h4 >{`${selectedCareReceiver?.phone_number?.international}`}</h4> : null}
          </div>            
            
        </div>
            <Button btnClass={styles.editProfileBtn} onClick={() => setShowEditProfile(!showEditProfile)}>
              {"Edit Profile"}
            </Button>        
        </div>
    </div>
  ) : (
    <div />
  );
};

export default CareReceiverSection;
