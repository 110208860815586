import { Buffer } from "buffer";

export const decodeBase64 = (data: String) => {
  return Buffer.from(data, "base64").toString("ascii");
};

export const convertBase64 = async (file: File) => {
  return new Promise((resolve, reject) => {
    const fileReader = new FileReader();
    fileReader.readAsDataURL(file);
    fileReader.onload = () => {
      resolve(fileReader.result);
    };
    fileReader.onerror = (error) => {
      reject(error);
    };
  });
};

export const isValidEmail = (email: string | null | undefined) => {
  return !email
    ? false
    : email.match(
        /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
      )
    ? true
    : false;
};

export const authenticator = async () => {
  try {
    const response = await fetch(
      `${process.env.REACT_APP_CARL_ENDPOINT}/imagekit/auth`
    );

    if (!response.ok) {
      const errorText = await response.text();
      throw new Error(
        `Request failed with status ${response.status}: ${errorText}`
      );
    }

    const data = await response.json();
    const { signature, expire, token } = data;
    return { signature, expire, token };
  } catch (error: any) {
    throw new Error(`Authentication request failed: ${error.message}`);
  }
};

export const nameToPossessive = (name: string | null | undefined) => {
  return !!name
    ? `${name}${
        name
          .split(" ")?.[0]
          ?.charAt(name?.split(" ")?.[0].length - 1)
          .toLocaleLowerCase() === "s"
          ? "'"
          : "'s"
      }`
    : "";
};

export const isEmailValid = (email: string | null) =>
  /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(email ?? '');
