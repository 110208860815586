import { Dispatch, createSlice } from "@reduxjs/toolkit";
import { RootState } from "../index.store";

interface IChachedImage {
  [imagePath: string]: string;
}

const initialState: IChachedImage = {};

export const imageCachingSlice = createSlice({
  name: "imageCaching",
  initialState,
  reducers: {
    addImageUrlToDictionary: (state, action) => {
      return {
        ...state,
        [action.payload.imagePath]: action.payload.imageUrl,
      };
    },
  },
});

export const cacheImage =
  ({
    profileId,
    imagePath,
    imageUrl,
  }: {
    profileId: string;
    imagePath: string;
    imageUrl: string;
  }) =>
  (dispatch: Dispatch) => {
    dispatch(addImageUrlToDictionary({ profileId, imagePath, imageUrl }));
  };

export const { addImageUrlToDictionary } = imageCachingSlice.actions;
export const selectImageCaching = (state: RootState) => state.imageCaching;
export const imageCachingReducer = imageCachingSlice.reducer;
