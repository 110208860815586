import { Dispatch, PayloadAction, createSlice } from "@reduxjs/toolkit";
import {
  GoogleAuthProvider,
  OAuthProvider,
  onAuthStateChanged,
  signInWithPopup,
  signOut,
} from "firebase/auth";

import { Authentication } from "../../interfaces/app.interface";
import { RootState } from "../index.store";
import { auth } from "../../services/firebase";
import axios from "axios";

const endpoint = process.env.REACT_APP_CARL_ENDPOINT;

const provider = new GoogleAuthProvider();
provider.setCustomParameters({
  prompt: "select_account",
});
provider.addScope("https://www.googleapis.com/auth/userinfo.email");
provider.addScope("https://www.googleapis.com/auth/userinfo.profile");

const initialState: Authentication = {
  loading: false,
  authenticated: false,
  addCaregiverResponse: null,
  error: null,
  errorCode: null,
};

interface ErrorPayload {
  message?: string;
  code?: number;
}

export const authenticationSlice = createSlice({
  name: "authentication",
  initialState,
  reducers: {
    onSuccessfulLogin: (state) => {
      if (!state.authenticated)
        return {
          ...state,
          loading: false,
          authenticated: true,
          error: null,
        };
    },
    onLoginError: (state, action: PayloadAction<ErrorPayload>) => {
      return {
        ...state,
        errorCode: action.payload.code,
        error: action.payload.message,
        loading: false,
      };
    },
    onLogOut: () => {
      localStorage.removeItem("section");
      localStorage.removeItem("selectedUser");
      localStorage.removeItem("persist:root");
      window.location.pathname = "/sign-in";
      return initialState;
    },
    resetError: (state) => {
      return {
        ...state,
        loading: false,
        error: null,
        errorCode: null,
      };
    },
    onAddCaregiverResponse: (state, action) => {
      return {
        ...state,
        addCaregiverResponse: action.payload.status,
        loading: false,
      };
    },
    toggleLoading: (state) => ({
      ...state,
      loading: true,
    }),
  },
});

export const verifyAuth =
  (addCaregiverResponse: number | null | undefined) =>
  async (dispatch: Dispatch) => {
    onAuthStateChanged(auth, async (user) => {
      if (user !== null && addCaregiverResponse === 201) {
        dispatch(onSuccessfulLogin());
      }
    });
  };

export const signInUserWithGoogle = (partnerId) => async (dispatch: Dispatch) => {
  try {
    await signInWithPopup(auth, provider);
    dispatch(toggleLoading());
    const registerSuccessfully = await createAdmin(dispatch, partnerId);

    if (registerSuccessfully === 200) dispatch(onSuccessfulLogin());
  } catch (error: any) {
    console.log(error.message);
    if (
      error.code !== "auth/popup-closed-by-user" &&
      error.code !== "auth/cancelled-popup-request"
    )
      dispatch(onLoginError({ message: error?.message }));
  }
};

export const signInUserWithApple = (partnerId) => async (dispatch: Dispatch) => {
  try {
    const provider = new OAuthProvider("apple.com");
    provider.addScope("email");
    provider.addScope("name");

    await signInWithPopup(auth, provider);
    dispatch(toggleLoading());
    const registerSuccessfully = await createAdmin(dispatch, partnerId);

    registerSuccessfully === 200 && dispatch(onSuccessfulLogin());
  } catch (error: any) {
    console.log(error.message);
    if (
      error.code !== "auth/popup-closed-by-user" &&
      error.code !== "auth/cancelled-popup-request"
    )
      dispatch(onLoginError({ message: error?.message }));
  }
};

const createAdmin = async (dispatch: Dispatch, partnerId: String) => {
  try {
    const idToken = await auth.currentUser?.getIdToken(true);
    const inviteCode = localStorage.getItem("inviteCode");
    const createAdminResponse = await axios.post(
      `${endpoint}/admin/create`,
      {...(!!inviteCode && {inviteCode: inviteCode}),...(!!partnerId && {partnerId: partnerId})},
      {
        headers: { Authorization: `Bearer ${idToken}` },
      }
    );

    dispatch(
      onAddCaregiverResponse({
        status: createAdminResponse.status,
        data: createAdminResponse.data,
      })
    );

    return createAdminResponse.status;
  } catch (error: any) {
    console.log(error.message);
    dispatch(
      onLoginError({ code: error.response.status, message: error.message.toString() })
    );
    return false;
  }
};

export const resetAddCgRespounse = () => (dispatch: Dispatch) => {
  dispatch(
    onAddCaregiverResponse({
      status: null,
      data: null,
    })
  );
};

export const logOutUser = () => async (dispatch: Dispatch) => {
  try {
    await signOut(auth);
    dispatch(onLogOut());
  } catch (err) {
    console.log(`logOutUser ERROR: ${err}`);
  }
};

export const {
  onSuccessfulLogin,
  onLoginError,
  onLogOut,
  resetError,
  onAddCaregiverResponse,
  toggleLoading,
} = authenticationSlice.actions;
export const selectAuthentication = (state: RootState) => state.authentication;
export const authenticationReducer = authenticationSlice.reducer;
