import cn from "classnames";
import styles from "./Card.module.sass";
import { useWindowWidth } from "../../hooks/useWindowWidth";

interface IProps {
  children: React.ReactNode;
  title?: string | null;
  subtitle?: string | null;
  className?: string | null;
  classTitle?: string | null;
  classCardHead?: string | null;
  head?: React.ReactNode | null;
  isModal?: boolean | null;
  hint?: string | null;
}

const Card = ({
  className,
  title,
  subtitle,
  classTitle,
  classCardHead,
  head,
  children,
  isModal,
}: IProps) => {
  const width = useWindowWidth();
  const isMobileVersion = width < 640;

  return (
    <div className={cn(styles.card, className)}>
      <div className={cn(styles.head, classCardHead)}>
        <div className={cn(styles.section)}>
          <p className={cn(styles.title, classTitle)}>{title}</p>
          <p className={cn(styles.subtitle)}>{subtitle}</p>
        </div>
        {head && (isModal || !isMobileVersion) && head}
      </div>
      {children}
      {head && !isModal && isMobileVersion && head && (
        <div className={styles.mobileBtnContainer}>
          {head && !isModal && isMobileVersion && head}
        </div>
      )}
    </div>
  );
};

export default Card;
