import ClipLoader from "react-spinners/ClipLoader";
import styles from "./LoadingSpinner.module.sass";

interface iProps {
  color?: string
}

const LoadingSpinner = ({color}: iProps) => {
  return (
    <div className={styles.spinner}>
    <ClipLoader color={color ?? "#FFCDCC"} speedMultiplier={0.5} />
  </div>
  );
};

export default LoadingSpinner;
