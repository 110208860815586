import Icon from "../Icon";
import cn from "classnames";
import styles from "./Button.module.sass";

interface IProps {
  children?: React.ReactNode;
  icon?: string;
  disabled?: boolean;
  btnClass?: string;
  iconViewBox?: string;
  iconColor?: string;
  onClick: () => void;
}

const Button = ({
  children,
  onClick,
  disabled,
  btnClass,
  iconViewBox,
  iconColor,
  icon,
  ...props
}: IProps) => {
  return (
    <button
      {...props}
      onClick={onClick}
      disabled={disabled ?? false}
      className={cn(styles.btn, btnClass)}
    >
      {icon && <Icon viewbox={iconViewBox ?? "2 3 16 18"} size={16.5} name={icon} fill={iconColor ?? "#FFFF"} />}
      {children}
    </button>
  );
};

export default Button;
