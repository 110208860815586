import React, { useState } from "react";
import cn from "classnames";
import OutsideClickHandler from "react-outside-click-handler";
import styles from "./Dropdown.module.sass";
import Icon from "../Icon";

export interface IDropdownOptions {
  icon: string | null;
  label: string | null;
}

interface IProps {
  className?: string;
  setValue?: React.Dispatch<React.SetStateAction<boolean>>;
  options?: IDropdownOptions[];
  small?: string;
  upBody?: boolean;
  onOptionClick: (icon: string) => void;
}

const Dropdown = ({
  className,
  setValue,
  options,
  small,
  upBody,
  onOptionClick,
}: IProps) => {
  const [visible, setVisible] = useState(false);

  return (
    <OutsideClickHandler onOutsideClick={() => setVisible(false)}>
      <div
        className={cn(
          styles.dropdown,
          className,
          { [styles.small]: small },
          {
            [styles.active]: visible,
          }
        )}
      >
        <Icon
          viewbox={"0 0 24 24"}
          size={17}
          name="pencil"
          fill="#0F172A"
          onClick={() => setVisible(!visible)}
        />
        <div className={cn(styles.body, { [styles.bodyUp]: upBody })}>
          {options?.map((option, index) => (
            <div
              className={cn(styles.option)}
              onClick={() =>
                onOptionClick(
                  option["icon"] === "profile-circle"
                    ? "transferAdmin"
                    : (option["icon"] as string)
                )
              }
              key={index}
            >
              <Icon
                className={styles.optionImg}
                size={20}
                fill="none"
                name={option["icon"] as string}
              />
              {option["label"]}
            </div>
          ))}
        </div>
      </div>
    </OutsideClickHandler>
  );
};

export default Dropdown;
