import { useAppDispatch, useAppSelector } from "../../store/redux.hooks";
import { useLocation, useNavigate } from "react-router-dom";

import AppStore from "../../assets/images/app-store.svg";
import FormCard from "../../components/FormCard/FormCard";
import GooglePlay from "../../assets/images/google-play.svg";
import { nameToPossessive } from "../../utils/helper.function";
import { resetAddCgRespounse } from "../../store/slices/auth.slice";
import styles from "./LoadingAccountSetup.module.sass";
import { testflightLink } from "../../utils/constants";
import { useEffect } from "react";
import { Admin } from "../../interfaces/app.interface";
import { getAdmin } from "../../store/slices/admin.slice";
import { Subscription } from "react-redux";

const LoadingAccountSetup = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  
  const caregiverName: String | null | undefined = useAppSelector(
    (state) => state?.admin?.name
  );

  const caregiveSubscriptions: any = useAppSelector(
    (state) => state?.admin?.subscriptions
  );

  const navigateToDashboard = () => {
    navigate("/", { replace: true });  
  };

  useEffect( () => {
    if (caregiveSubscriptions && caregiveSubscriptions.length > 0) {
      navigateToDashboard();
    }
  }, [caregiveSubscriptions]);

  // useEffect (() => {
  //   let pollingInterval;
  //   const startSubscriptionPolling = async () => {    
  //     await dispatch(getAdmin());
  //     if (!caregiver || caregiver.subscriptions || caregiver.subscriptions!.length > 0) {
  //       pollingInterval = setInterval(async () => {
  //         await dispatch(getAdmin()); // Poll every 2 seconds 
  //       }, 2000);
  //     }
  //   };
  //   startSubscriptionPolling();
  //   return () => clearInterval(pollingInterval);
  // }, []); // Start polling when component mounts


  // Function to initiate polling
  const startPolling = () => {
    dispatch(getAdmin()); // Dispatch action to fetch admin data immediately
    const pollingInterval = setInterval(() => {
      dispatch(getAdmin()); // Dispatch action to fetch admin data every 5 seconds (adjust as needed)
    }, 5000);
    return () => {
      clearInterval(pollingInterval); // Cleanup function to stop polling when component unmounts
    }      
  };

  useEffect(startPolling, []); // Start polling when component mounts

  return (
    <FormCard
      title={ "Carl account" }
      primaryButtonLabel={"We are configuring your Carl account"}
      closeButtonVisible={false}
      onClickCloseButton={navigateToDashboard}
    >
      <div className={styles.container}>
        <p className={styles.title}>
          <span>
            {`You’re almost done ${caregiverName}`}
            <br />
            <br />
          </span>          
        </p>
      </div>     
    </FormCard>
  );
};

export default LoadingAccountSetup;
