import { useEffect, useState, useRef } from "react";
import { IKUpload } from "imagekitio-react";
import { ImageKit } from "../Image";
import cn from "classnames";

import Image from "../../assets/images/image.svg";
import {
  imageKitAcceptedTypes,
  imageKitAcceptedTypesArray,
} from "../../utils/constants";

import styles from "./ImagePicker.module.sass";
import LoadingSpinner from "../LoadingSpinner/LoadingSpinner.component";

const ImagePicker = ({ setImage, img, id, setIsUploading }) => {
  const [fileHover, setFileHover] = useState(false);
  const [loading, setLoading] = useState(false);
  const [imageWidth, setImageWidth] = useState();

  const handleUploadComplete = (uploadedFiles) => {
    setLoading(false);
    setIsUploading && setIsUploading(false);
    uploadedFiles && setImage(uploadedFiles.filePath);
  };

  const handleUploadStart = () => {
    setLoading(true);
    setIsUploading(true);
  };

  const container = useRef(null);

  useEffect(() => {
    if (container && container.current) {
      container.current.addEventListener("dragenter", setFileHover(true), true);
      container.current.addEventListener(
        "dragleave",
        setFileHover(false),
        true
      );
    }

    return () => {
      if (container && container.current) {
        container.current.removeEventListener(
          "dragenter",
          setFileHover(false),
          false
        );
        container.current.removeEventListener(
          "dragleave",
          setFileHover(false),
          false
        );
      }
    };
  }, [fileHover, setFileHover]);

  useEffect(() => {
    var width = document.getElementById("imageContainer").offsetWidth;
    setImageWidth(width);
  }, []);

  return (
    <div id="imageContainer" ref={container} className={styles.file}>
      {loading ? (
        <LoadingSpinner />
      ) : img ? (
        <div>
          {imageWidth && (
            <>
              <ImageKit path={img} width={imageWidth} height={imageWidth} />
              <svg
                className={styles.imageMask}
                width={imageWidth}
                height={imageWidth}
              >
                <defs>
                  <mask
                    id="mask"
                    x="0"
                    y="0"
                    width={imageWidth}
                    height={imageWidth}
                  >
                    <rect
                      x="0"
                      y="0"
                      width={imageWidth}
                      height={imageWidth}
                      fill="#fff"
                    />
                    <circle
                      cx={imageWidth / 2}
                      cy={imageWidth / 2}
                      r={imageWidth / 2}
                    />
                  </mask>
                </defs>
                <rect
                  x="0"
                  y="0"
                  width={imageWidth}
                  height={imageWidth}
                  mask="url(#mask)"
                  fill-opacity="0.4"
                />
              </svg>
            </>
          )}
        </div>
      ) : (
        <div
          className={cn(styles.container, {
            [styles.withImg]: img != null,
          })}
        >
          <img
            className={styles.img}
            src={Image}
            alt="Profile picture placeholder"
          />
          <p className={styles.description}>
            Drag a photo here or{" "}
            <strong className={styles.uploadFile}>upload a file</strong>
          </p>
        </div>
      )}
      <IKUpload
        id="imagePickerRef"
        onSuccess={handleUploadComplete}
        accept={imageKitAcceptedTypes}
        validateFile={(file) => imageKitAcceptedTypesArray.includes(file.type)}
        draggable={false}
        onUploadStart={handleUploadStart}
        fileName="profile"
        isPrivateFile={false}
        folder={`/${process.env.REACT_APP_ENVIRONMENT}/${id}/`}
      />
    </div>
  );
};

export default ImagePicker;
