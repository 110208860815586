import { Dispatch, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "../index.store";

interface IBilling {
  careReceiverId: string | null;
  isOnboarding?: boolean | null;
}

const initialState: IBilling = {
  careReceiverId: null,
  isOnboarding: null,
};

export const BillingSlice = createSlice({
  name: "billing",
  initialState,
  reducers: {
    setValue: (state, action: PayloadAction<IBilling>) => {
      return {
        ...state,
        careReceiverId: action.payload.careReceiverId,
        isOnboarding: action.payload.isOnboarding
      };
    },
    setBillingToInitial: (state) => {
      return {
        ...initialState,
      };
    }
  },
});

export const saveBillingData =
  ({careReceiverId, isOnboarding}: IBilling) =>
  (dispatch: Dispatch) => {
    dispatch(setValue({careReceiverId, isOnboarding}));
  };

export const resetBilling = ()  => (dispatch: Dispatch) => {
  dispatch(setBillingToInitial());
}

export const { setValue, setBillingToInitial } = BillingSlice.actions;
export const selectBilling = (state: RootState) => state.createAccount;
export const BillingReducer = BillingSlice.reducer;
