import cn from "classnames";

const icons = {
  sun: 'M8.002 12a4 4 0 1 0 0-8 4 4 0 1 0 0 8zM7.999 1c.368 0 .667.298.667.667v.667a.67.67 0 0 1-.667.667c-.368 0-.667-.298-.667-.667v-.667c0-.368.298-.667.667-.667zm0 12c.368 0 .667.298.667.667v.665a.67.67 0 0 1-.667.667c-.368 0-.667-.298-.667-.667v-.665c0-.368.298-.667.667-.667zM1 7.999c0-.368.298-.667.667-.667h.667c.368 0 .667.298.667.667s-.298.667-.667.667h-.667c-.368 0-.667-.298-.667-.667zm12 0c0-.368.298-.667.667-.667h.664c.368 0 .667.298.667.667s-.298.667-.667.667h-.664c-.368 0-.667-.298-.667-.667zm-9.95 4.949a.67.67 0 0 1 0-.943l.471-.471a.67.67 0 0 1 .943 0c.261.26.26.682 0 .943l-.471.471a.67.67 0 0 1-.943 0zm8.485-8.485a.67.67 0 0 1 0-.943l.47-.47a.67.67 0 0 1 .943 0c.261.26.26.682 0 .943l-.47.47a.67.67 0 0 1-.943 0zM3.05 3.05a.67.67 0 0 1 .943 0l.471.471a.67.67 0 0 1 0 .943c-.26.261-.682.26-.943 0l-.471-.471a.67.67 0 0 1 0-.943zm8.485 8.485a.67.67 0 0 1 .943 0l.47.47a.67.67 0 0 1 0 .943c-.26.261-.682.26-.943 0l-.47-.47a.67.67 0 0 1 0-.943z',
  moon: 'M5.377 4.017c-1.235.841-2.044 2.257-2.044 3.861A4.67 4.67 0 0 0 8 12.544c1.603 0 3.02-.809 3.861-2.044a7.34 7.34 0 0 1-6.484-6.484zm7.29 5.194c.577 0 1.075.509.846 1.039-.92 2.134-3.042 3.628-5.513 3.628a6 6 0 0 1-6-6c0-2.471 1.494-4.593 3.628-5.513.53-.228 1.039.269 1.039.846a6 6 0 0 0 6 6z',
  'arrow-down':
    'M4.471 5.528c-.26-.26-.682-.26-.943 0s-.26.682 0 .943l.943-.943zM8 10l-.471.471c.26.26.682.26.943 0L8 10zm4.471-3.529c.26-.26.26-.682 0-.943s-.682-.26-.943 0l.943.943zm-8.943 0l4 4 .943-.943-4-4-.943.943zm4.943 4l4-4-.943-.943-4 4 .943.943z',
  'arrow-next':
    'M5.528 11.528c-.26.26-.26.682 0 .943s.682.26.943 0l-.943-.943zM10 8l.471.471c.26-.26.26-.682 0-.943L10 8zM6.471 3.528c-.26-.26-.682-.26-.943 0s-.26.682 0 .943l.943-.943zm0 8.943l4-4-.943-.943-4 4 .943.943zm4-4.943l-4-4-.943.943 4 4 .943-.943z',
  'arrow-left':
    'M7.138 11.862c.26.26.26.682 0 .943s-.682.26-.943 0L2.334 8.943c-.521-.521-.521-1.365 0-1.886l3.862-3.862c.26-.26.682-.26.943 0s.26.682 0 .943L3.943 7.333h9.39c.368 0 .667.298.667.667s-.298.667-.667.667h-9.39l3.195 3.195z',
  'arrow-right':
    'M8.862 11.862c-.26.26-.26.682 0 .943s.682.26.943 0l3.862-3.862c.521-.521.521-1.365 0-1.886L9.805 3.195c-.26-.26-.682-.26-.943 0s-.26.682 0 .943l3.195 3.195H2.667C2.298 7.333 2 7.632 2 8s.298.667.667.667h9.391l-3.195 3.195z',
  'arrow-bottom':
    'M8 1a1 1 0 0 1 .993.883L9 2v9.585l3.293-3.292.094-.081a1 1 0 0 1 1.32 1.495h0v-.001l-4.991 4.993c-.029.03-.061.059-.094.085l-.04.03-.062.041-.027.016-.078.04-.033.014-.067.025-.051.015-.06.015-.053.009-.063.008-.053.003L8 15l-.033-.001-.052-.003L8 15a1.01 1.01 0 0 1-.148-.011l-.054-.01-.058-.013-.052-.016-.069-.025-.033-.015-.07-.035-.039-.023-.063-.042a1.02 1.02 0 0 1-.12-.103l.09.08c-.035-.027-.068-.057-.099-.089L2.293 9.707l-.081-.088a1 1 0 0 1 .522-1.599 1 1 0 0 1 .973.273h0L7 11.585V2a1 1 0 0 1 1-1z',
  'arrow-top':
    'M8 1h.019l.07.004L8 1a1.01 1.01 0 0 1 .149.011l.053.01.061.014.05.015.069.025.032.014.079.04.027.016.063.041c.043.031.085.067.125.106l-.085-.076.085.076 5 5a1 1 0 0 1-1.414 1.414h0 0L9 4.413V14a1 1 0 0 1-1.993.117L7 14V4.413L3.707 7.707l-.088.081a1 1 0 0 1-1.599-.522 1 1 0 0 1 .273-.973h0l5-5 .082-.073.039-.03.062-.042.04-.024.069-.035.034-.015.067-.025.054-.016.056-.014.056-.01.059-.007.07-.004z',
  'arrows-up-down':
    'M11.5 2.666c.368 0 .667.298.667.667h0v8.057l1.529-1.529c.26-.26.682-.26.943 0s.26.682 0 .943h0L12.443 13c-.521.521-1.365.521-1.886 0h0l-2.195-2.195c-.26-.26-.26-.682 0-.943s.682-.26.943 0h0l1.529 1.529V3.333c0-.368.298-.667.667-.667zM5.776 3l2.195 2.195c.26.26.26.682 0 .943s-.682.26-.943 0L5.5 4.609v8.057c0 .368-.298.667-.667.667s-.667-.298-.667-.667V4.609L2.638 6.138c-.26.26-.682.26-.943 0s-.26-.682 0-.943L3.891 3c.521-.521 1.365-.521 1.886 0z',
  store:
    'M11.631 1.333a2 2 0 0 1 1.864 1.275h0l1.192 3.065a1.57 1.57 0 0 1-.688 1.935h0v5.059a2 2 0 0 1-2 2h0-8a2 2 0 0 1-2-2h0V7.608a1.57 1.57 0 0 1-.688-1.935h0l1.192-3.065a2 2 0 0 1 1.864-1.275h0zm-6.965 6h-.001l-.467.234c-.274.137-.567.223-.866.258v4.841c0 .368.298.667.667.667h1.333v-2a2 2 0 0 1 2-2h1.333a2 2 0 0 1 2 2v2h1.333c.368 0 .667-.299.667-.667V7.825c-.299-.035-.592-.122-.866-.258l-.468-.234-.428.171c-.795.318-1.681.318-2.476 0l-.429-.171-.429.171c-.795.318-1.681.318-2.476 0l-.428-.171zm4 3.333H7.333c-.368 0-.667.298-.667.667h0v2h2.667v-2c0-.368-.298-.667-.667-.667h0zm2.965-8h-.474l.741 3.459.03.015h0l.468.234c.274.137.588.171.885.097.138-.035.215-.182.163-.315h0l-1.192-3.065c-.1-.256-.346-.425-.621-.425h0zm-6.79 0h-.474c-.275 0-.522.169-.621.425L2.554 6.157c-.052.133.025.28.163.315.297.074.611.04.885-.097l.468-.234.03-.015.741-3.459zm4.952 0H8.666v3.497l.257.103a2 2 0 0 0 1.486 0l.144-.058-.759-3.543zm-2.461 0H6.205l-.759 3.543.144.058a2 2 0 0 0 1.486 0l.257-.103V2.666z',
  'profile-circle':
    'M15.9815 16.7346C14.6121 14.9272 12.4424 13.7598 10 13.7598C7.55761 13.7598 5.38789 14.9272 4.01846 16.7346M15.9815 16.7346C17.8335 15.0861 19 12.6842 19 10.0098C19 5.0392 14.9706 1.00977 10 1.00977C5.02944 1.00977 1 5.0392 1 10.0098C1 12.6842 2.1665 15.0861 4.01846 16.7346M15.9815 16.7346C14.3915 18.1499 12.2962 19.0098 10 19.0098C7.70383 19.0098 5.60851 18.1499 4.01846 16.7346M13 7.75977C13 9.41662 11.6569 10.7598 10 10.7598C8.34315 10.7598 7 9.41662 7 7.75977C7 6.10291 8.34315 4.75977 10 4.75977C11.6569 4.75977 13 6.10291 13 7.75977Z',
  lightning:
    'M10.44 3.711l-.78 2.194h2.443c1.743 0 2.652 2.073 1.471 3.355l-4.657 5.054c-1.475 1.601-4.085.026-3.355-2.025l.78-2.194H3.899c-1.743 0-2.652-2.074-1.471-3.355l4.657-5.054C8.56.084 11.169 1.66 10.44 3.711zM8.065 2.589L3.409 7.644a.67.67 0 0 0 .49 1.118h3.387c.217 0 .42.105.545.282s.156.404.083.608l-1.097 3.084a.67.67 0 0 0 1.118.675l4.656-5.054a.67.67 0 0 0-.49-1.118H8.715c-.217 0-.42-.105-.545-.282s-.156-.404-.083-.608l1.097-3.084a.67.67 0 0 0-1.118-.675z',
  'plus-circle':
    'M8 1.333A6.67 6.67 0 0 1 14.666 8 6.67 6.67 0 0 1 8 14.666 6.67 6.67 0 0 1 1.333 8 6.67 6.67 0 0 1 8 1.333zm0 1.333C5.054 2.666 2.666 5.054 2.666 8S5.054 13.333 8 13.333 13.333 10.945 13.333 8 10.945 2.666 8 2.666zm0 2c.368 0 .667.298.667.667h0v2h2c.368 0 .667.298.667.667s-.298.667-.667.667h0-2v2c0 .368-.298.667-.667.667s-.667-.298-.667-.667h0v-2h-2c-.368 0-.667-.298-.667-.667s.298-.667.667-.667h0 2v-2c0-.368.298-.667.667-.667z',
  'pie-chart':
    'M6.666 4c.368 0 .667.298.667.667v4h4c.368 0 .667.298.667.667 0 1.055-.313 2.086-.899 2.963s-1.419 1.561-2.394 1.964-2.047.509-3.081.304-1.985-.714-2.731-1.46-1.254-1.696-1.46-2.731-.1-2.107.304-3.081 1.087-1.807 1.964-2.394S5.611 4 6.666 4zM6 5.389a4 4 0 0 0-3.029 2.413 4 4 0 0 0 .867 4.359 4 4 0 0 0 4.359.867A4 4 0 0 0 10.61 10H6.666c-.177 0-.346-.07-.471-.195S6 9.51 6 9.333V5.389zm3.333-4.056c1.414 0 2.771.562 3.771 1.562s1.562 2.357 1.562 3.771c0 .368-.298.667-.667.667H9.333c-.177 0-.346-.07-.471-.195s-.195-.295-.195-.471V2c0-.177.07-.346.195-.471s.295-.195.471-.195zM10 2.722V6h3.277A4 4 0 0 0 10 2.722z',
  home: 'M9.338 1.87l4.667 4.2a2 2 0 0 1 .662 1.487v5.109a2 2 0 0 1-2 2H3.333a2 2 0 0 1-2-2V7.557a2 2 0 0 1 .662-1.487l4.667-4.2a2 2 0 0 1 2.676 0zm-.892.991c-.254-.228-.638-.228-.892 0h0l-4.667 4.2c-.14.126-.221.307-.221.496h0v5.109c0 .368.298.667.667.667h0 2V10a2 2 0 0 1 2-2h0 1.333a2 2 0 0 1 2 2h0v3.333h2c.368 0 .667-.298.667-.667h0V7.557c0-.189-.08-.369-.221-.496h0zm.221 6.471H7.333c-.368 0-.667.299-.667.667h0v3.333h2.667V10c0-.368-.298-.667-.667-.667h0z',
  diamond:
    'M11.213 1.333a2 2 0 0 1 1.609.812h0l1.951 2.644a2 2 0 0 1-.022 2.405h0l-5.164 6.736a2 2 0 0 1-3.174 0h0L1.249 7.194a2 2 0 0 1-.022-2.405h0l1.951-2.644a2 2 0 0 1 1.609-.812h0zm2.263 5.333H2.525l4.946 6.452c.267.348.791.348 1.058 0l4.946-6.452zm-2.263-4H4.787c-.212 0-.411.1-.536.271h0L2.483 5.333h11.035l-1.768-2.396c-.126-.17-.325-.271-.536-.271h0z',
  promotion:
    'M9.297 1.563l.848.722c.107.091.24.146.379.157l1.11.089a2 2 0 0 1 1.835 1.835l.089 1.11c.011.14.066.273.157.379l.722.848a2 2 0 0 1 0 2.594l-.722.848c-.091.107-.146.24-.157.379l-.089 1.11a2 2 0 0 1-1.835 1.835l-1.11.089c-.14.011-.273.066-.379.157l-.848.722a2 2 0 0 1-2.594 0l-.848-.722c-.107-.091-.24-.146-.379-.157l-1.11-.089a2 2 0 0 1-1.835-1.835l-.089-1.11c-.011-.14-.066-.273-.157-.379l-.722-.848a2 2 0 0 1 0-2.594l.722-.848c.091-.107.146-.24.157-.379l.089-1.11a2 2 0 0 1 1.835-1.835l1.11-.089c.14-.011.273-.066.379-.157l.848-.722a2 2 0 0 1 2.594 0zm-1.73 1.015L6.72 3.3a2 2 0 0 1-1.138.471l-1.11.089c-.326.026-.585.285-.612.612l-.089 1.11A2 2 0 0 1 3.3 6.72l-.722.848c-.212.249-.212.616 0 .865l.722.848a2 2 0 0 1 .471 1.138l.089 1.11c.026.326.285.586.612.612l1.11.089a2 2 0 0 1 1.138.471l.848.722c.249.212.616.212.865 0l.848-.722a2 2 0 0 1 1.138-.471l1.11-.089c.326-.026.585-.285.611-.612l.089-1.11A2 2 0 0 1 12.7 9.28l.722-.848c.212-.249.212-.616 0-.865L12.7 6.72a2 2 0 0 1-.471-1.138l-.089-1.11c-.026-.326-.285-.585-.611-.612l-1.11-.089A2 2 0 0 1 9.28 3.3l-.848-.722c-.249-.212-.616-.212-.865 0zm2.1 6.089a1 1 0 1 1 0 2 1 1 0 1 1 0-2zm.427-3.179c.283.236.321.656.085.939l-3.333 4c-.236.283-.656.321-.939.085s-.321-.656-.085-.939l3.333-4c.236-.283.656-.321.939-.085zm-3.76-.155a1 1 0 1 1 0 2 1 1 0 1 1 0-2z',
  help: 'M8 1.333A6.67 6.67 0 0 1 14.666 8 6.67 6.67 0 0 1 8 14.666 6.67 6.67 0 0 1 1.333 8 6.67 6.67 0 0 1 8 1.333zm0 1.333C5.054 2.666 2.666 5.054 2.666 8S5.054 13.333 8 13.333 13.333 10.945 13.333 8 10.945 2.666 8 2.666zm0 8.667c.368 0 .667.298.667.667s-.298.667-.667.667-.667-.298-.667-.667.298-.667.667-.667zM8 4c1.473 0 2.667 1.194 2.667 2.667a2.67 2.67 0 0 1-2 2.583V10c0 .368-.299.667-.667.667s-.667-.298-.667-.667V8.666C7.333 8.298 7.632 8 8 8c.736 0 1.333-.597 1.333-1.333S8.736 5.333 8 5.333c-.58 0-1.074.37-1.258.889-.123.347-.504.529-.851.406s-.529-.504-.406-.851C5.851 4.743 6.838 4 8 4z',
  search:
    'M7.333 1.333a6 6 0 0 1 6 6c0 1.417-.491 2.719-1.312 3.745h0l1.783 1.783c.26.26.26.682 0 .943s-.682.26-.943 0h0l-1.783-1.783c-1.026.821-2.328 1.312-3.745 1.312a6 6 0 1 1 0-12zm0 1.333a4.67 4.67 0 0 0-4.667 4.667A4.67 4.67 0 0 0 7.333 12 4.67 4.67 0 0 0 12 7.333a4.67 4.67 0 0 0-4.667-4.667z',
  'close-circle':
    'M8 14.666A6.67 6.67 0 0 0 14.666 8 6.67 6.67 0 0 0 8 1.333 6.67 6.67 0 0 0 1.333 8 6.67 6.67 0 0 0 8 14.666zm-2.471-4.195c-.26-.26-.26-.682 0-.943L7.057 8 5.528 6.471c-.26-.26-.26-.682 0-.943s.682-.26.943 0L8 7.057l1.529-1.529c.26-.26.682-.26.943 0s.26.682 0 .943L8.942 8l1.529 1.529c.26.26.26.682 0 .943s-.682.26-.943 0L8 8.942l-1.529 1.529c-.26.26-.682.26-.943 0z',
  close:
    'M4.471 3.528c-.26-.26-.682-.26-.943 0s-.26.682 0 .943L7.057 8l-3.529 3.529c-.26.26-.26.682 0 .943s.682.26.943 0L8 8.942l3.529 3.529c.26.26.682.26.943 0s.26-.682 0-.943L8.942 8l3.529-3.529c.26-.26.26-.682 0-.943s-.682-.26-.943 0L8 7.057 4.471 3.528z',
  photos:
    'M12.666 1.333a2 2 0 0 1 2 2V10a2 2 0 0 1-2 2H12v.667a2 2 0 0 1-2 2h0-6.667a2 2 0 0 1-2-2h0V6a2 2 0 0 1 2-2h0H4v-.667a2 2 0 0 1 2-2h6.667zM4 5.333h-.667c-.368 0-.667.298-.667.667h0v6.667c0 .368.298.667.667.667h0H10c.368 0 .667-.298.667-.667h0V12H6a2 2 0 0 1-2-2V5.333zm2.893 1.278l-1.56 1.871V10c0 .368.298.667.667.667h5.636L7.896 6.587c-.273-.298-.745-.286-1.004.024zm5.774-3.945H6c-.335 0-.612.247-.659.568l-.007.099V6.4l.535-.642a2 2 0 0 1 2.896-.187l.115.116 4.251 4.638.066.08c.064-.084.109-.184.127-.293l.009-.111V3.333c0-.368-.298-.667-.667-.667zM11 4a1 1 0 1 1 0 2 1 1 0 1 1 0-2z',
  add: 'M8.667 3.334c0-.368-.298-.667-.667-.667s-.667.298-.667.667v4h-4c-.368 0-.667.298-.667.667s.298.667.667.667h4v4c0 .368.298.667.667.667s.667-.298.667-.667v-4h4c.368 0 .667-.298.667-.667s-.298-.667-.667-.667h-4v-4z',
  check:
    'M13.138 4.202c.26.26.26.682 0 .943l-5.724 5.724a2 2 0 0 1-2.828 0L2.195 8.478c-.26-.26-.26-.682 0-.943s.682-.26.943 0l2.391 2.391c.26.26.682.26.943 0l5.724-5.724c.26-.26.682-.26.943 0z',
  'check-circle':
    'M8 14.666A6.67 6.67 0 0 0 14.666 8a6.64 6.64 0 0 0-.745-3.066c-.1-.192-.356-.224-.509-.07L8.276 10c-.521.521-1.365.521-1.886 0L4.528 8.138c-.26-.26-.26-.682 0-.943s.682-.26.943 0l1.862 1.862 5.348-5.344c.126-.126.131-.331.004-.456C11.481 2.067 9.826 1.333 8 1.333A6.67 6.67 0 0 0 1.333 8 6.67 6.67 0 0 0 8 14.666z',
  'check-all':
    'M1.528 8.195c.26-.26.682-.26.943 0h0l1.724 1.724.009.009 1.325 1.325a2 2 0 0 1-2.277-.391h0L1.528 9.138c-.26-.26-.26-.682 0-.943zm12.943-3.367c.26.26.26.682 0 .943l-5.091 5.091a2 2 0 0 1-2.828 0L4.828 9.138c-.26-.26-.26-.682 0-.943s.682-.26.943 0l1.724 1.724c.26.26.682.26.943 0l5.091-5.091c.26-.26.682-.26.943 0zm-4.276.033c.26-.26.682-.26.943 0s.26.682 0 .943h0L7.966 8.976l-.943-.943z',
  trash:
    'M6.666 6.673c.368 0 .667.298.667.667v3.333c0 .368-.298.667-.667.667S6 11.042 6 10.673V7.34c0-.368.298-.667.667-.667zm2.667 0c.368 0 .667.298.667.667v3.333c0 .368-.298.667-.667.667s-.667-.298-.667-.667V7.34c0-.368.298-.667.667-.667zm0-5.333a2 2 0 0 1 2 2h0H14c.368 0 .667.298.667.667s-.298.667-.667.667h0-.667v8a2 2 0 0 1-2 2h0-6.667a2 2 0 0 1-2-2h0v-8H2c-.368 0-.667-.298-.667-.667S1.631 3.34 2 3.34h0 2.667a2 2 0 0 1 2-2h0zM12 4.673H4v8c0 .368.298.667.667.667h6.667c.368 0 .667-.298.667-.667v-8zm-2.667-2H6.666c-.368 0-.667.298-.667.667h4c0-.368-.298-.667-.667-.667z',
  message:
    'M4.666 6c0-.368.298-.667.667-.667h5.333c.368 0 .667.298.667.667s-.298.667-.667.667H5.333c-.368 0-.667-.298-.667-.667zm0 2.667c0-.368.298-.667.667-.667H8c.368 0 .667.298.667.667s-.298.667-.667.667H5.333c-.368 0-.667-.298-.667-.667zm8-6.667a2 2 0 0 1 2 2h0v6.667a2 2 0 0 1-2 2h0-7.333L3.52 14.178c-.868.724-2.187.106-2.187-1.024h0V4a2 2 0 0 1 2-2h0zm0 1.333H3.333c-.368 0-.667.298-.667.667v9.153l1.813-1.511c.24-.2.542-.309.854-.309h7.333c.368 0 .667-.298.667-.667V4c0-.368-.298-.667-.667-.667z',
  messages:
    'M12.593 1.526c.886-.591 2.073.044 2.073 1.109h0v6.175a2 2 0 0 1-2 2h0H12v.667a2 2 0 0 1-1.851 1.995l-.149.005H4.666l-1.167.934c-.839.672-2.067.123-2.161-.914l-.006-.127V7.478a2 2 0 0 1 1.851-1.995l.149-.005H4v-.667a2 2 0 0 1 2-2h0 4.667zM10 6.811H3.333c-.368 0-.667.298-.667.667v5.893l1.167-.934c.236-.189.53-.292.833-.292H10c.368 0 .667-.298.667-.667v-4c0-.368-.298-.667-.667-.667zm-1.333 2c.368 0 .667.298.667.667s-.298.667-.667.667h-4c-.368 0-.667-.298-.667-.667s.298-.667.667-.667h4zm4.667-6.175L11.406 3.92c-.219.146-.476.224-.74.224h0H6c-.368 0-.667.298-.667.667h0v.667H10a2 2 0 0 1 1.995 1.851l.005.149v2h.667c.368 0 .667-.298.667-.667h0V2.636z',
  'more-horizontal':
    'M5.334 8.006c0 .736-.597 1.333-1.333 1.333s-1.333-.597-1.333-1.333S3.264 6.673 4 6.673s1.333.597 1.333 1.333zm4 0c0 .736-.597 1.333-1.333 1.333s-1.333-.597-1.333-1.333S7.264 6.673 8 6.673s1.333.597 1.333 1.333zm4 0c0 .736-.597 1.333-1.333 1.333s-1.333-.597-1.333-1.333.597-1.333 1.333-1.333 1.333.597 1.333 1.333z',
  notification:
    'M8.019 1.333c2.935 0 5.314 2.379 5.314 5.314v2.134c0 .354.14.693.391.943l.667.667c.177.177.276.417.276.667 0 .521-.422.943-.943.943h-3.057c0 1.473-1.194 2.667-2.667 2.667S5.333 13.472 5.333 12H2.269c-.517 0-.936-.419-.936-.936 0-.254.103-.497.286-.673l.686-.663c.261-.252.408-.6.407-.963l-.006-2.102c-.009-2.941 2.373-5.33 5.314-5.33zM9.333 12H6.666c0 .736.597 1.333 1.333 1.333S9.333 12.736 9.333 12zM8.019 2.666a3.98 3.98 0 0 0-3.98 3.992h0l.006 2.102c.002.716-.284 1.403-.793 1.906h0 9.529c-.5-.5-.781-1.178-.781-1.886h0V6.647a3.98 3.98 0 0 0-3.98-3.98z',
  'bar-chart':
    'M2 1.34c.368 0 .667.298.667.667v10.667c0 .368.298.667.667.667H14c.368 0 .667.298.667.667s-.298.667-.667.667H3.333a2 2 0 0 1-2-2V2.007c0-.368.298-.667.667-.667zm2.666 4c.368 0 .667.298.667.667v5.333c0 .368-.298.667-.667.667S4 11.708 4 11.34V6.007c0-.368.298-.667.667-.667zm2.667 4c.368 0 .667.298.667.667v1.333c0 .368-.298.667-.667.667s-.667-.298-.667-.667v-1.333c0-.368.298-.667.667-.667zM10 2.673c.368 0 .667.298.667.667v8c0 .368-.298.667-.667.667s-.667-.298-.667-.667v-8c0-.368.298-.667.667-.667zm2.666 4c.368 0 .667.298.667.667v4c0 .368-.298.667-.667.667S12 11.708 12 11.34v-4c0-.368.298-.667.667-.667z',
  grid: 'M12.666 1.34a2 2 0 0 1 2 2h0v9.333a2 2 0 0 1-2 2h0-9.333a2 2 0 0 1-2-2h0V3.34a2 2 0 0 1 2-2h0zm-3.333 9.333H6.666v2.667h2.667v-2.667zm4 0h-2.667v2.667h2c.368 0 .667-.299.667-.667h0v-2zm-8 0H2.666v2c0 .368.298.667.667.667h0 2v-2.667zm4-4H6.666V9.34h2.667V6.673zm4 0h-2.667V9.34h2.667V6.673zm-8 0H2.666V9.34h2.667V6.673zm4-4H6.666V5.34h2.667V2.673zm3.333 0h-2V5.34h2.667v-2c0-.368-.298-.667-.667-.667zm-7.333 0h-2c-.368 0-.667.298-.667.667h0v2h2.667V2.673z',
  leaderboard:
    'M6 4.673c0-.736.597-1.333 1.333-1.333h1.333c.736 0 1.333.597 1.333 1.333v7.333c0 .368-.298.667-.667.667H6.666a.67.67 0 0 1-.667-.667V4.673zm5.333 4c0-.368.298-.667.667-.667h1.333c.736 0 1.333.597 1.333 1.333v2.667c0 .368-.298.667-.667.667h-2c-.368 0-.667-.298-.667-.667V8.673zm-10-.666c0-.736.597-1.333 1.333-1.333H4c.368 0 .667.298.667.667v4.667c0 .368-.298.667-.667.667H2c-.368 0-.667-.298-.667-.667v-4z',
  ticket:
    'M12.666 2.673a2 2 0 0 1 2 2h0v1.333c0 .368-.316.653-.639.83a1.33 1.33 0 0 0 0 2.34c.323.177.639.461.639.83h0v1.333a2 2 0 0 1-2 2h0-9.333a2 2 0 0 1-2-2h0v-1.333c0-.368.316-.653.639-.83a1.33 1.33 0 0 0 0-2.34c-.323-.177-.639-.461-.639-.83h0V4.673a2 2 0 0 1 2-2h0zm0 1.333H3.333c-.368 0-.667.298-.667.667v1.024C3.461 6.156 4 7.017 4 8.006a2.67 2.67 0 0 1-1.333 2.31v1.024c0 .368.298.667.667.667h9.333c.368 0 .667-.298.667-.667v-1.024C12.539 9.857 12 8.995 12 8.006a2.67 2.67 0 0 1 1.333-2.31V4.673c0-.368-.298-.667-.667-.667zM7.371 5.818a.67.67 0 0 1 1.259 0h0l.158.456a.67.67 0 0 0 .616.448h0l.482.01a.67.67 0 0 1 .389 1.198h0l-.385.292c-.222.168-.316.457-.235.724h0l.14.462a.67.67 0 0 1-1.019.74h0l-.396-.276a.67.67 0 0 0-.762 0h0l-.396.276a.67.67 0 0 1-1.019-.74h0l.14-.462c.081-.267-.013-.556-.235-.724h0l-.385-.292a.67.67 0 0 1 .389-1.198h0l.482-.01c.279-.006.525-.184.616-.448h0zM8 7.466a2 2 0 0 1-.514.374c.097.19.164.394.196.605a2 2 0 0 1 .636 0c.033-.211.099-.415.196-.605-.19-.096-.364-.223-.514-.374z',
  'shopping-bag':
    'M8 1.333c1.473 0 2.667 1.194 2.667 2.667h0 .901a2 2 0 0 1 1.985 1.752h0l.833 6.667a2 2 0 0 1-1.985 2.248h0-8.802a2 2 0 0 1-1.985-2.248h0l.833-6.667A2 2 0 0 1 4.433 4h0 .901c0-1.473 1.194-2.667 2.667-2.667zm-2.667 4h-.901c-.336 0-.62.25-.662.584h0l-.833 6.667c-.05.398.261.749.662.749h0 8.802c.401 0 .711-.351.662-.749h0l-.833-6.667c-.042-.334-.325-.584-.662-.584h0-.901V6c0 .368-.298.667-.667.667S9.334 6.368 9.334 6h0v-.667H6.667V6c0 .368-.298.667-.667.667S5.334 6.368 5.334 6h0v-.667zM8 2.666c-.736 0-1.333.597-1.333 1.333h2.667c0-.736-.597-1.333-1.333-1.333z',
  info: 'M8.001 1.334a6.67 6.67 0 0 1 6.667 6.667 6.67 6.67 0 0 1-6.667 6.667 6.67 6.67 0 0 1-6.667-6.667 6.67 6.67 0 0 1 6.667-6.667zm0 6c-.368 0-.667.298-.667.667h0v3.334c0 .368.298.667.667.667s.667-.298.667-.667h0V8.001c0-.368-.298-.667-.667-.667zm0-2.667c-.368 0-.667.298-.667.667s.298.667.667.667.667-.298.667-.667-.298-.667-.667-.667z',
  'info-stroke':
    'M8.003 1.334a6.67 6.67 0 0 1 6.667 6.667 6.67 6.67 0 0 1-6.667 6.667 6.67 6.67 0 0 1-6.667-6.667 6.67 6.67 0 0 1 6.667-6.667zm0 1.333c-2.945 0-5.333 2.388-5.333 5.333s2.388 5.333 5.333 5.333 5.333-2.388 5.333-5.333-2.388-5.333-5.333-5.333zm0 3.999c.368 0 .667.298.667.667v3.334c0 .368-.298.667-.667.667s-.667-.298-.667-.667V7.333c0-.368.298-.667.667-.667zm0-2c.368 0 .667.298.667.667S8.371 6 8.003 6s-.667-.298-.667-.667.298-.667.667-.667z',
  activity:
    'M6.326 3.369c-.075-.351-.577-.351-.652 0l-.796 3.716a2 2 0 0 1-1.956 1.581H2c-.368 0-.667-.298-.667-.667s.298-.667.667-.667h.922c.314 0 .586-.22.652-.527l.796-3.716c.376-1.757 2.883-1.757 3.259 0l2.044 9.54c.075.351.577.351.652 0l.796-3.716a2 2 0 0 1 1.956-1.581H14c.368 0 .667.298.667.667s-.298.667-.667.667h-.922c-.314 0-.586.22-.652.527l-.796 3.716c-.376 1.757-2.883 1.757-3.259 0L6.326 3.369z',
  link: 'M1.333 8c0-1.841 1.492-3.333 3.333-3.333h2c.368 0 .667.298.667.667S7.035 6 6.666 6h-2a2 2 0 1 0 0 4h2c.368 0 .667.298.667.667s-.298.667-.667.667h-2c-1.841 0-3.333-1.492-3.333-3.333zm7.334-2.666c0-.368.298-.667.667-.667h2c1.841 0 3.333 1.492 3.333 3.333s-1.492 3.333-3.333 3.333h-2c-.368 0-.667-.298-.667-.667S8.966 10 9.334 10h2a2 2 0 1 0 0-4h-2c-.368 0-.667-.298-.667-.667zM5.333 8c0-.368.298-.667.667-.667h4c.368 0 .667.298.667.667s-.298.667-.667.667H6c-.368 0-.667-.298-.667-.667z',
  heart:
    'M7.867 3.475L8 3.611l.133-.136c1.495-1.523 3.923-1.523 5.418 0a3.93 3.93 0 0 1 0 5.485l-4.123 4.202a2 2 0 0 1-2.855 0L2.449 8.961a3.93 3.93 0 0 1 0-5.485c1.495-1.523 3.923-1.523 5.418 0zM3.4 4.409c-.979.998-.979 2.62 0 3.618l4.123 4.202c.261.266.69.266.952 0l4.123-4.202c.979-.998.979-2.62 0-3.618a2.45 2.45 0 0 0-3.514 0l-.609.621c-.125.128-.297.2-.476.2s-.35-.072-.476-.2l-.609-.621a2.45 2.45 0 0 0-3.514 0z',
  'heart-fill':
    'M7.867 3.475L8 3.611l.133-.136c1.495-1.523 3.923-1.523 5.418 0a3.93 3.93 0 0 1 0 5.485l-4.123 4.202a2 2 0 0 1-2.855 0L2.449 8.961a3.93 3.93 0 0 1 0-5.485c1.495-1.523 3.923-1.523 5.418 0z',
  basket:
    'M9.414 2.472l2.862 2.862h1.622c.406 0 .717.359.66.761l-.979 6.855a2 2 0 0 1-1.98 1.717H4.401a2 2 0 0 1-1.98-1.717l-.979-6.855c-.057-.402.254-.761.66-.761h1.622l2.862-2.862a2 2 0 0 1 2.828 0zm3.715 4.195H2.87l.871 6.094c.047.328.328.572.66.572h7.197c.332 0 .613-.244.66-.572l.871-6.094zM8.471 3.415a.67.67 0 0 0-.943 0h0L5.609 5.334h4.781zM5.333 8c.368 0 .667.298.667.667v2.667c0 .368-.298.667-.667.667s-.667-.298-.667-.667V8.667c0-.368.298-.667.667-.667zM8 8c.368 0 .667.298.667.667v2.667c0 .368-.298.667-.667.667s-.667-.298-.667-.667V8.667A.67.67 0 0 1 8 8zm2.667 0c.368 0 .667.298.667.667v2.667c0 .368-.298.667-.667.667S10 11.702 10 11.334V8.667c0-.368.298-.667.667-.667z',
  'video-recorder':
    'M9.333 3.333a2 2 0 0 1 2 2v.381l1.225-.875c.883-.63 2.108 0 2.108 1.085v4.151c0 1.085-1.226 1.715-2.108 1.085l-1.225-.875v.381a2 2 0 0 1-2 2h-6a2 2 0 0 1-2-2V5.333a2 2 0 0 1 2-2h6zm0 1.333h-6c-.368 0-.667.298-.667.667h0v5.333c0 .368.298.667.667.667h0 6c.368 0 .667-.298.667-.667h0V5.333c0-.368-.298-.667-.667-.667h0zm4 1.258l-2 1.429v1.294l2 1.429V5.924z',
  phone:
    'M3.526 2.121a1.55 1.55 0 0 1 2.094.385h0l1.005 1.371a2 2 0 0 1 .176 2.077h0l-.273.546c-.079.158-.092.341-.011.498.147.286.461.794 1.066 1.399s1.112.918 1.399 1.066c.157.081.34.068.498-.011h0l.546-.273a2 2 0 0 1 2.077.176h0l1.371 1.005a1.55 1.55 0 0 1 .385 2.094c-1.096 1.694-3.104 2.565-4.871 1.592-1.153-.635-2.54-1.6-3.998-3.057S2.568 8.146 1.933 6.992C.96 5.225 1.832 3.217 3.526 2.121zM4.25 3.24c-1.315.851-1.676 2.151-1.149 3.109.577 1.048 1.467 2.333 2.832 3.698s2.65 2.255 3.698 2.832c.958.528 2.258.166 3.109-1.149.063-.097.039-.226-.054-.295l-1.371-1.005c-.202-.148-.469-.17-.692-.059l-.546.273a1.88 1.88 0 0 1-1.705.004c-.403-.207-1.024-.601-1.731-1.309S5.539 8.012 5.331 7.608a1.88 1.88 0 0 1 .004-1.705l.273-.546c.112-.224.089-.491-.059-.692L4.544 3.295c-.068-.093-.198-.117-.295-.054z',
  design:
    'M3.757 1.872a2 2 0 0 1 2.828 0h0l1.9 1.899 1.69-1.69a2 2 0 0 1 2.828 0h0l.943.943a2 2 0 0 1 0 2.828h0l-1.69 1.69 1.872 1.872a2 2 0 0 1 0 2.828h0l-1.886 1.886a2 2 0 0 1-2.828 0h0l-1.872-1.872-1.467 1.467c-.093.093-.212.156-.341.182h0l-3.536.707c-.466.093-.878-.318-.784-.784h0l.707-3.536c.026-.129.089-.248.182-.341h0l1.467-1.467-1.899-1.9a2 2 0 0 1 0-2.828h0zm7.556 6.614l-2.828 2.828.458.458.471-.471c.26-.26.682-.26.943 0s.26.682 0 .943l-.471.471.471.471c.26.26.682.26.943 0l1.886-1.886c.26-.26.26-.682 0-.943l-1.872-1.872zM9.233 4.909L3.39 10.752l-.471 2.357 2.357-.471 5.843-5.843-1.886-1.886zM4.7 2.815L2.815 4.7c-.26.26-.26.682 0 .943l.471.471.943-.943c.26-.26.682-.26.943 0s.26.682 0 .943l-.943.943.485.485 2.828-2.828-1.9-1.9c-.26-.26-.682-.26-.943 0zm6.418.209l-.943.943 1.886 1.886.943-.943c.26-.26.26-.682 0-.943l-.943-.943c-.26-.26-.682-.26-.943 0z',
  multiselect:
    'M12.666 1.333a2 2 0 0 1 1.995 1.851l.005.149V10a2 2 0 0 1-1.851 1.995l-.149.005H12v.667a2 2 0 0 1-2 2h0-6.667a2 2 0 0 1-2-2h0V6a2 2 0 0 1 2-2h0H4v-.667A2 2 0 0 1 5.85 1.338L6 1.333h6.667zM4 5.333h-.667c-.368 0-.667.298-.667.667h0v6.667c0 .368.298.667.667.667h0H10c.368 0 .667-.298.667-.667h0V12H6a2 2 0 0 1-1.995-1.851L4 10V5.333zm8.667-2.667H6c-.368 0-.667.298-.667.667V10c0 .368.298.667.667.667h6.667c.368 0 .667-.298.667-.667V3.333c0-.368-.298-.667-.667-.667zm-.862 2.195c.26.26.26.682 0 .943L9.138 8.471c-.26.26-.682.26-.943 0L6.862 7.138c-.26-.26-.26-.682 0-.943s.682-.26.943 0l.862.862 2.195-2.195c.26-.26.682-.26.943 0z',
  schedule:
    'M15 11a4 4 0 0 1-4 4 3.98 3.98 0 0 1-2.229-.678l.017.011H3a2 2 0 0 1-2-2h0v-8a2 2 0 0 1 2-2h0 .667v-.667c0-.368.298-.667.667-.667S5 1.298 5 1.667h0v.667h5.333v-.667c0-.368.298-.667.667-.667s.667.298.667.667h0v.667h.667a2 2 0 0 1 2 2h0v4.455h0l.022.034A3.98 3.98 0 0 1 15 11zm-4-2.667c-1.473 0-2.667 1.194-2.667 2.667S9.527 13.667 11 13.667s2.667-1.194 2.667-2.667S12.473 8.333 11 8.333zM3.667 3.667H3c-.368 0-.667.298-.667.667h0v8c0 .368.298.667.667.667h0 4.535C7.198 12.423 7 11.735 7 11a4 4 0 0 1 4-4 3.98 3.98 0 0 1 2.015.544L13 7.535V4.333c0-.368-.298-.667-.667-.667h0-.667v.667c0 .368-.298.667-.667.667s-.667-.298-.667-.667h0v-.667H5v.667C5 4.702 4.702 5 4.333 5s-.667-.298-.667-.667h0v-.667zM11 9c.368 0 .667.298.667.667v1.057l.471.471c.26.26.26.682 0 .943s-.682.26-.943 0l-.667-.667c-.125-.125-.195-.295-.195-.471V9.667c0-.368.298-.667.667-.667z',
  facebook:
    'M8.5 4.667c0-.736.597-1.333 1.333-1.333h.667c.368 0 .667-.298.667-.667S10.868 2 10.5 2h-.667C8.36 2 7.166 3.194 7.166 4.667V6H5.5c-.368 0-.667.298-.667.667s.298.667.667.667h1.667v6c0 .368.298.667.667.667s.667-.298.667-.667v-6h1.667c.368 0 .667-.298.667-.667S10.535 6 10.166 6H8.5V4.667z',
  'facebook-fill':
    'M6.023 16L6 9H3V6h3V4c0-2.699 1.672-4 4.079-4 1.153 0 2.145.086 2.434.124v2.821l-1.67.001c-1.31 0-1.563.622-1.563 1.535V6H13l-1 3H9.28v7H6.023z',
  twitter:
    'M10.266 2c1.133 0 1.867.733 2.267 1.2l1.533-.533c.267-.067.6 0 .733.2.2.2.2.533 0 .8l-1.2 1.8c.133.8.267 3.2-1.533 5.6-1.467 2.133-3.8 3-6.067 3-1.6 0-3.267-.4-4.6-1.133-.267-.133-.4-.4-.333-.733.067-.267.267-.533.6-.533 1.133-.133 2-.333 2.8-.733-1.933-1.467-2.933-4.133-2.8-7.6 0-.267.2-.533.4-.6.267-.133.533-.067.733.133C4.133 4.2 5.733 5.6 7.266 5.467c0-.667.067-1.733.667-2.467.533-.667 1.333-1 2.333-1zm.067 1.333c-.6 0-1.067.133-1.333.533-.467.6-.4 1.667-.333 2.067.067.333-.133.667-.467.733-1.933.667-3.867-.6-5.2-1.733.2 2.133.867 4.4 3 5.467.2.133.333.333.333.533a.7.7 0 0 1-.333.6c-.6.4-1.267.733-1.933.933 2.533.6 5.333.133 7.067-2.2 1.8-2.333 1.267-4.667 1.2-4.8-.067-.2 0-.4.067-.533l.267-.4-.133.067c-.333.133-.667-.067-.8-.333l-.025-.04c-.114-.179-.617-.893-1.375-.893z',
  'twitter-fill':
    'M13.404 5.756l-.05-.353 1.256-1.884c.079-.118.074-.274-.012-.387s-.235-.159-.37-.114l-1.747.582a3.37 3.37 0 0 0-.412-.502c-.37-.37-.949-.764-1.736-.764-.815 0-1.418.228-1.849.603s-.651.856-.766 1.317-.125.912-.108 1.243l.02.26c-.837.178-1.712-.095-2.57-.604-.946-.561-1.813-1.377-2.491-2.055-.094-.094-.235-.123-.358-.074s-.206.167-.21.3c-.085 2.718.475 6.008 3.007 7.676-1.065.576-2.109.845-3.382 1.004-.146.018-.262.13-.287.274s.048.289.179.354c3.439 1.72 7.896 1.595 10.416-1.765 1.045-1.393 1.395-2.789 1.482-3.839.044-.524.022-.962-.011-1.272z',
  instagram:
    'M10.663 1.333a4 4 0 0 1 3.995 3.8l.005.2v5.333a4 4 0 0 1-3.8 3.995l-.2.005H5.33a4 4 0 0 1-3.995-3.8l-.005-.2V5.333a4 4 0 0 1 3.8-3.995l.2-.005h5.333zm0 1.333H5.33c-1.473 0-2.667 1.194-2.667 2.667v5.333c0 1.473 1.194 2.667 2.667 2.667h5.333c1.473 0 2.667-1.194 2.667-2.667V5.333c0-1.473-1.194-2.667-2.667-2.667zm-2.667 2c1.841 0 3.333 1.492 3.333 3.333s-1.492 3.333-3.333 3.333S4.663 9.841 4.663 8s1.492-3.333 3.333-3.333zm0 1.333a2 2 0 1 0 0 4 2 2 0 1 0 0-4zm3.333-2c.368 0 .667.298.667.667s-.298.667-.667.667-.667-.298-.667-.667S10.962 4 11.33 4z',
  pinterest:
    'M8.213 1.36c1.774-.082 3.6.551 4.647 1.897 2.665 3.427.088 8.076-3.526 8.076-.858 0-1.532-.188-2.045-.527a2.5 2.5 0 0 1-.5-.434l-1.177 3.824c-.108.352-.481.549-.833.441s-.549-.481-.441-.833l2.667-8.667c.108-.352.481-.549.833-.441s.549.481.441.833l-.807 2.624-.005.039c-.006.053-.013.133-.014.23-.002.199.022.445.108.678.084.225.223.434.464.593s.645.306 1.31.306c2.477 0 4.475-3.351 2.474-5.924-.713-.917-2.067-1.451-3.533-1.384-1.449.067-2.884.719-3.745 2.011-.56.84-.611 1.642-.447 2.248.175.647.565 1.003.807 1.08.351.112.544.487.433.838s-.487.544-.838.433c-.803-.256-1.436-1.067-1.689-2.003-.264-.977-.149-2.175.625-3.335C4.559 2.255 6.423 1.442 8.213 1.36z',
  mail: 'M12.666 2.667a2 2 0 0 1 2 2v6.667a2 2 0 0 1-2 2H3.333a2 2 0 0 1-2-2V4.667a2 2 0 0 1 2-2h9.333zm.667 3.246L9.109 8.729a2 2 0 0 1-2.219 0L2.666 5.913v5.421c0 .368.298.667.667.667h9.333c.368 0 .667-.298.667-.667V5.913zM12.666 4H3.333c-.257 0-.481.146-.592.36h0L7.63 7.619c.224.149.516.149.74 0h0l4.889-3.259c-.111-.214-.334-.36-.592-.36h0z',
  payment:
    'M12.666 2.667a2 2 0 0 1 2 2v6.667a2 2 0 0 1-2 2H3.333a2 2 0 0 1-2-2V4.667a2 2 0 0 1 2-2h9.333zm.667 4H2.666v4.667c0 .368.298.667.667.667h9.333c.368 0 .667-.298.667-.667V6.667zM12.666 4H3.333c-.368 0-.667.298-.667.667h0v.667h10.667v-.667c0-.368-.298-.667-.667-.667h0zM4 10c0-.368.298-.667.667-.667h4c.368 0 .667.298.667.667s-.298.667-.667.667h-4C4.298 10.667 4 10.369 4 10z',
  edit: 'M12.518 2.545l.943.943a2 2 0 0 1 0 2.828l-6.928 6.928c-.093.093-.212.157-.341.182l-3.536.707c-.466.093-.878-.318-.784-.784l.707-3.536c.026-.129.089-.248.182-.341L9.69 2.545a2 2 0 0 1 2.828 0zM8.747 5.373l-4.9 4.9-.471 2.357 2.357-.471 4.9-4.9-1.886-1.886zm2.828-1.886c-.26-.26-.682-.26-.943 0h0l-.943.943 1.886 1.886.943-.943c.26-.26.26-.682 0-.943h0z',
  upload:
    'M13.333 9.167c.368 0 .667.298.667.667v1.333a2 2 0 0 1-2 2H4a2 2 0 0 1-2-2V9.833c0-.368.298-.667.667-.667s.667.298.667.667v1.333c0 .368.298.667.667.667h8c.368 0 .667-.298.667-.667V9.833c0-.368.298-.667.667-.667zM8.001 2.833c.177 0 .346.07.471.195l2.999 2.999c.26.26.26.682 0 .943s-.682.26-.943 0L8.668 5.109v4.724c0 .368-.298.667-.667.667s-.667-.298-.667-.667V5.109L5.473 6.971c-.26.26-.682.26-.943 0s-.26-.682 0-.943l3-3c.125-.125.295-.195.471-.195z',
  expand:
    'M1.333 3.333a2 2 0 0 1 2-2h1.333c.368 0 .667.298.667.667s-.298.667-.667.667H3.333c-.368 0-.667.298-.667.667v1.333c0 .368-.298.667-.667.667s-.667-.298-.667-.667V3.333zm0 9.333a2 2 0 0 0 2 2h1.333c.368 0 .667-.298.667-.667s-.298-.667-.667-.667H3.333c-.368 0-.667-.298-.667-.667v-1.333c0-.368-.298-.667-.667-.667s-.667.298-.667.667v1.333zm13.333 0a2 2 0 0 1-2 2h-1.333c-.368 0-.667-.298-.667-.667s.298-.667.667-.667h1.333c.368 0 .667-.298.667-.667v-1.333c0-.368.298-.667.667-.667s.667.298.667.667v1.333zm0-9.333a2 2 0 0 0-2-2h-1.333c-.368 0-.667.298-.667.667s.298.667.667.667h1.333c.368 0 .667.298.667.667v1.333c0 .368.298.667.667.667s.667-.298.667-.667V3.333z',
  calendar:
    'M11.333 1.333c.368 0 .667.298.667.667v.667h.667a2 2 0 0 1 2 2v8a2 2 0 0 1-2 2H3.333a2 2 0 0 1-2-2v-8a2 2 0 0 1 2-2H4V2c0-.368.298-.667.667-.667s.667.298.667.667v.667h5.333V2c0-.368.298-.667.667-.667zm2 6.667H2.666v4.667c0 .368.298.667.667.667h9.333c.368 0 .667-.298.667-.667V8zM4 4h-.667c-.368 0-.667.298-.667.667h0v2h10.667v-2c0-.368-.298-.667-.667-.667h0H12v.667c0 .368-.298.667-.667.667s-.667-.298-.667-.667h0V4H5.333v.667c0 .368-.298.667-.667.667S4 5.035 4 4.666h0V4z',
  list: 'M13.333 10.667c.368 0 .667.298.667.667s-.298.667-.667.667h-8c-.368 0-.667-.298-.667-.667s.298-.667.667-.667h8zm-10.667 0c.368 0 .667.298.667.667S3.035 12 2.667 12 2 11.702 2 11.333s.298-.667.667-.667zm10.667-3.333c.368 0 .667.298.667.667s-.298.667-.667.667h-8c-.368 0-.667-.298-.667-.667s.298-.667.667-.667h8zm-10.667 0c.368 0 .667.298.667.667s-.298.667-.667.667S2 8.368 2 8s.298-.667.667-.667zM13.333 4c.368 0 .667.298.667.667s-.298.667-.667.667h-8c-.368 0-.667-.298-.667-.667S4.965 4 5.333 4h8zM2.667 4c.368 0 .667.298.667.667s-.298.667-.667.667S2 5.035 2 4.667 2.298 4 2.667 4z',
  clock:
    'M8 1.333A6.67 6.67 0 0 1 14.666 8 6.67 6.67 0 0 1 8 14.666 6.67 6.67 0 0 1 1.333 8 6.67 6.67 0 0 1 8 1.333zm0 1.333C5.054 2.666 2.666 5.054 2.666 8S5.054 13.333 8 13.333 13.333 10.945 13.333 8 10.945 2.666 8 2.666zM8 4c.368 0 .667.298.667.667v3.057l1.471 1.471c.26.26.26.682 0 .943s-.682.26-.943 0L7.724 8.666c-.25-.25-.391-.589-.391-.943V4.666C7.333 4.298 7.631 4 8 4z',
  'star-stroke':
    'M6.741 2.563c.415-1.194 2.104-1.194 2.519 0h0l.63 1.813c.183.527.675.884 1.232.895h0l1.919.039c1.264.026 1.786 1.632.778 2.396h0l-1.53 1.16c-.444.337-.632.915-.471 1.449h0l.556 1.838c.366 1.21-1 2.203-2.038 1.481h0l-1.576-1.097c-.458-.319-1.065-.319-1.523 0h0l-1.576 1.097c-1.038.722-2.404-.271-2.038-1.481h0l.556-1.838c.161-.534-.026-1.112-.471-1.449h0l-1.53-1.16c-1.007-.764-.486-2.37.778-2.396h0l1.919-.039c.558-.011 1.049-.369 1.232-.895h0zM8 3l-.63 1.813C7.003 5.867 6.02 6.582 4.905 6.604l-1.919.039 1.53 1.16c.889.674 1.264 1.83.941 2.897l-.556 1.838 1.576-1.097c.916-.637 2.131-.637 3.046 0l1.576 1.097-.556-1.838c-.323-1.068.053-2.223.941-2.897l1.53-1.16-1.919-.039C9.98 6.582 8.997 5.867 8.63 4.814L8 3z',
  'star-fill':
    'M6.758 2.563c.415-1.194 2.104-1.194 2.519 0l.63 1.813c.183.527.675.884 1.232.895l1.919.039c1.264.026 1.786 1.632.778 2.396l-1.53 1.16c-.444.337-.632.915-.471 1.449l.556 1.838c.366 1.21-1 2.203-2.038 1.481l-1.576-1.097c-.458-.319-1.065-.319-1.523 0L5.68 13.633c-1.038.722-2.404-.271-2.038-1.481l.556-1.838c.161-.534-.026-1.112-.471-1.449l-1.53-1.16c-1.007-.764-.486-2.37.778-2.396l1.919-.039c.558-.011 1.049-.369 1.232-.895l.63-1.813z',
  smile:
    'M8 1.333A6.67 6.67 0 0 1 14.666 8 6.67 6.67 0 0 1 8 14.666 6.67 6.67 0 0 1 1.333 8 6.67 6.67 0 0 1 8 1.333zm0 1.333C5.054 2.666 2.666 5.054 2.666 8S5.054 13.333 8 13.333 13.333 10.945 13.333 8 10.945 2.666 8 2.666zm3.323 6.056c.337.147.491.54.344.878a4 4 0 0 1-7.334 0c-.147-.337.007-.73.344-.878s.73.007.878.344c.412.943 1.353 1.6 2.445 1.6s2.033-.657 2.445-1.6c.147-.337.54-.491.878-.344zm-5.99-3.389c.368 0 .667.298.667.667s-.298.667-.667.667-.667-.298-.667-.667.298-.667.667-.667zm5.333 0c.368 0 .667.298.667.667s-.298.667-.667.667S10 6.368 10 6s.298-.667.667-.667z',
  repeat:
    'M10.805 1.862a.67.67 0 0 0-.943 0c-.261.26-.26.682 0 .943l.526.526H6.667A4.67 4.67 0 0 0 2 7.998v.667c0 .368.298.667.667.667s.667-.299.667-.667v-.667c0-1.841 1.492-3.333 3.333-3.333h3.724l-.529.529a.67.67 0 0 0 0 .943c.26.261.682.26.943 0l1.43-1.43a1 1 0 0 0 0-1.414l-1.43-1.43zM9.332 12.667A4.67 4.67 0 0 0 13.999 8v-.667c0-.368-.298-.667-.667-.667s-.667.298-.667.667V8c0 1.841-1.492 3.333-3.333 3.333H5.608l.529-.529a.67.67 0 0 0 0-.943c-.26-.261-.682-.26-.943 0l-1.43 1.43a1 1 0 0 0 0 1.414l1.43 1.43a.67.67 0 0 0 .943 0c.261-.26.26-.682 0-.943l-.526-.526h3.721z',
  mobile:
    'M10.666 1.333a2 2 0 0 1 2 2v9.333a2 2 0 0 1-2 2H5.333a2 2 0 0 1-2-2V3.333a2 2 0 0 1 2-2h5.333zM6 2.666h-.667c-.368 0-.667.298-.667.667v9.333c0 .368.298.667.667.667h5.333c.368 0 .667-.298.667-.667V3.333c0-.368-.298-.667-.667-.667H10a1 1 0 0 1-1 1H7a1 1 0 0 1-1-1z',
  tablet:
    'M12 1.333a2 2 0 0 1 1.995 1.851l.005.149v9.333a2 2 0 0 1-1.851 1.995l-.149.005H4a2 2 0 0 1-1.995-1.851L2 12.666V3.333a2 2 0 0 1 1.851-1.995L4 1.333h8zm0 1.333H4c-.368 0-.667.298-.667.667v9.333c0 .368.298.667.667.667h8c.368 0 .667-.298.667-.667V3.333c0-.368-.298-.667-.667-.667zm-4 8.667c.368 0 .667.298.667.667s-.298.667-.667.667-.667-.298-.667-.667.298-.667.667-.667z',
  desktop:
    'M14.667 12.667c.368 0 .667.298.667.667s-.298.667-.667.667H1.334c-.368 0-.667-.298-.667-.667s.298-.667.667-.667h13.333zM12.667 2a2 2 0 0 1 1.995 1.851l.005.149v6a2 2 0 0 1-1.851 1.995l-.149.005H3.334a2 2 0 0 1-1.995-1.851L1.334 10V4a2 2 0 0 1 1.851-1.995L3.334 2h9.333zm0 1.333H3.334c-.368 0-.667.298-.667.667v6c0 .368.298.667.667.667h9.333c.368 0 .667-.298.667-.667V4c0-.368-.298-.667-.667-.667z',
  filter:
    'M13.333 1.467c.736 0 1.333.597 1.333 1.333v1.028a2 2 0 0 1-.764 1.573L10 8.467v4.454c0 .283-.179.535-.445.629l-2.667.938c-.434.153-.888-.169-.888-.629V8.467L2.097 5.401a2 2 0 0 1-.764-1.573V2.8c0-.736.597-1.333 1.333-1.333h10.667zm0 1.2H2.666v1.028c0 .205.094.398.255.524l3.902 3.066c.322.253.51.639.51 1.048v4.451l1.333-.469V8.334c0-.409.188-.796.51-1.048l3.902-3.066c.161-.126.255-.32.255-.524V2.667z',
  mouse:
    'M7.834 1.333a4.67 4.67 0 0 1 4.662 4.453L12.5 6v4a4.67 4.67 0 0 1-4.667 4.667 4.67 4.67 0 0 1-4.662-4.453L3.167 10V6a4.67 4.67 0 0 1 4.667-4.667zm0 1.333C5.993 2.666 4.5 4.159 4.5 6v4c0 1.841 1.492 3.333 3.333 3.333s3.333-1.492 3.333-3.333V6c0-1.841-1.492-3.333-3.333-3.333zm0 1.333c.368 0 .667.298.667.667v2c0 .368-.298.667-.667.667s-.667-.298-.667-.667v-2c0-.368.298-.667.667-.667z',
  image:
    'M12.667 1.333a2 2 0 0 1 2 2v9.333a2 2 0 0 1-2 2H3.334a2 2 0 0 1-2-2V3.333a2 2 0 0 1 2-2h9.333zM4.036 6.424L2.822 7.881c-.1.12-.155.271-.155.427v4.359c0 .368.298.667.667.667h9.333l.055-.002c.322-.027.316-.434.098-.672L7.047 6.353a2 2 0 0 0-3.011.071zm7.298-3.091c-.736 0-1.333.597-1.333 1.333S10.598 6 11.334 6s1.333-.597 1.333-1.333-.597-1.333-1.333-1.333z',
  'image-stroke':
    'M12.667 1.333a2 2 0 0 1 2 2v9.333a2 2 0 0 1-2 2H3.334a2 2 0 0 1-2-2V3.333a2 2 0 0 1 2-2h9.333zM5.06 7.278l-2.393 2.871v2.517c0 .368.298.667.667.667h8.302L6.064 7.254c-.273-.298-.745-.286-1.004.024zm7.607-4.611H3.334c-.335 0-.612.247-.659.568l-.007.099v4.733l1.369-1.642a2 2 0 0 1 2.896-.187l.115.116 6.085 6.638.066.08c.064-.084.109-.184.127-.293l.009-.111V3.333c0-.368-.298-.667-.667-.667zm-2 1.333c.736 0 1.333.597 1.333 1.333s-.597 1.333-1.333 1.333-1.333-.597-1.333-1.333S9.931 4 10.667 4z',
  video:
    'M3.334 3.333a2 2 0 0 0-2 2v5.333a2 2 0 0 0 2 2h6a2 2 0 0 0 2-2v-.381l1.225.875c.882.63 2.108 0 2.108-1.085V5.924c0-1.085-1.226-1.715-2.108-1.085l-1.225.875v-.381a2 2 0 0 0-2-2h-6z',
  'video-stroke':
    'M9.334 3.333a2 2 0 0 1 2 2v.381l1.225-.875c.882-.63 2.108 0 2.108 1.085v4.152c0 1.084-1.226 1.715-2.108 1.085l-1.225-.875v.381a2 2 0 0 1-2 2h-6a2 2 0 0 1-2-2V5.333a2 2 0 0 1 2-2h6zm0 1.333h-6c-.368 0-.667.298-.667.667h0v5.333c0 .368.298.667.667.667h0 6c.368 0 .667-.298.667-.667h0V5.333c0-.368-.298-.667-.667-.667h0zm4 1.258l-2 1.429v1.294l2 1.429V5.924z',
  setting:
    'M8.414.673c.574 0 1.083.367 1.265.912l.258.775c.122.364.399.651.735.838l.155.09c.33.198.717.295 1.094.218l.801-.164c.562-.115 1.135.143 1.422.64l.414.717c.287.497.224 1.122-.157 1.551l-.543.613c-.255.287-.365.67-.359 1.054v.181c-.006.384.104.767.359 1.054l.543.613c.381.429.444 1.054.157 1.551l-.414.717c-.287.497-.86.755-1.422.64l-.801-.164c-.377-.077-.764.02-1.094.218l-.155.09c-.336.187-.613.473-.735.838l-.258.775c-.181.544-.691.912-1.265.912h-.828c-.574 0-1.083-.367-1.265-.912l-.258-.775c-.121-.364-.399-.651-.734-.838l-.155-.09c-.33-.198-.717-.295-1.094-.218l-.801.164c-.562.115-1.135-.143-1.422-.64l-.414-.717c-.287-.497-.224-1.122.157-1.551l.543-.613c.255-.287.365-.67.359-1.054v-.181a1.59 1.59 0 0 0-.258-.925l-.1-.129-.543-.613c-.349-.394-.431-.951-.222-1.425l.064-.127.414-.717c.265-.459.773-.714 1.292-.66l.13.02.801.164c.377.077.764-.02 1.094-.218l.155-.09c.336-.187.613-.473.734-.838l.258-.775c.181-.544.691-.912 1.265-.912h.828zm0 1.333h-.828l-.258.775c-.25.75-.795 1.272-1.351 1.581l-.117.068c-.547.328-1.272.54-2.047.381l-.801-.164-.414.717.543.613c.476.536.668 1.19.692 1.783l.002.176v.139c.009.58-.14 1.237-.56 1.795l-.134.164-.543.613.414.717.801-.164c.775-.159 1.501.053 2.047.381l.117.068c.556.31 1.101.831 1.351 1.581l.258.775h.828l.258-.775c.25-.75.795-1.272 1.351-1.581l.117-.068c.547-.328 1.272-.54 2.047-.381l.801.164.414-.717-.543-.613c-.523-.59-.704-1.322-.694-1.959v-.139c-.01-.638.171-1.37.694-1.96l.543-.613-.414-.717-.801.164c-.775.159-1.501-.053-2.047-.381l-.117-.068c-.556-.309-1.101-.831-1.351-1.581l-.258-.775zM8 5.34c1.473 0 2.667 1.194 2.667 2.667S9.473 10.673 8 10.673 5.334 9.479 5.334 8.006 6.528 5.34 8 5.34zm0 1.333c-.736 0-1.333.597-1.333 1.333S7.264 9.34 8 9.34s1.333-.597 1.333-1.333S8.737 6.673 8 6.673z',
  'file-add':
    'M10.667 1.333l.007.007a2 2 0 0 1 1.245.579l1.495 1.495a2 2 0 0 1 .579 1.245l.007.007v8a2 2 0 0 1-2 2H4a2 2 0 0 1-2-2V3.333a2 2 0 0 1 2-2h6.667zM10 2.666H4c-.335 0-.612.247-.659.568l-.007.099v9.333c0 .335.247.612.568.659l.099.007h8c.335 0 .612-.247.659-.568l.007-.099V5.333h-1.333c-.693 0-1.263-.529-1.327-1.205L10 4V2.666zM8 6c.368 0 .667.298.667.667h0V8H10c.368 0 .667.298.667.667s-.298.667-.667.667h0-1.333v1.333c0 .368-.298.667-.667.667s-.667-.298-.667-.667h0V9.333H6c-.368 0-.667-.298-.667-.667S5.632 8 6 8h0 1.333V6.666C7.333 6.298 7.632 6 8 6z',
  copy: 'M12 4.667a2 2 0 0 1 1.995 1.851l.005.149V12a2 2 0 0 1-1.851 1.995L12 14H6.667a2 2 0 0 1-1.995-1.851L4.667 12v-.667H4a2 2 0 0 1-2-2h0V4a2 2 0 0 1 2-2h0 5.333a2 2 0 0 1 2 2h0v.667H12zM12 6H6.667C6.298 6 6 6.298 6 6.667V12c0 .368.298.667.667.667H12c.368 0 .667-.298.667-.667V6.667c0-.368-.298-.667-.667-.667zm-2-2c0-.368-.298-.667-.667-.667h0H4c-.368 0-.667.298-.667.667h0v5.333c0 .368.298.667.667.667h0 .667V6.667a2 2 0 0 1 1.851-1.995l.149-.005H10z',
  download:
    'M13.333 9.333c.368 0 .667.298.667.667v1.333a2 2 0 0 1-2 2H4a2 2 0 0 1-2-2V10c0-.368.298-.667.667-.667s.667.298.667.667v1.333c0 .368.298.667.667.667h8c.368 0 .667-.298.667-.667V10c0-.368.298-.667.667-.667zM8.001 2.666c.368 0 .667.298.667.667h0v5.391l1.861-1.861c.26-.26.682-.26.943 0s.26.682 0 .943h0l-2.527 2.527c-.521.521-1.365.521-1.886 0h0L4.53 7.804c-.26-.26-.26-.682 0-.943s.682-.26.943 0h0l1.862 1.862V3.333c0-.368.299-.667.667-.667z',
  share:
    'M4.667 10.666C3.194 10.666 2 9.472 2 8s1.194-2.667 2.667-2.667a2.66 2.66 0 0 1 1.886.781l-.015-.015h0l2.238-1.343.008.028c-.076-.248-.117-.511-.117-.784 0-1.473 1.194-2.667 2.667-2.667S14 2.527 14 4s-1.194 2.667-2.667 2.667c-.68 0-1.3-.254-1.771-.673l-.1-.094h0L7.224 7.242l-.015-.05c.081.255.124.526.124.807 0 .273-.041.536-.117.784l.008-.028 2.238 1.343h0l-.015.015a2.66 2.66 0 0 1 1.886-.781C12.806 9.333 14 10.527 14 12s-1.194 2.667-2.667 2.667S8.667 13.472 8.667 12c0-.281.044-.553.124-.807l-.015.05-2.238-1.343h0l-.1.094c-.471.419-1.091.673-1.771.673zm6.667 0c-.736 0-1.333.597-1.333 1.333s.597 1.333 1.333 1.333 1.333-.597 1.333-1.333-.597-1.333-1.333-1.333zm-6.667-4c-.736 0-1.333.597-1.333 1.333s.597 1.333 1.333 1.333S6 8.736 6 8s-.597-1.333-1.333-1.333zm6.667-4C10.597 2.666 10 3.263 10 4s.597 1.333 1.333 1.333S12.667 4.736 12.667 4s-.597-1.333-1.333-1.333z',
  lock: 'M8 1.333c1.841 0 3.333 1.492 3.333 3.333v.667a2 2 0 0 1 2 2v5.333a2 2 0 0 1-2 2H4.667a2 2 0 0 1-2-2V7.333a2 2 0 0 1 2-2v-.667c0-1.841 1.492-3.333 3.333-3.333zm3.333 5.333H4.667c-.335 0-.612.247-.659.568L4 7.333v5.333c0 .335.247.612.568.659l.099.007h6.667c.335 0 .612-.247.659-.568l.007-.099V7.333c0-.368-.298-.667-.667-.667zM8 8c.736 0 1.333.597 1.333 1.333 0 .494-.268.924-.667 1.155h0v.845c0 .368-.298.667-.667.667s-.667-.298-.667-.667h0v-.845c-.399-.231-.667-.661-.667-1.155C6.667 8.597 7.264 8 8 8zm0-5.333a2 2 0 0 0-1.995 1.851L6 4.666v.667h4v-.667a2 2 0 0 0-2-2z',
  'donut-chart':
    'M7.333 3.334v2.661c0 .368-.306.656-.654.776-.161.056-.316.132-.46.228-.33.22-.587.534-.739.9s-.191.77-.114 1.159.268.746.549 1.027.638.471 1.027.549.792.038 1.159-.114.68-.409.9-.739c.096-.144.172-.298.228-.46.12-.348.408-.654.776-.654h2.661c.368 0 .67.299.63.665a6 6 0 0 1-3.667 4.878A6 6 0 0 1 3.09 12.91 6 6 0 0 1 1.79 6.371a6 6 0 0 1 4.878-3.667c.366-.041.665.261.665.63zM6 4.195c-.445.133-.869.331-1.259.592-.767.513-1.366 1.242-1.719 2.094s-.446 1.791-.266 2.696.625 1.737 1.277 2.389 1.484 1.097 2.389 1.277 1.844.088 2.696-.266 1.582-.951 2.094-1.719c.261-.39.459-.815.592-1.259h-1.411a3.34 3.34 0 0 1-1.783 1.751 3.34 3.34 0 0 1-3.638-.724 3.34 3.34 0 0 1-.724-3.638A3.34 3.34 0 0 1 6 5.606V4.195zm3.332-2.824a6 6 0 0 1 3.577 1.72 6 6 0 0 1 1.72 3.577c.041.366-.261.665-.63.665h-2.661c-.368 0-.656-.306-.776-.654l-.043-.113c-.101-.243-.248-.464-.435-.651s-.407-.334-.651-.435l-.113-.043c-.348-.12-.654-.408-.654-.776V2c0-.368.299-.67.665-.63zM10 2.862v1.411A3.34 3.34 0 0 1 11.727 6h1.411c-.046-.153-.099-.304-.161-.453-.235-.566-.578-1.081-1.012-1.514s-.948-.777-1.514-1.012A4.67 4.67 0 0 0 10 2.862z',
  careTeam:
    'M16.6854 16.997C18.5721 15.219 19.75 12.697 19.75 9.8999C19.75 4.51513 15.3848 0.149902 10 0.149902C4.61522 0.149902 0.25 4.51513 0.25 9.8999C0.25 12.697 1.42785 15.219 3.31463 16.997C5.06012 18.6418 7.41234 19.6499 10 19.6499C12.5877 19.6499 14.9399 18.6418 16.6854 16.997ZM4.14512 15.7122C5.51961 13.9977 7.63161 12.8999 10 12.8999C12.3684 12.8999 14.4804 13.9977 15.8549 15.7122C14.3603 17.2177 12.289 18.1499 10 18.1499C7.711 18.1499 5.63973 17.2177 4.14512 15.7122ZM13.75 6.8999C13.75 8.97097 12.0711 10.6499 10 10.6499C7.92893 10.6499 6.25 8.97097 6.25 6.8999C6.25 4.82883 7.92893 3.1499 10 3.1499C12.0711 3.1499 13.75 4.82883 13.75 6.8999Z',
  careReceivers:
    'M7.25 4.6499C7.25 2.57883 8.92893 0.899902 11 0.899902C13.0711 0.899902 14.75 2.57883 14.75 4.6499C14.75 6.72097 13.0711 8.3999 11 8.3999C8.92893 8.3999 7.25 6.72097 7.25 4.6499Z M14.75 7.6499C14.75 5.99305 16.0931 4.6499 17.75 4.6499C19.4069 4.6499 20.75 5.99305 20.75 7.6499C20.75 9.30676 19.4069 10.6499 17.75 10.6499C16.0931 10.6499 14.75 9.30676 14.75 7.6499Z M1.25 7.6499C1.25 5.99305 2.59315 4.6499 4.25 4.6499C5.90685 4.6499 7.25 5.99305 7.25 7.6499C7.25 9.30676 5.90685 10.6499 4.25 10.6499C2.59315 10.6499 1.25 9.30676 1.25 7.6499Z M5.30986 13.0174C6.50783 11.1443 8.60835 9.8999 11 9.8999C13.3919 9.8999 15.4927 11.1446 16.6906 13.0181C17.5187 14.3133 17.877 15.8751 17.709 17.3978C17.6827 17.6358 17.5444 17.8469 17.3368 17.9661C15.4694 19.0375 13.3051 19.6499 11 19.6499C8.69492 19.6499 6.53059 19.0375 4.66325 17.9661C4.45559 17.8469 4.3173 17.6358 4.29103 17.3978C4.12293 15.8748 4.48141 14.3128 5.30986 13.0174Z M4.08228 12.1536C4.07024 12.1721 4.05827 12.1906 4.04638 12.2092C3.08091 13.7188 2.63908 15.5166 2.77471 17.2889C2.16667 17.1966 1.5767 17.048 1.01043 16.8486L0.89547 16.8081C0.685759 16.7342 0.539227 16.5438 0.521591 16.3221L0.511923 16.2007C0.504017 16.1013 0.5 16.001 0.5 15.8999C0.5 13.885 2.08905 12.2413 4.08228 12.1536Z M19.2256 17.2889C19.3612 15.5169 18.9196 13.7195 17.9545 12.2101C17.9424 12.1912 17.9303 12.1724 17.9181 12.1536C19.9111 12.2415 21.5 13.8852 21.5 15.8999C21.5 16.001 21.496 16.1013 21.4881 16.2007L21.4784 16.3221C21.4608 16.5438 21.3142 16.7342 21.1045 16.8081L20.9896 16.8486C20.4234 17.048 19.8336 17.1965 19.2256 17.2889Z',
  logout:
    'M3.5 1.6499C2.67157 1.6499 2 2.32148 2 3.1499L2 16.6499C2 17.4783 2.67157 18.1499 3.5 18.1499H9.5C10.3284 18.1499 11 17.4783 11 16.6499V12.8999C11 12.4857 11.3358 12.1499 11.75 12.1499C12.1642 12.1499 12.5 12.4857 12.5 12.8999V16.6499C12.5 18.3068 11.1569 19.6499 9.5 19.6499H3.5C1.84315 19.6499 0.5 18.3068 0.5 16.6499L0.500001 3.1499C0.500001 1.49305 1.84315 0.149902 3.5 0.149902L9.5 0.149903C11.1569 0.149903 12.5 1.49305 12.5 3.1499V6.8999C12.5 7.31412 12.1642 7.6499 11.75 7.6499C11.3358 7.6499 11 7.31412 11 6.8999V3.1499C11 2.32148 10.3284 1.6499 9.5 1.6499L3.5 1.6499ZM14.2197 6.36957C14.5126 6.07668 14.9874 6.07668 15.2803 6.36957L18.2803 9.36957C18.5732 9.66247 18.5732 10.1373 18.2803 10.4302L15.2803 13.4302C14.9874 13.7231 14.5126 13.7231 14.2197 13.4302C13.9268 13.1373 13.9268 12.6625 14.2197 12.3696L15.9393 10.6499L5 10.6499C4.58579 10.6499 4.25 10.3141 4.25 9.8999C4.25 9.48569 4.58579 9.1499 5 9.1499L15.9393 9.1499L14.2197 7.43023C13.9268 7.13734 13.9268 6.66247 14.2197 6.36957Z',
  carlLogo:
    'M81.3443 46.2698H90.9029V26.7619C90.9029 22.5471 94.0306 19.593 98.2455 19.593C99.1138 19.593 100.809 19.7232 101.851 19.9837V11.2084C101.156 11.1216 100.373 11.0782 99.2875 11.0782C95.6388 11.0782 92.2488 13.4244 90.9897 17.7695H90.6424V11.7746H81.3443V46.2716V46.2698ZM57.0137 46.9645C61.6627 46.9645 65.1378 44.9222 66.7894 41.7945H67.1367V46.2698H76.2177V23.3737C76.2177 15.7706 69.9188 11.0782 61.1852 11.0782C52.4515 11.0782 46.2829 16.0311 45.458 23.5474L54.0162 23.504C55.1451 19.7232 58.0574 18.1602 60.8812 18.1602C64.2261 18.1602 66.6591 20.1157 66.6591 23.3737V32.2376C66.6591 37.3208 63.7051 40.2315 58.535 40.2315C56.1454 40.2315 54.5806 38.9724 54.5806 36.5827C54.5806 34.0194 56.7098 32.6284 58.6653 32.281L67.7029 30.6729V24.3289L57.1023 26.1541C50.3241 27.3263 45.1975 30.2821 45.1975 37.102C45.1975 43.9219 50.1938 46.9645 57.0154 46.9645M22.3448 46.9645C33.9022 46.9645 41.2431 39.2746 42.2868 30.6729L32.4678 30.5861C31.5994 35.4522 27.8621 38.3628 22.5184 38.3628C14.2207 38.3628 10.7873 31.7583 10.7873 23.504C10.7873 15.2496 14.5229 8.60168 22.5618 8.60168C27.9924 8.60168 31.7731 11.686 32.4678 16.7258H42.3302C41.1997 6.60452 33.5098 0 22.343 0C8.91865 0 0.924805 10.2098 0.924805 23.504C0.924805 37.4945 9.0489 46.9645 22.343 46.9645M105.892 0.651247H115.451V34.8009C115.451 36.06 115.754 36.9301 116.362 37.4945C116.927 38.1023 117.97 38.3628 119.447 38.3628H122.878V46.2698H117.838C109.845 46.2698 105.89 42.4023 105.89 34.7124V0.651247H105.892Z',
  dropdownDots: `M3.968,12.061C1.775,12.061,0,13.835,0,16.027c0,2.192,1.773,3.967,3.968,3.967c2.189,0,3.966-1.772,3.966-3.967
  C7.934,13.835,6.157,12.061,3.968,12.061z M16.233,12.061c-2.188,0-3.968,1.773-3.968,3.965c0,2.192,1.778,3.967,3.968,3.967
  s3.97-1.772,3.97-3.967C20.201,13.835,18.423,12.061,16.233,12.061z M28.09,12.061c-2.192,0-3.969,1.774-3.969,3.967
  c0,2.19,1.774,3.965,3.969,3.965c2.188,0,3.965-1.772,3.965-3.965S30.278,12.061,28.09,12.061z`,
  pencil: `M21.7312 2.26884C20.706 1.24372 19.044 1.24372 18.0189 2.26884L16.8617 3.426L20.574 7.13831L21.7312 5.98116C22.7563 4.95603 22.7563 3.29397 21.7312 2.26884Z
  M19.5134 8.19897L15.801 4.48666L3.65021 16.6375C3.03342 17.2543 2.58003 18.015 2.33101 18.851L1.53123 21.5359C1.45261 21.7998 1.52496 22.0856 1.71969 22.2803C1.91442 22.4751 2.2002 22.5474 2.46413 22.4688L5.14902 21.669C5.98499 21.42 6.74574 20.9666 7.36253 20.3498L19.5134 8.19897Z`,
  plus: 'M12 3.75C12.4142 3.75 12.75 4.08579 12.75 4.5V11.25H19.5C19.9142 11.25 20.25 11.5858 20.25 12C20.25 12.4142 19.9142 12.75 19.5 12.75H12.75V19.5C12.75 19.9142 12.4142 20.25 12 20.25C11.5858 20.25 11.25 19.9142 11.25 19.5V12.75H4.5C4.08579 12.75 3.75 12.4142 3.75 12C3.75 11.5858 4.08579 11.25 4.5 11.25H11.25V4.5C11.25 4.08579 11.5858 3.75 12 3.75Z',
  changeRole:
    'M15.9815 16.7348C14.6121 14.9275 12.4424 13.76 10 13.76C7.55761 13.76 5.38789 14.9275 4.01846 16.7348M15.9815 16.7348C17.8335 15.0864 19 12.6844 19 10.01C19 5.03945 14.9706 1.01001 10 1.01001C5.02944 1.01001 1 5.03945 1 10.01C1 12.6844 2.1665 15.0864 4.01846 16.7348M15.9815 16.7348C14.3915 18.1501 12.2962 19.01 10 19.01C7.70383 19.01 5.60851 18.1501 4.01846 16.7348M13 7.76001C13 9.41686 11.6569 10.76 10 10.76C8.34315 10.76 7 9.41686 7 7.76001C7 6.10316 8.34315 4.76001 10 4.76001C11.6569 4.76001 13 6.10316 13 7.76001Z',
  remove:
    'M11.7404 8.01001L11.3942 17.01M6.60577 17.01L6.25962 8.01001M16.2276 4.80058C16.5696 4.85222 16.9104 4.90748 17.25 4.9663M16.2276 4.80058L15.1598 18.6826C15.0696 19.8548 14.0921 20.76 12.9164 20.76H5.08357C3.90786 20.76 2.93037 19.8548 2.8402 18.6826L1.77235 4.80058M16.2276 4.80058C15.0812 4.62745 13.9215 4.49486 12.75 4.40433M0.75 4.9663C1.08957 4.90748 1.43037 4.85222 1.77235 4.80058M1.77235 4.80058C2.91878 4.62745 4.07849 4.49486 5.25 4.40433M12.75 4.40433V3.4882C12.75 2.30883 11.8393 1.32424 10.6606 1.28653C10.1092 1.2689 9.55565 1.26001 9 1.26001C8.44435 1.26001 7.89078 1.2689 7.33942 1.28653C6.16065 1.32424 5.25 2.30883 5.25 3.4882V4.40433M12.75 4.40433C11.5126 4.30871 10.262 4.26001 9 4.26001C7.73803 4.26001 6.48744 4.30871 5.25 4.40433',
  sendInvite:
    'M3.99947 10.01L1.26855 1.13452C7.88369 3.05618 14.0273 6.08602 19.4852 10.0097C14.0274 13.9335 7.88376 16.9635 1.26865 18.8852L3.99947 10.01ZM3.99947 10.01L11.4998 10.01',
  uploadPhoto:
    'M1.25 12.76L6.40901 7.601C7.28769 6.72232 8.71231 6.72232 9.59099 7.601L14.75 12.76M13.25 11.26L14.659 9.851C15.5377 8.97232 16.9623 8.97232 17.841 9.851L20.75 12.76M2.75 16.51H19.25C20.0784 16.51 20.75 15.8384 20.75 15.01V3.01001C20.75 2.18158 20.0784 1.51001 19.25 1.51001H2.75C1.92157 1.51001 1.25 2.18158 1.25 3.01001V15.01C1.25 15.8384 1.92157 16.51 2.75 16.51ZM13.25 5.26001H13.2575V5.26751H13.25V5.26001ZM13.625 5.26001C13.625 5.46712 13.4571 5.63501 13.25 5.63501C13.0429 5.63501 12.875 5.46712 12.875 5.26001C12.875 5.0529 13.0429 4.88501 13.25 4.88501C13.4571 4.88501 13.625 5.0529 13.625 5.26001Z',
    refresh: "M2.75541 7.05914C3.82748 3.05814 7.94 0.683774 11.941 1.75584C13.262 2.10979 14.4038 2.79398 15.3036 3.69552L17.2067 5.59859H14.023C13.6088 5.59859 13.273 5.93438 13.273 6.34859C13.273 6.76281 13.6088 7.09859 14.023 7.09859H19.0156C19.2145 7.09859 19.4053 7.01956 19.546 6.87889C19.6866 6.73821 19.7656 6.54742 19.7656 6.34849V1.35599C19.7656 0.941777 19.4298 0.605991 19.0156 0.605991C18.6014 0.605991 18.2656 0.941777 18.2656 1.35599V4.53616L16.3653 2.63586C15.2841 1.55278 13.9116 0.73095 12.3292 0.306948C7.52803 -0.979528 2.593 1.86971 1.30653 6.67091C1.19932 7.07101 1.43676 7.48226 1.83686 7.58947C2.23696 7.69667 2.64821 7.45924 2.75541 7.05914ZM18.1629 10.4111C17.7628 10.3039 17.3515 10.5413 17.2443 10.9414C16.1722 14.9424 12.0597 17.3168 8.05871 16.2447C6.73774 15.8908 5.59594 15.2066 4.69609 14.3051L2.7942 12.4019H5.97702C6.39123 12.4019 6.72702 12.0661 6.72702 11.6519C6.72702 11.2377 6.39123 10.9019 5.97702 10.9019L0.984375 10.9019C0.570162 10.9019 0.234375 11.2377 0.234375 11.6519V16.6446C0.234375 17.0588 0.570162 17.3946 0.984375 17.3946C1.39859 17.3946 1.73438 17.0588 1.73438 16.6446V13.4634L3.63444 15.3647C4.71564 16.4479 6.08796 17.2696 7.67048 17.6936C12.4717 18.9801 17.4067 16.1308 18.6932 11.3297C18.8004 10.9296 18.563 10.5183 18.1629 10.4111Z"
};

interface IProps {
  size?: number;
  onClick?: () => void;
  className?: string;
  viewbox?: string;
  fill?: string;
  stroke?: string;
  name: string;
}

const Icon = ({size = 16, onClick, className, viewbox,  fill, stroke, name}: IProps) => {
  return (
    <svg
      preserveAspectRatio="xMinYMin"
      onClick={onClick}
      className={cn(className)}
      width={size}
      height={size}
      viewBox={viewbox ?? "0 0 22 20"}
      fill={fill}
      stroke={stroke}
    >
      <path d={icons[name]}></path>
    </svg>
  );
};

export default Icon;
