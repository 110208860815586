export enum InvitationStatus {
  pending = 'pending',
  accepted = 'accepted',
  expired = 'expired',
}

export enum StripeStatus {
  inactive = "inactive",
  active = "active"
}

export enum Environment {
  production = "production",
  qa = "qa",
  development = "development"
}