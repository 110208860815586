import {
  Admin,
  CareGroup,
  IPhoneNumber,
  Member,
  Subscription
} from "../../interfaces/app.interface";
import { RootState } from "../index.store";
import { auth } from "../../services/firebase";
import axios from "axios";
import {
  Dispatch,
  PayloadAction,
  createSlice,
} from "@reduxjs/toolkit";
import { authenticationSlice } from "./auth.slice";

const endpoint = process.env.REACT_APP_CARL_ENDPOINT;

const initialState: Admin = {
  profile_id: null,
  intercom_user_hash: null,
  caregroups: [],
  selected_care_group: null,
  actionCompleteNotification: null,
  subscriptions: undefined,
  loading: false,
};

interface IAddReceiver {
  name: string;
  gender?: string;
  pronoun?: string;
  phoneNumber?: IPhoneNumber;
  imageFile?: File | null;
}

interface IUpdateReceiverProfile {
  id: string;
  name?: string;
  imageFile?: string | null;
  phoneNumber?: IPhoneNumber | null;
  pronoun?: string | null;
  isUpdateProfileScreen?: boolean;
}

interface IRegreshAdminsList {
  admins: Member[];
  members: Member[];
  owners: Member[];
  careGroupId: string;
}

export const adminSlice = createSlice({
  name: "admin",
  initialState,
  extraReducers: (builder) => {
    builder.addCase(
      authenticationSlice.actions.onAddCaregiverResponse,
      (_, action) => {
        return {
          ...action.payload.data,
          actionCompleteNotification: null,
          loading: false,
          error: null,
        };
      }
    );
    builder.addCase(authenticationSlice.actions.onLogOut, (_) => {
      return initialState;
    });
  },
  reducers: {
    onSuccessfulGetAdmin: (_, action: PayloadAction<Admin>) => {
      const careReceiverExist = action.payload.caregroups
        .map((careGroup) => careGroup._id)
        .includes(localStorage.getItem("selectedUser") ?? "");

      const selectedSection =
        localStorage.getItem("section") !== null
          ? localStorage.getItem("section")
          : action.payload.caregroups[0] !== null
          ? "care-team"
          : null;

      if (selectedSection != null)
        localStorage.setItem("section", selectedSection);

      if (action.payload.caregroups.length > 0 && !careReceiverExist)
        localStorage.setItem("selectedUser", action.payload.caregroups[0]._id);

      return {
        email: action.payload.email,
        image_uri: action.payload.image_uri,
        name: action.payload.name?.trim(),
        profile_id: action.payload.profile_id,
        intercom_user_hash: action.payload.intercom_user_hash,
        sendbird_id: action.payload.sendbird_id,
        first_login: action.payload.first_login,
        caregroups: action.payload.caregroups,
        subscriptions: action.payload.subscriptions,
        partnerId: action.payload.partnerId,
        selected_care_group:
          !!localStorage.getItem("selectedUser") &&
          action.payload.caregroups.length > 0 &&
          careReceiverExist
            ? action.payload.caregroups.find(
                (careGroup) =>
                  careGroup._id === localStorage.getItem("selectedUser")
              )
            : action.payload.caregroups[0],
        selected_section: selectedSection,
        actionCompleteNotification: null,
        loading: false,
        error: null,
      };
    },
    onSuccessfulInviteCaregiver: (state, action) => {
      const updatedCareGroups = state.caregroups.map((careGroup) =>
        careGroup._id === state.selected_care_group?._id
          ? {
              ...careGroup,
              members: [...careGroup.members, action.payload.caregiver],
            }
          : careGroup
      );
      const updatedSelectedCareGroup =
        state.selected_care_group !== null
          ? ({
              ...state.selected_care_group,
              members: [
                ...(state.selected_care_group?.members ?? []),
                action.payload.caregiver,
              ],
            } as CareGroup)
          : null;

      return {
        ...state,
        caregroups: updatedCareGroups,
        selected_care_group: updatedSelectedCareGroup,
        actionCompleteNotification: "Invite sent",
        loading: false,
      };
    },
    onSuccessfulAcceptInvite: (state, action: PayloadAction<CareGroup>) => {
      localStorage.setItem("selectedUser", action.payload._id);
      localStorage.setItem("section", "care-team");

      if (
        !state.caregroups
          .map((careGroup) => careGroup._id)
          .includes(action.payload._id)
      ) {
        return {
          ...state,
          caregroups: [...state.caregroups, action.payload],
          selected_care_group: action.payload,
          selected_section: "care-team",
          actionCompleteNotification: "Invitation accepted",
          loading: false,
        };
      }
    },
    onSuccessfulResendInvite: (state, action: PayloadAction<string>) => {
      return {
        ...state,
        actionCompleteNotification: `Resent invite to ${action.payload}`,
        loading: false,
      };
    },
    onSuccessfulDeleteInvite: (state, action: PayloadAction<string>) => {
      const updatedCareGroups =
        state.email === action.payload
          ? state.caregroups.filter(
              (careGroup) => careGroup._id !== state.selected_care_group?._id
            )
          : state.caregroups.map((careGroup) =>
              careGroup._id === state.selected_care_group?._id
                ? {
                    ...careGroup,
                    members: careGroup.members.filter(
                      (member) => member.email !== action.payload
                    ),
                  }
                : careGroup
            );

      if (state.caregroups.length === 1) {
        localStorage.removeItem("selectedUser");
      }
      if (state.caregroups.length > 1 && state.selected_care_group !== null) {
        localStorage.setItem(
          "selectedUser",
          updatedCareGroups[updatedCareGroups.length - 1]?._id as string
        );
        localStorage.setItem("section", "care-team");
      }

      const updatedSelectedCareGroup =
        state.email === action.payload
          ? state.caregroups.length > 1
            ? updatedCareGroups[updatedCareGroups.length - 1]
            : null
          : ({
              ...state.selected_care_group,
              members: state.selected_care_group!.members.filter(
                (member) => member.email !== action.payload
              ),
            } as CareGroup);

      return {
        ...state,
        caregroups: updatedCareGroups,
        selected_care_group: updatedSelectedCareGroup,
        actionCompleteNotification: "Caregiver deleted successfully",
        loading: false,
      };
    },
    onSuccessfulAddCareReceiver: (state, action: PayloadAction<CareGroup>) => {
      localStorage.setItem("selectedUser", action.payload._id as string);
      localStorage.setItem("section", "care-team");

      return {
        ...state,
        selected_care_group: action.payload,
        caregroups: [...state.caregroups, action.payload],
        actionCompleteNotification: "Care receiver added successfully",
        loading: false,
      };
    },
    onSuccessfulDeleteCareReceiver: (state, action) => {
      const updatedCareGroups = state.caregroups.filter(
        (caregroups) => caregroups.care_receivers[0].id !== action.payload.id
      );
      if (state.caregroups.length === 1) {
        localStorage.removeItem("selectedUser");
      }
      if (state.caregroups.length > 1 && state.selected_care_group !== null) {
        localStorage.setItem(
          "selectedUser",
          updatedCareGroups[updatedCareGroups.length - 1]?._id as string
        );
      }

      localStorage.setItem("section", "care-team");

      return {
        ...state,
        caregroups: updatedCareGroups,
        selected_section: "care-team",
        selected_care_group:
          state.caregroups.length > 1
            ? updatedCareGroups[updatedCareGroups.length - 1]
            : null,
        actionCompleteNotification: "Care receiver deleted",
        loading: false,
      };
    },
    onSuccessfulReceiverProfileUpdate: (state, action) => {
      const updatedCareGroups = state.caregroups.map((careGroup) =>
        careGroup._id === state.selected_care_group?._id
          ? {
              ...careGroup,
              care_receivers: careGroup.care_receivers.map((careReceiver) =>
                careReceiver.id === action.payload.id
                  ? {
                      ...careReceiver,
                      name: action.payload.name.trim(),
                      image_uri: action.payload.image_uri,
                      pronoun: action.payload.pronoun,
                      phone_number: action.payload.phone_number,
                    }
                  : careReceiver
              ),
            }
          : careGroup
      );

      const updatedSelectedCareGroup =
        state.selected_care_group !== null
          ? updatedCareGroups.find(
              (careGroup) => careGroup._id === state.selected_care_group?._id
            )
          : null;

      return {
        ...state,
        caregroups: updatedCareGroups,
        selected_care_group: updatedSelectedCareGroup,
        ...(action.payload?.isUpdateProfileScreen && {
          actionCompleteNotification: "Profile uploaded",
        }),
        loading: false,
      };
    },
    onSuccessfulAdminUploadPhoto: (state, action) => {
      const updatedCareGroups = state.caregroups.map((careGroup) =>
        careGroup._id === state.selected_care_group?._id
          ? {
              ...careGroup,
              members: careGroup.members.map((member) =>
                member.profile_id === action.payload.profile_id
                  ? action.payload
                  : member
              ),
            }
          : careGroup
      );

      const updatedSelectedCareGroup =
        state.selected_care_group != null
          ? updatedCareGroups.find(
              (careGroup) => careGroup._id === state.selected_care_group?._id
            )
          : null;

      return {
        ...state,
        caregroups: updatedCareGroups,
        selected_care_group: updatedSelectedCareGroup,
        actionCompleteNotification: "Image uploaded successfully",
        loading: false,
      };
    },

    onSuccessfulAdminUpdateProfile: (state, action) => {
      return {
        ...state,
        first_login: false,
        email: action.payload.email.trim(),
        name: action.payload.name.trim(),
        image_uri: action.payload.image_uri,
        phone_number: action.payload.phone_number,
      };
    },

    onSuccessfulTransferAdminRole: (
      state,
      action: PayloadAction<{ caregroupId: string; admins: Member[] }>
    ) => {
      const updatedCareGroups = state.caregroups.map((careGroup) =>
        careGroup._id === action.payload.caregroupId
          ? { ...careGroup, admins: action.payload.admins }
          : careGroup
      ) as CareGroup[];

      const updatedSelectedCareGroup = {
        ...state.selected_care_group,
        admins: action.payload.admins,
      } as CareGroup;

      return {
        ...state,
        selected_care_group: updatedSelectedCareGroup,
        caregroups: updatedCareGroups,
        actionCompleteNotification: "Admin role transferred successfully",
      };
    },

    onSuccessfulRefreshAdminsList: (
      state,
      action: PayloadAction<IRegreshAdminsList>
    ) => {
      const updatedSelectedCareGroup = {
        ...state.selected_care_group,
        admins: action.payload.admins,
        owners: action.payload.owners,
        members: action.payload.members,
      } as CareGroup;

      const updateCareGroups = state.caregroups.map((careGroup) =>
        careGroup._id == action.payload.careGroupId
          ? updatedSelectedCareGroup
          : careGroup
      );

      return {
        ...state,
        caregroups: updateCareGroups,
        selected_care_group: updatedSelectedCareGroup,
        loading: false,
        actionCompleteNotification: "Caregivers list refreshed successfully",
      };
    },

    onSuccessfulChangeCareGroup: (state, action) => {
      if (state.selected_care_group?._id !== action.payload)
        return {
          ...state,
          selected_care_group: state.caregroups.find(
            (careGroup, index) => careGroup._id === action.payload
          ),
        };
    },
    onSuccessfulChangeSection: (state, action) => {
      if (state.selected_section !== action.payload)
        localStorage.setItem("section", action.payload);
      if (state.selected_care_group !== null)
        localStorage.setItem(
          "selectedUser",
          state.selected_care_group?._id as string
        );

      return {
        ...state,
        selected_section: action.payload,
      };
    },
    removeAdminData: () => {
      localStorage.removeItem("selectedUser");
      return {
        ...initialState,
      };
    },
    onError: (state, action: PayloadAction<string>) => {
      return {
        ...state,
        error: action.payload,
        loading: false,
      };
    },
    resetActionCompleteNotification: (state) => ({
      ...state,
      actionCompleteNotification: null,
    }),
    resetError: (state) => ({
      ...state,
      error: null,
    }),
    toggleLoading: (state) => ({
      ...state,
      loading: true,
    }),
  },
});

export const getAdmin = () => async (dispatch: Dispatch) => {
  try {
    dispatch(toggleLoading());
    const idToken = await auth.currentUser?.getIdToken(true);

    const result = await axios.get(`${endpoint}/admin`, {
      headers: {
        Authorization: `Bearer ${idToken}`,
      },
    });

    dispatch(onSuccessfulGetAdmin(result.data));
  } catch (error: any) {
    console.log("ERROR getting caregiver info", error);
    dispatch(onError(error?.response?.data?.error || error?.message));
  }
};

export const inviteCaregiver =
  (caregroupId: string, email: string, name: string) =>
  async (dispatch: Dispatch) => {
    try {
      const idToken = await auth.currentUser?.getIdToken(true);
      const result = await axios.post(
        `${endpoint}/caregroup/invite/add`,
        {
          caregroupId: caregroupId,
          email,
          name,
        },
        {
          headers: {
            Authorization: `Bearer ${idToken}`,
          },
        }
      );

      dispatch(
        onSuccessfulInviteCaregiver({
          caregiver: result.data,
          caregroupId: caregroupId,
        })
      );
    } catch (error: any) {
      console.error("ERROR sending caregiver invite", error);
      dispatch(onError(error?.response?.data?.error || error?.message));
    }
  };

export const resendInviteCaregiver =
  (careGroupId: string, email: string) => async (dispatch: Dispatch) => {
    try {
      const idToken = await auth.currentUser?.getIdToken(true);
      await axios.put(
        `${endpoint}/caregroup/invite/resend`,
        {
          caregroupId: careGroupId,
          email: email,
        },
        {
          headers: {
            Authorization: `Bearer ${idToken}`,
          },
        }
      );

      dispatch(onSuccessfulResendInvite(email));
    } catch (error: any) {
      console.error("ERROR resending caregiver invite", error?.data);
      dispatch(onError(error?.response?.data?.error || error?.message));
    }
  };

export const acceptInviteCaregiver =
  (inviteCode: string) => async (dispatch: Dispatch) => {
    try {
      const idToken = await auth.currentUser?.getIdToken(true);
      const result = await axios.put(
        `${endpoint}/caregroup/invite/accept`,
        {
          inviteCode: inviteCode,
        },
        {
          headers: {
            Authorization: `Bearer ${idToken}`,
          },
        }
      );

      dispatch(onSuccessfulAcceptInvite(result.data));
    } catch (error: any) {
      console.error("ERROR accepting caregiver invite", error);
      dispatch(onError(error?.response?.data?.error || error?.message));
      if (
        error?.response?.data?.error ===
        "The invitation is already accepted or expired"
      ) {
        localStorage.removeItem("inviteCode");
      }
    }
  };

export const deleteInviteCaregiver =
  (careGroupId: string, caregiverEmail: string) =>
  async (dispatch: Dispatch) => {
    try {
      const idToken = await auth.currentUser?.getIdToken(true);
      const result = await axios.put(
        `${endpoint}/caregroup/invite/delete`,
        {
          caregroupId: careGroupId,
          email: caregiverEmail,
        },
        {
          headers: {
            Authorization: `Bearer ${idToken}`,
          },
        }
      );

      dispatch(onSuccessfulDeleteInvite(result.data["email"]));
    } catch (error: any) {
      console.error("ERROR deleting caregiver invite", error);
      dispatch(onError(error?.response?.data?.error || error?.message));
    }
  };

export const addCareReceiver =
  ({ name }: IAddReceiver) =>
  async (dispatch: Dispatch) => {
    try {
      dispatch(toggleLoading());
      const idToken = await auth.currentUser?.getIdToken(true);
      const addCareReceiverResult = await axios.post(
        `${endpoint}/care-receiver/add`,
        {
          name: name.trim(),
        },
        { headers: { Authorization: `Bearer ${idToken}` } }
      );

      dispatch(onSuccessfulAddCareReceiver({ ...addCareReceiverResult.data }));
    } catch (error: any) {
      console.log(error.message);
      dispatch(onError(error?.response?.data?.error || error?.message));
    }
  };

export const deleteCareReceiver =
  (id: string) => async (dispatch: Dispatch) => {
    try {
      dispatch(toggleLoading());
      const idToken = await auth.currentUser?.getIdToken(true);
      const result = await axios.delete(`${endpoint}/care-receiver/${id}`, {
        headers: { Authorization: `Bearer ${idToken}` },
      });

      dispatch(onSuccessfulDeleteCareReceiver(result.data));
    } catch (error: any) {
      console.log(error.message);
      dispatch(onError(error?.response?.data?.error || error?.message));
    }
  };

export const updateProfileReceiver =
  ({
    id,
    imageFile,
    name,
    phoneNumber,
    pronoun,
    isUpdateProfileScreen,
  }: IUpdateReceiverProfile) =>
  async (dispatch: Dispatch) => {
    try {
      if (
        (imageFile == null ||
          !!imageFile ||
          !!name ||
          !!phoneNumber ||
          !!pronoun) &&
        !!id
      ) {
        const idToken = await auth.currentUser?.getIdToken(true);

        const result = await axios.patch(
          `${endpoint}/care-receiver/update-profile/${id}`,
          {
            ...(name && { name: name.trim() }),
            ...(phoneNumber && { phone_number: phoneNumber }),
            ...((!!imageFile || imageFile === null) && {
              image_uri: imageFile,
            }),
            ...(pronoun && { pronoun: pronoun }),
          },
          {
            headers: {
              Authorization: `Bearer ${idToken}`,
            },
          }
        );
        dispatch(
          onSuccessfulReceiverProfileUpdate({
            ...result.data,
            isUpdateProfileScreen,
          })
        );
      }
    } catch (error: any) {
      console.log(error.message);
      dispatch(onError(error?.response?.data?.error || error?.message));
    }
  };

interface IUpdateAdminProfile {
  id: string;
  name?: string;
  email: string;
  imagePath?: null | string;
  phoneNumber?: IPhoneNumber | null;
}

export const updateProfileAdmin =
  ({ id, name, email, imagePath, phoneNumber }: IUpdateAdminProfile) =>
  async (dispatch: Dispatch) => {
    try {
      const idToken = await auth.currentUser?.getIdToken(true);
      const result = await axios.patch(
        `${endpoint}/admin/update-profile/${id}`,
        {
          name: name?.trim(),
          email: email.trim(),
          image_uri: imagePath,
          ...(phoneNumber && { phone_number: phoneNumber }),
        },
        {
          headers: {
            Authorization: `Bearer ${idToken}`,
          },
        }
      );

      dispatch(onSuccessfulAdminUpdateProfile(result.data));
    } catch (error: any) {
      console.log(error.message);
      dispatch(onError(error?.response?.data?.error || error?.message));
    }
  };

export const uploadPhotoAdmin =
  (id: string, careGroupId: string, image: string) =>
  async (dispatch: Dispatch) => {
    try {
      const idToken = await auth.currentUser?.getIdToken(true);
      const result = await axios.patch(
        `${endpoint}/admin/update-profile/${id}`,
        {
          image_uri: image,
          caregroupId: careGroupId,
        },
        {
          headers: {
            Authorization: `Bearer ${idToken}`,
          },
        }
      );
      dispatch(onSuccessfulAdminUploadPhoto(result.data));
    } catch (error: any) {
      console.log(error.message);
      dispatch(onError(error?.response?.data?.error || error?.message));
    }
  };

export const transferAdminRole =
  (caregroupId: string, caregiverId: string) => async (dispatch: Dispatch) => {
    try {
      const idToken = await auth.currentUser?.getIdToken(true);
      const result = await axios.put(
        `${endpoint}/caregroup/transfer`,
        {
          caregroupId: caregroupId,
          caregiverId: caregiverId,
        },
        {
          headers: {
            Authorization: `Bearer ${idToken}`,
          },
        }
      );
      dispatch(
        onSuccessfulTransferAdminRole({
          caregroupId: caregroupId,
          admins: result.data.admins,
        })
      );
    } catch (error: any) {
      console.log(error.message);
      dispatch(onError(error?.response?.data?.error || error?.message));
    }
  };

export const refreshAdminList =
  (careGroupId: string) => async (dispatch: Dispatch) => {
    try {
      const idToken = await auth.currentUser?.getIdToken(true);
      const result = await axios.get(
        `${endpoint}/refresh/care-givers/${careGroupId}`,
        {
          headers: {
            Authorization: `Bearer ${idToken}`,
          },
        }
      );

      dispatch(
        onSuccessfulRefreshAdminsList({
          ...result.data,
          careGroupId: careGroupId,
        })
      );
    } catch (error: any) {
      console.log(error.message);
      dispatch(onError(error?.response?.data?.error || error?.message));
    }
  };

export const changeSelectedCareTeam = (id: string) => (dispatch: Dispatch) => {
  dispatch(onSuccessfulChangeCareGroup(id));
};

export const changeSection = (section: string) => (dispatch: Dispatch) => {
  dispatch(onSuccessfulChangeSection(section));
};

export const removeAdmin = () => (dispatch: Dispatch) => {
  dispatch(removeAdminData());
};

export const createPortal = (id: string) => async (dispatch: Dispatch) => {
  try {
    dispatch(toggleLoading());
    const idToken = await auth.currentUser?.getIdToken();
    const result = await axios.post(
      `${endpoint}/stripe/create-portal/${id}`,
      {},
      {
        headers: {
          Authorization: `Bearer ${idToken}`,
        },
      }
    );

    window.location.href = result.data.url;
  } catch (error: any) {
    console.log(error.message);
    dispatch(toggleLoading());
    dispatch(onError(error?.response?.data?.error || error?.message));
  }
};

export const getStripePortal = (id: string) => async (dispatch: Dispatch) => {
  try {
    dispatch(toggleLoading());
    const idToken = await auth.currentUser?.getIdToken();
    const result = await axios.get(
      `${endpoint}/stripe/portal/${id}`,
      {
        headers: {
          Authorization: `Bearer ${idToken}`,
        },
      }
    );

    window.location.href = result.data.url;
  } catch (error: any) {
    console.log(error.message);
    dispatch(toggleLoading());
    dispatch(onError(error?.response?.data?.error || error?.message));
  }
};

export const {
  onSuccessfulGetAdmin,
  onSuccessfulInviteCaregiver,
  onSuccessfulResendInvite,
  onSuccessfulAcceptInvite,
  onSuccessfulDeleteInvite,
  onSuccessfulAddCareReceiver,
  onSuccessfulDeleteCareReceiver,
  onSuccessfulReceiverProfileUpdate,
  onSuccessfulAdminUploadPhoto,
  onSuccessfulAdminUpdateProfile,
  onSuccessfulChangeCareGroup,
  onSuccessfulChangeSection,
  onSuccessfulTransferAdminRole,
  onSuccessfulRefreshAdminsList,
  removeAdminData,
  onError,
  resetError,
  resetActionCompleteNotification,
  toggleLoading,
} = adminSlice.actions;
export const selectAuthentication = (state: RootState) => state.admin;
export const adminReducer = adminSlice.reducer;
