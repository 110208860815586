import { Dispatch, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { CareGroup, IPhoneNumber } from "../../interfaces/app.interface";
import { RootState } from "../index.store";
import { adminSlice } from "../slices/admin.slice";

interface ICreateAccount {
  name: string | null;
  email: string | null;
  phoneNumber: IPhoneNumber | null;
  pronoun: string | null;
  section: AddLovedOneSections
  imagePath: string | null;
  careReceiverId: string | null;
  careGroupId: string | null;
  isOnboarding: boolean;
}

interface ISetValue {
  value: string | null | IPhoneNumber | File;
  field: string;
}

export enum AddLovedOneSections {
  introduction = "introduction",
  name = "name",
  picture = "picture",
  pronoun = "pronoun",
  phoneNumber= "phoneNumber",
  ending= "ending"
}

const initialState: ICreateAccount = {
  name: null,
  email: null,
  phoneNumber: null,
  pronoun: null,
  imagePath: null,
  section: AddLovedOneSections.introduction,
  careReceiverId: null,
  careGroupId: null,
  isOnboarding: false
};

export const AddLovedOneSlice = createSlice({
  name: "addLovedOne",
  initialState,
  extraReducers:(builder) => {
    builder.addCase(adminSlice.actions.onSuccessfulAddCareReceiver, (state, action: PayloadAction<CareGroup>) => {
      return {
        ...state,
        careReceiverId: action.payload.care_receivers[0].id,
        careGroupId: action.payload._id
      }
    });
  },
  reducers: {
    setValue: (state, action: PayloadAction<ISetValue>) => {
      return {
        ...state,
        [action.payload.field]: action.payload.value,
      };
    },
    setAddLovedOneDataToInitial: (_) => {
      return {
        ...initialState
      }
    }
  },
});

export const setAddLovedOneValues =
  ({ value, field }: ISetValue) =>
  (dispatch: Dispatch) => {
    dispatch(setValue({ value, field }));
  };

export const resetAddLovedOne = ()  => (dispatch: Dispatch) => {
  dispatch(setAddLovedOneDataToInitial());
}

export const { setValue, setAddLovedOneDataToInitial } = AddLovedOneSlice.actions;
export const selectLovedOneSlice = (state: RootState) => state.addLovedOne;
export const addLovedOneReducer = AddLovedOneSlice.reducer;
