import { useEffect, useState } from "react";
import styles from "./Sidebar.module.sass";
import cn from "classnames";
import Icon from "../Icon";
import packageJson from "../../../package.json";

import { useAppDispatch, useAppSelector } from "../../store/redux.hooks";
import { resetAddLovedOne } from "../../store/slices/addLovedOne.slice";

import SideBarDropDown from "../SideBarDropDown";
import { logOutUser } from "../../store/slices/auth.slice";
import { CareGroup } from "../../interfaces/app.interface";
import OutsideClickHandler from "react-outside-click-handler";
import { useNavigate } from "react-router-dom";
import { Environment } from "../../interfaces/app.enums";
import Modal from "../Modal";
import Terms from "../../pages/Terms/Terms";

interface IProps {
  className?: string;
  onClose: () => void;
}

export interface ISidebarItem {
  title: string;
  id: string;
  icon: string;
  imageUrl: string | null;
  dropdown: IDropdown[];
}

export interface IDropdown {
  title: string;
  name: string;
  url: string;
  data: CareGroup;
}

const Sidebar = ({ className, onClose }: IProps) => {
  const careGroups = useAppSelector((state) => state.admin.caregroups);
  const careGiverEmail = useAppSelector((state) => state.admin.email);
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const [visible, setVisible] = useState(false);
  
  const [showPrivacy, setShowPrivacy] = useState(false);
  const [showTerms, setShowTerms] = useState(false);

  const [navigation, setNavigation] = useState<ISidebarItem[]>([]);

  // useEffect(() => {
  //   setNavigation(() =>
  //     careGroups?.map<ISidebarItem>((careGroup: CareGroup) => ({
  //       title: careGroup.care_receivers[0].name,
  //       id: careGroup._id as string,
  //       icon: "profile-circle",
  //       imageUrl: careGroup.care_receivers[0].image_uri ?? null,
  //       dropdown: [
  //         {
  //           title: "Care Team",
  //           name: "care-team",
  //           url: `/care-team/${careGroup._id}`,
  //           data: careGroup,
  //         },
  //         {
  //           title: "Profile",
  //           name: "profile",
  //           url: `/profile/${careGroup._id}`,
  //           data: careGroup,
  //         },
  //         {
  //           title: "Billing",
  //           name: "billing",
  //           url: `/billing/${careGroup._id}`,
  //           data: careGroup,
  //         },
  //       ],
  //     }))
  //   );
  // }, [careGroups, careGroups?.length]);

  return (
    <>
      <div
        className={cn(styles.sidebar, className, {
          [styles.active]: visible,
        })}
      >
        <div className={styles.sideMenuTop}>
          {/* <button className={styles.close} onClick={onClose}>
            <Icon name="close" size={30} viewbox={"-2 -2 18 20"} />
          </button> */}
          <Icon
            className={styles.logo}
            name="carlLogo"
            viewbox={"0 0 123 47"}
            fill="#002E1C"
            size={100}
          />
        
        </div>
        <p>{careGiverEmail}</p>
        <div className={styles.menu}>
          {/* <OutsideClickHandler onOutsideClick={() => setVisible(false)}>
            <div className={styles.careReceiversList}>
              {navigation?.map((item) => (
                <SideBarDropDown
                  key={item?.id}
                  visibleSidebar={visible}
                  setValue={setVisible}
                  item={item}
                  onClose={onClose}
                />
              ))}
              {process.env.REACT_APP_ENVIRONMENT != Environment.production && (
                <div
                  className={cn(styles.item, { [styles.wide]: visible })}
                  onClick={() => {
                    // dispatch(resetAddLovedOne());
                    navigate("/purchase-survey", { replace: true });
                  }}
                >
                  <Icon
                    name={"plus-circle"}
                    size={20}
                    viewbox="1.1 1.1 13.5 13.5"
                  />
                  <p>Add a care receiver</p>
                </div>
              )}
            </div>
          </OutsideClickHandler> */}
        </div> 

        <Modal
          outerClassName={styles.modalOuter}
          title={"Privacy Policy"}       
          visible={showPrivacy}
          hasSeparator={false}
          onClose={() => setShowPrivacy(false)}
          onFinish={()=>{}}        
          secundaryBtnStyle={styles.removeButton}        
          onSecundaryBtnClick={()=>{}}>            
          <Terms termlyId="9ea49ad0-cc46-435f-9025-3829ab013778" />          
        </Modal> 

        <Modal
          outerClassName={styles.modalOuter}
          title={"Terms and Conditions"}       
          visible={showTerms}
          hasSeparator={false}
          onClose={() => setShowTerms(false)}
          onFinish={()=>{}}        
          secundaryBtnStyle={styles.removeButton}        
          onSecundaryBtnClick={()=>{}}>                        
          <Terms termlyId="bc7505f5-5d99-4bf7-aa93-95e263f79488"/>          
        </Modal> 

        <div className={styles.terms}>
          <button className={styles.btn} onClick={() => { setShowPrivacy(true)}}>
              Privacy Policy
          </button>
          <button className={styles.btn} onClick={() => { setShowTerms(true)}}>
              Terms and Conditions
          </button>
          
        </div>
        <div
          className={cn(styles.item, styles.logout)}
          onClick={() => dispatch(logOutUser())}
        >
          <Icon
            name={"logout"}
            size={20}
            fill="#6F767E"
            viewbox="-2 -2 12 22"
          />
          <p>Log out</p>
        </div>
        <p
          className={cn(styles.appVersion, { [styles.wide]: visible })}
        >{`v${packageJson.version}`}</p>
      </div>
    </>
  );
};

export default Sidebar;
