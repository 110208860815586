export const pronounOptions = [
  "He/Him",
  "She/Her",
  "They/Them",
  "Ze/Zir",
  "Xie/Hir",
  "Other",
];

export const imageKitAcceptedTypes =
  "image/jpeg, image/png, image/svg, image/webp, image/heic, image/heif, image/psd";
export const imageKitAcceptedTypesArray = [
  "image/jpeg",
  "image/png",
  "image/svg",
  "image/webp",
  "image/heic",
  "image/heif",
  "image/psd",
];

export const testflightLink = "https://testflight.apple.com/join/bic7fydo";
