import React, { useCallback, useEffect } from "react";
import { createPortal } from "react-dom";
import { disableBodyScroll, clearAllBodyScrollLocks } from "body-scroll-lock";
import OutsideClickHandler from "react-outside-click-handler";
import cn from "classnames";
import styles from "./Modal.module.sass";
import Card from "../Card";
import Button from "../Button/Button";
import CloseButton from "../CloseButton/CloseButton";

interface IProps {
  title?: string;
  subtitle?: string;
  primaryDescription?: string | null;
  descriptionStyle?: string;
  secundaryDescription?: string;
  primaryBtnLabel?: string | null;
  primaryBtnStyle?: string;
  outerClassName?: string;
  visible?: boolean;
  closeButton?: boolean; 
  onClose?: () => void;
  onFinish: (() => void) | null;
  children?: React.ReactNode;
  hasSeparator?: boolean;
  secundaryBtnLabel?: string;
  secundaryBtnStyle?: string;
  onSecundaryBtnClick?: () => void;
}

const Modal = ({
  title,
  outerClassName,
  visible,
  onClose,
  closeButton = true,
  children,
  onFinish,
  primaryBtnLabel,
  primaryBtnStyle,
  subtitle,
  primaryDescription,
  secundaryDescription,
  descriptionStyle,
  hasSeparator = true,
  secundaryBtnLabel,
  secundaryBtnStyle,
  onSecundaryBtnClick,
}: IProps) => {
  const escFunction = useCallback(
    (e: { keyCode: number }) => {
      if (e.keyCode === 27 && onClose) {
        onClose();
      }
    },
    [onClose]
  );

  useEffect(() => {
    document.addEventListener("keydown", escFunction, false);
    return () => {
      document.removeEventListener("keydown", escFunction, false);
    };
  }, [escFunction]);

  useEffect(() => {
    if (visible) {
      const target = document.querySelector("#modal");
      disableBodyScroll(target);
    } else {
      clearAllBodyScrollLocks();
    }
  }, [visible]);

  return createPortal(
    visible && (
      <div id="modal" className={styles.modal}>
        <div className={cn(styles.outer, outerClassName)}>
          {/* <OutsideClickHandler onOutsideClick={onClose}> */}
            <Card
              isModal={true}
              classTitle={cn(styles.modalCardTitle)}
              classCardHead={cn(styles.modalCard)}
              title={title}
              subtitle={subtitle}
              head={ closeButton ?<CloseButton onClick={onClose} /> : undefined}
            >
              <div className={styles.modalContentContainer}>
                {hasSeparator && <div className={styles.separator} />}
                {primaryDescription && (
                  <p className={cn(styles.description, descriptionStyle)}>{primaryDescription}</p>
                )}
                {secundaryDescription && (
                  <p className={cn(styles.description, descriptionStyle)}>{secundaryDescription}</p>
                )}
                <div className={styles.modalBody}>{children}</div>
                <div className={styles.buttonsContainer}>
                  {secundaryBtnLabel && (
                    <Button
                      onClick={() =>
                        onSecundaryBtnClick && onSecundaryBtnClick()
                      }
                      btnClass={cn(styles.removeBtn, secundaryBtnStyle)}
                    >
                      {secundaryBtnLabel}
                    </Button>
                  )}
                  {primaryBtnLabel && (
                    <Button
                      onClick={() => onFinish && onFinish()}
                      btnClass={cn(styles.modalBtn, primaryBtnStyle, {[styles.disable] : onFinish === null})}
                    >
                      {primaryBtnLabel}
                    </Button>
                  )}
                </div>
              </div>
            </Card>
          {/* </OutsideClickHandler> */}
        </div>
      </div>
    ),
    document.body
  );
};

export default Modal;
