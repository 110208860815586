import { useState } from "react";
import cn from "classnames";
import { useAppDispatch, useAppSelector } from "../../store/redux.hooks";
import { useWindowWidth } from "../../hooks/useWindowWidth";

import { Member } from "../../interfaces/app.interface";
import { InvitationStatus, StripeStatus } from "../../interfaces/app.enums";
import {
  resendInviteCaregiver,
  uploadPhotoAdmin,
  deleteInviteCaregiver,
  transferAdminRole,
} from "../../store/slices/admin.slice";

import Dropdown, { IDropdownOptions } from "../Dropdown";
import MenuDropDown from "../MenuDropDown/MenuDropDown";
import ImagePicker from "../ImagePicker/ImagePicker";
import { ImageKit } from "../Image";
import Modal from "../Modal";

import styles from "./Table.module.sass";
import { nameToPossessive } from "../../utils/helper.function";

interface IProps {
  data: Member[];
  adminsIds: string[];
  ownersIds: string[];
  columns: string[];
  userTable?: string;
}

const Table = ({ data, adminsIds, ownersIds, columns, userTable }: IProps) => {
  const [modalActive, setModalActive] = useState(false);
  const [modalType, setModalType] = useState<string | null>();
  const [selectedUser, setSelectedUser] = useState<Member | null>(null);
  const [targetedProfileId, setTargetedProfileId] = useState<string | null>(
    null
  );
  const [image, setImage] = useState<string | null>();
  const [isUploading, setIsUploading] = useState(false);
  const [role, setRole] = useState<string>("Family or friend");
  const width = useWindowWidth();
  const isMobileVersion = width < 640;
  const dispatch = useAppDispatch();

  const currentUser = useAppSelector((state) => state.admin);
  const selectedCareGroup = useAppSelector(
    (state) => state.admin.selected_care_group
  );
  const userIsInactive = useAppSelector(
    (state) =>
      state.admin.selected_care_group?.care_receivers[0].status ===
      StripeStatus.inactive
  );

  const getDropdownOptions = (
    invitationStatus: string,
    profileId: string | null
  ) => {
    const memberOptions: IDropdownOptions[] = [];
    if (
      adminsIds?.includes(currentUser.profile_id as string) &&
      profileId !== currentUser.profile_id &&
      (invitationStatus === InvitationStatus.accepted ||
        ownersIds.includes(profileId as string))
    ) {
      memberOptions.push({ label: "Make admin", icon: "profile-circle" });
    }
    if (
      adminsIds?.includes(currentUser.profile_id as string) &&
      (invitationStatus === InvitationStatus.pending ||
        invitationStatus === "failed")
    ) {
      memberOptions.push({ label: "Resend invite", icon: "sendInvite" });
    }
    if (profileId === currentUser.profile_id) {
      memberOptions.push({ label: "Upload photo", icon: "uploadPhoto" });
    }
    if (
      (adminsIds?.includes(currentUser.profile_id as string) &&
        !ownersIds.includes(targetedProfileId!) &&
        !adminsIds.includes(targetedProfileId!)) ||
      (!ownersIds?.includes(currentUser.profile_id as string) &&
        profileId === currentUser.profile_id &&
        !adminsIds?.includes(currentUser.profile_id as string) &&
        profileId === currentUser.profile_id)
    ) {
      memberOptions.push({ label: "Remove", icon: "remove" });
    }

    return memberOptions;
  };

  const handleDropdownSelection = (type: string) => {
    setModalActive(true);
    setModalType(type);
    if (type === "uploadPhoto") {
      setIsUploading(true);
    }
  };

  const handleModalTitle = () => {
    if (modalType === "changeRole") return "Change role";
    if (modalType === "sendInvite") return "Resend Invite";
    if (modalType === "uploadPhoto") return "Add profile photo";
    if (modalType === "remove") return "Remove caregiver";
    if (modalType === "transferAdmin") return "Transfer admin";
  };

  const handleModalDescription = () => {
    if (modalType === "remove")
      return "By removing this user, they will be unable to access any of the features and functionalities of the Carl app.";
    if (modalType === "sendInvite")
      return `A new invitation will be sent to the following email address: "${selectedUser?.email}"`;
    if (modalType === "transferAdmin")
      return `Are you sure you want to make ${
        selectedUser?.name
      } the admin of ${nameToPossessive(
        selectedCareGroup?.care_receivers[0].name
      )} care team? You can’t undo this action.`;
  };

  const handleModalOnFinish = () => {
    if (
      modalType === "transferAdmin" &&
      (selectedUser?.invitation_status === InvitationStatus.accepted ||
        ownersIds.includes(selectedUser?.profile_id as string))
    ) {
      dispatch(
        transferAdminRole(
          selectedCareGroup?._id as string,
          selectedUser?.profile_id as string
        )
      );
    }
    if (modalType === "sendInvite" && selectedUser?.email !== null) {
      dispatch(
        resendInviteCaregiver(
          selectedCareGroup?._id as string,
          selectedUser?.email as string
        )
      );
    }
    if (modalType === "uploadPhoto" && image !== null) {
      dispatch(
        uploadPhotoAdmin(
          currentUser.profile_id as string,
          currentUser.selected_care_group?._id as string,
          image as string
        )
      );
    }
    if (modalType === "remove") {
      dispatch(
        deleteInviteCaregiver(
          selectedCareGroup?._id as string,
          selectedUser?.email as string
        )
      );
    }
    setModalActive(false);
    setSelectedUser(null);
    setImage(null);
  };

  const handleModalFields = () => {
    if (modalType === "changeRole") {
      return (
        <div>
          <MenuDropDown
            label={"Role"}
            value={role}
            setValue={(value) => setRole(value)}
            options={["Family or friend", "Caregiver"]}
          />
          <p className={styles.changeRoleDescription}>
            A family or friend role will only have access to the chat, learn,
            and settings features.
          </p>
        </div>
      );
    } else if (modalType === "uploadPhoto" && selectedUser) {
      return (
        <ImagePicker
          img={image}
          setImage={(img) => setImage(img)}
          id={selectedUser.profile_id}
          setIsUploading={setIsUploading}
        />
      );
    } else if (modalType === "remove") {
    }
  };

  const dropDownActionsHandler = (member: Member, modalType: string) => {
    if (userIsInactive) return;
    setSelectedUser(member);
    handleDropdownSelection(modalType);
  };

  return (
    <div className={styles.wrapper}>
      {isMobileVersion ? (
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            gap: "24px",
          }}
        >
          {data?.map((member) => (
            <div
              key={member.identity_id}
              style={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-between",
                alignItems: "center",
              }}
            >
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                  justifyContent: "space-between",
                  gap: "16px",
                }}
              >
                {member.image_uri ? (
                  <div className={styles.avatar}>
                    <ImageKit
                      color
                      path={member.image_uri}
                      width={48}
                      height={48}
                    />
                  </div>
                ) : (
                  <div className={styles.initialsContainer}>
                    <p className={styles.initials}>
                      {member.name !== null && member.name !== "undefined"
                        ? member.name
                            ?.split(" ")
                            ?.map((a) => a[0]?.toUpperCase())
                            ?.join("")
                        : ""}
                    </p>
                  </div>
                )}
                <div
                  style={{
                    display: "flex",
                    gap: "2px",
                    flexDirection: "column",
                  }}
                >
                  <p>
                    {member.name !== null && member.name !== "undefined"
                      ? member.name
                      : ""}
                    <em className={styles.admin}>
                      {ownersIds.includes(member.profile_id)
                        ? !adminsIds.includes(member.profile_id)
                          ? " (Owner)"
                          : " (Admin)"
                        : adminsIds.includes(member.profile_id) && " (Admin)"}
                    </em>
                  </p>
                  <p
                    className={cn(styles.status, {
                      [styles.statusActive]:
                        member.invitation_status ===
                          InvitationStatus.accepted ||
                        ownersIds.includes(member.profile_id),
                      [styles.statusExpired]:
                        member.invitation_status === InvitationStatus.expired,
                    })}
                  >
                    {(member.invitation_status === InvitationStatus.pending &&
                      "Invited") ||
                      (member.invitation_status === InvitationStatus.expired &&
                        InvitationStatus.pending) ||
                      (member.invitation_status === InvitationStatus.accepted &&
                        "Active") ||
                      (ownersIds.includes(member.profile_id) && "Active")}
                  </p>
                  <p title={member.email} className={cn(styles.breakWork)}>
                    {member.email}
                  </p>
                </div>
              </div>
              {!!getDropdownOptions(
                member.invitation_status ?? "",
                member.profile_id
              ).length && (
                <div
                  onMouseOver={() => setTargetedProfileId(member.profile_id)}
                >
                  <Dropdown
                    onOptionClick={(modalType: string) =>
                      dropDownActionsHandler(member, modalType)
                    }
                    options={getDropdownOptions(
                      member.invitation_status ?? "",
                      member.profile_id
                    )}
                  />
                </div>
              )}
            </div>
          ))}
        </div>
      ) : (
        <div className={styles.table}>
          <div className={cn(styles.row, styles.tableColSection)}>
            <div className={cn(styles.tableSectionLabel)} />
            {columns.map((col) => (
              <div
                key={col}
                className={cn(styles.cell, styles.tableSectionLabel, {})}
              >
                {col}
              </div>
            ))}
          </div>
          {data?.map((member) => (
            <div className={styles.row} key={member.profile_id}>
              {member.image_uri ? (
                <div className={styles.avatar}>
                  <ImageKit
                    color
                    path={member.image_uri}
                    width={48}
                    height={48}
                  />
                </div>
              ) : (
                <div className={styles.initialsContainer}>
                  <p className={styles.initials}>
                    {member.name !== null && member.name !== "undefined"
                      ? member.name
                          ?.split(" ")
                          ?.map((a) => a[0]?.toUpperCase())
                          ?.join("")
                      : ""}
                  </p>
                </div>
              )}
              <div className={styles.cell}>
                {member.name !== null && member.name !== "undefined"
                  ? member.name
                  : ""}
                <em className={styles.admin}>
                  {ownersIds.includes(member.profile_id)
                    ? !adminsIds.includes(member.profile_id)
                      ? " (Owner)"
                      : " (Admin)"
                    : adminsIds.includes(member.profile_id) && " (Admin)"}
                </em>
              </div>
              <div className={cn(styles.cell)}>
                <p
                  className={cn(styles.status, {
                    [styles.statusActive]:
                      member.invitation_status === InvitationStatus.accepted ||
                      ownersIds.includes(member.profile_id),
                    [styles.statusExpired]:
                      member.invitation_status === InvitationStatus.expired,
                  })}
                >
                  {(member.invitation_status === InvitationStatus.pending &&
                    "Invited") ||
                    (member.invitation_status === InvitationStatus.expired &&
                      InvitationStatus.pending) ||
                    (member.invitation_status === InvitationStatus.accepted &&
                      "Active") ||
                    (ownersIds.includes(member.profile_id) && "Active")}
                </p>
              </div>
              <div
                title={member.email}
                className={cn(styles.cell, styles.breakWork)}
              >
                {member.email}
              </div>
              <div className={styles.cell}>
                {!!getDropdownOptions(
                  member.invitation_status ?? "",
                  member.profile_id
                ).length && (
                  <div
                    onMouseOver={() => setTargetedProfileId(member.profile_id)}
                  >
                    <Dropdown
                      onOptionClick={(modalType) =>
                        dropDownActionsHandler(member, modalType)
                      }
                      options={getDropdownOptions(
                        member.invitation_status ?? "",
                        member.profile_id
                      )}
                    />
                  </div>
                )}
              </div>
            </div>
          ))}
        </div>
      )}
      <Modal
        title={handleModalTitle()}
        onClose={() => {
          setModalActive(() => false);
          setImage(null);
          setSelectedUser(null);
        }}
        onFinish={handleModalOnFinish}
        visible={modalActive}
        primaryBtnLabel={
          ["remove", "sendInvite", "changeRole"].includes(
            modalType as string
          ) ||
          (modalType === "uploadPhoto" && image !== null && !isUploading)
            ? "Confirm"
            : null || modalType === "transferAdmin"
            ? "Transfer admin"
            : ""
        }
        primaryBtnStyle={cn({
          [styles.warningBtn]: modalType === "transferAdmin",
        })}
        primaryDescription={handleModalDescription()}
        secundaryBtnLabel={modalType === "transferAdmin" ? "Cancel" : undefined}
        onSecundaryBtnClick={
          modalType === "transferAdmin" ? () => setModalActive(false) : () => {}
        }
        secundaryBtnStyle={styles.secundaryBtn}
        hasSeparator={modalType !== "uploadPhoto"}
      >
        {handleModalFields()}
      </Modal>
    </div>
  );
};

export default Table;
