import { useEffect, useState } from "react";
import useDarkMode from "use-dark-mode";
import axios from "axios";
import { useAppSelector } from "../store/redux.hooks";
import { cacheImage } from "../store/slices/imageCaching";

import { IKImage } from "imagekitio-react";
import { useDispatch } from "react-redux";
import LoadingSpinner from "./LoadingSpinner/LoadingSpinner.component";

export const ImageKit = ({ path, width, height, color }) => {
  const [signedUrl, setSignedUrl] = useState();

  const admin = useAppSelector((state) => state.admin);
  const imageCaching = useAppSelector((state) => state.imageCaching);

  const dispatch = useDispatch();

  const getSignedUrl = async () => {
    try {
      const result = await axios.get(
        `${process.env.REACT_APP_CARL_ENDPOINT}/imagekit/signed-url`,
        {
          params: {
            imagePath: path,
            width: 360 * window.devicePixelRatio,
            height: 360 * window.devicePixelRatio,
            focus: "face",
          },
        }
      );
      setSignedUrl(result.data.url);
      result.data.url &&
        dispatch(
          cacheImage({
            profileId: admin.profile_id,
            imagePath: path,
            imageUrl: result.data.url,
          })
        );
    } catch (err) {
      console.log(`Get signed url error: ${err}`);
    }
  };

  useEffect(() => {
    setSignedUrl(null);
    if (path in imageCaching && path != null) {
      setSignedUrl(imageCaching[path]);
    } else {
      getSignedUrl();
    }
  }, [path, imageCaching]);

  return !!signedUrl ? (
    <IKImage
      draggable={false}
      width={width ?? 48}
      height={height ?? 48}
      src={signedUrl}
    />
  ) : (
    <div
      style={{
        height: `${height}px`,
        width: `${width}px`,
        borderRadius: "100%",
        overflow: "hidden",
      }}
    >
      <LoadingSpinner color={color ?? "#0096B7"} />
    </div>
  );
};

export const Image = ({
  className,
  src,
  srcDark,
  srcSet,
  srcSetDark,
  alt,
  width = 20,
  height = 20,
}) => {
  const darkMode = useDarkMode(false);

  return (
    <img
      width={width ?? 48}
      height={height ?? 48}
      className={className}
      srcSet={darkMode.value ? srcSetDark : srcSet}
      src={darkMode.value ? srcDark : src}
      alt={alt}
    />
  );
};
