import { Navigate } from "react-router-dom";
import { useAppSelector } from "../store/redux.hooks";

import LoadingSpinner from "../components/LoadingSpinner/LoadingSpinner.component";

export type ProtectedRouteProps = {
  redirectPath: string;
  outlet: JSX.Element;
  extraCondition?: boolean;
};

export default function ProtectedRoute({
  redirectPath,
  outlet,
  extraCondition = true,
}: ProtectedRouteProps) {
  const isAuthenticated = useAppSelector(
    (state) => state.authentication.authenticated
  );
  const authLoading = useAppSelector((state) => state.authentication.loading);
  const admin = useAppSelector((state) => state.admin);

  return authLoading || admin.loading ? (
    <div style={{ height: "100vh", backgroundColor: "#FFFFFF" }}>
      <LoadingSpinner />
    </div>
  ) : isAuthenticated && extraCondition ? (
    !admin.profile_id ? (
      <div style={{ height: "100vh", backgroundColor: "#FFFFFF" }}>
        <LoadingSpinner />
      </div>
    ) : (
      outlet
    )
  ) : (
    <Navigate to={{ pathname: redirectPath }} />
  );
}
